import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { updateDepartment } from '../../../api/settings';
import { addDepartmentDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useDepartments from '../../../hooks/useDepartments';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import { EllipsisLoader } from '../../global/MyLoaders';
import Textarea from '../../global/Textarea';

interface IDepartmentProps {
  closeModal: () => void;
  id: number
}

const EditDepartment = ({ closeModal, id }: IDepartmentProps) => {
  const [department, setDepartment] = useState(addDepartmentDefaultData);
  const { loading, refresh, getSelectedDepartment, selectedDepartment } = useDepartments();
  const [formLoading, setLoading] = useState(false);
  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedDepartment(id), [id]);

  const formNames = ['description', 'name', 'salary_range'];

  useEffect(() => {
    let departmentData = addDepartmentDefaultData;
    if (!loading) {
      for (let x of formNames) {
        departmentData = { ...departmentData, ...{ [x]: { ...departmentData[x], ...{ error: false, data: selectedDepartment[x] } } } };
      }
      setDepartment(departmentData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setDepartment({ ...department, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setDepartment({ ...department, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(department, setDepartment)) {
      setLoading(true);

      const data = {
        name: department.name.data,
        description: department.description.data,
        salary_range: department.salary_range.data,
      }

      try {
        const response = await updateDepartment(data, id);
        if (response.data.success === true) {
          toast.success('Department updated successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Department Name' handleChange={handleChange} error={department.name.error} errorMessage="Please provide a department name" value={department?.name.data} required />
        </div>
        <div className="col-sm-6">
          <Input name='salary_range' label='Salary Range' handleChange={handleChange} error={department?.salary_range.error} errorMessage="Please provide a salary range" value={department?.salary_range.data} required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} name='description' label='Description' error={department.description.error} errorMessage="Please provide a department description" value={department?.description.data} required />
        </div>

      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Updating..." : "Update"}</button>
      </div>
    </form>
  )
}

export default EditDepartment