import React from 'react'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const MyProfile = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Roles' />
        {/* body */}
      </>
    </SettingsWrapper>

  )
}

export default MyProfile