import React from "react";
import View from "../../components/accounting/transactions/View";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import { ROUTES } from "../../routes/routes";

function TransactionDetails() {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs
          title="View Transaction"
          parents={[{ link: ROUTES.TRANSACTIONS, title: "Transactions" }]}
        />
        {/* body */}
        <View />
      </>
    </DashboardWrapper>
  );
}

export default TransactionDetails;
