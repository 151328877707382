import React from 'react'
import Card from '../../parts/Card'

const GenerateReportsViewTable = () => {
  return (
    <Card>
      <div className='table-responsive'>
        <table className='table table-bordered'>
          <tr>
            <th>Username</th>
            <th>Age</th>
          </tr>
        </table>
      </div>
    </Card>
  )
}

export default GenerateReportsViewTable