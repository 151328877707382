import React from 'react'
import ListUsers from '../../components/settings/users/ListUsers'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Users = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='System users' />
        {/* body */}
        <ListUsers />
      </>
    </SettingsWrapper>

  )
}

export default Users