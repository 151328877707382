// import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import { getAllPayrolls } from '../api/hr';
import { RootState } from '../redux/app/store';
import { setTotalDeducation, setTotalPay } from '../redux/reducer/hr';
// import { setPayrolls, setSelectedPayroll } from '../redux/reducer/hr';

const usePayrollItems = () => {
  // const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    payrollItems, payrollDeducationItems, netPay, totalDeducation, totalPay
  } = useSelector(
    (state: RootState) => state.hr,
  );

  const handleTotalPayChange = (e: any) => {
    const { value, name } = e.target as HTMLInputElement;
    dispatch(setTotalPay({ value, name }));

  }

  const handleTotalDeducationChange = (e: any) => {
    const { value, name } = e.target as HTMLInputElement;
    dispatch(setTotalDeducation({ value, name }));
  }




  return {
    payrollItems, payrollDeducationItems, handleTotalPayChange, handleTotalDeducationChange, netPay, totalDeducation, totalPay
  }
}

export default usePayrollItems