/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useLocation, useParams } from 'react-router';

interface IParam {
  id: any
}

const useQuery = (): any => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useLastUrlSegment = (): any => {
  const { id } = useParams() as IParam;
  return id;
};

export default useQuery;