import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { createAsset } from '../../api/assetManagement';
import { assetDefaultData, depreciationAppreciation, depreciationMethod, errorToastOptions, successToastOptions } from '../../constants';
import { formatErrorMessage } from '../../helpers';
import useAccounts from '../../hooks/useAccounts';
import useAssetManagement from '../../hooks/useAssetManagement';
import useBudget from '../../hooks/useBudget';
import { formatDepartmentSelectData, getFormData } from '../../utils/helpers';
import Validator from '../../utils/Validator';
import Input from '../global/Input'
import InputDate from '../global/InputDate'
import InputSelect from '../global/InputSelect'
import { EllipsisLoader } from '../global/MyLoaders';
import Textarea from '../global/Textarea';

interface CreateAssetProps {
  closeModal: () => void;
}

const CreateAsset = ({ closeModal: onCancel }: CreateAssetProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [asset, setAsset] = useState(assetDefaultData);
  const { refresh, refreshTypes } = useAssetManagement();

  const { getAccount, formattedIncomeAccounts, loading, formattedAssetAccounts, formattedExpenseAccounts } = useAccounts();
  const { budgets } = useBudget();


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAccount(), []);

  useEffect(() => {
    return refreshTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validator = new Validator();

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAsset({ ...asset, ...{ [name]: { ...asset[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAsset({ ...asset, ...{ [name]: { ...asset[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAsset({ ...asset, ...{ [name]: { ...asset[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAsset({ ...asset, ...{ [name]: { ...asset[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(asset, setAsset)) {
      setFormLoading(true);

      const data = getFormData(asset);

      createAsset(data).then((response) => {
        if (response.data.success === true) {
          toast.success('Asset created successfully', successToastOptions);
          refresh();

          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setFormLoading(false);
      })
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} name="asset_name" label='Asset name' value={asset.asset_name.data} error={asset.asset_name.error} errorMessage="Please enter the asset name" required />
        </div>
        <div className="col-sm-6">
          <InputSelect data={formatDepartmentSelectData(budgets)} label="Budget" name='budget_id' id='budget_id' required handleChange={handleSelectChange} value={asset.budget_id.data} error={asset.budget_id.error} errorMessage="Please select the budget" />
        </div>

      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} name="identity_no" label='Identification No or S/N' value={asset.identity_no.data} error={asset.identity_no.error} errorMessage="Please enter the asset identification" required />
        </div>
        <div className="col-sm-6">
          <InputDate handleChange={handleSelectChange} label="Purchase Date" name='purchase_date' required value={asset.purchase_date.data} error={asset.purchase_date.error} errorMessage="Please select the puchase date" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input type='number' handleChange={handleChange} name="purchase_cost" label='Purchase Cost' value={asset.purchase_cost.data} error={asset.purchase_cost.error} errorMessage="Please enter the purchase cost" required />
        </div>
        <div className="col-sm-6">
          <InputDate handleChange={handleSelectChange} label="Date When Put To Use" name='date_when' required value={asset.date_when.data} error={asset.date_when.error} errorMessage="Please select the date to put the asset to use" />
        </div>
      </div>
      <div className='form-row'>
        <div className="col-sm-6">
          <Input type='number' handleChange={handleChange} name="salvage_value" label='Salvage Value' required value={asset.salvage_value.data} error={asset.salvage_value.error} errorMessage="Please enter the salvage value" />
        </div>

        <div className="col-sm-6">
          <Input type='number' handleChange={handleChange} name="expected_age" label='Expected Age (years)' required value={asset.expected_age.data} error={asset.expected_age.error} errorMessage="Please enter the expected age in years" />
        </div>
      </div>
      <div className='form-row'>

        <div className="col-sm-6">
          <InputSelect data={formattedAssetAccounts} label="Asset Account (Debit)" name='asset_account_id' id='asset_account_id' required handleChange={handleSelectChange} value={asset.asset_account_id.data} error={asset.asset_account_id.error} errorMessage="Please select the asset account to debit" />
        </div>


        <div className="col-sm-6">
          <InputSelect data={depreciationAppreciation} label="Depreciation / Appreciation" name='depre_appre_id' id='depre_appre_id' handleChange={handleSelectChange} value={asset.depre_appre_id.data} errorMessage="Please select the fund account" />
        </div>
      </div>

      {Number(asset.depre_appre_id.data) === 1 && (
        <>
          <hr />
          <h5 className="text-muted">Depreciation</h5>
          <div className="form-row">
            <div className="col-sm-3">
              <Input type='number' handleChange={handleChange} name="depreciation_rate" label='Depreciation Rate' required={Number(asset.depre_appre_id.data) === 1} value={asset.depreciation_rate.data} error={asset.depreciation_rate.error} errorMessage="Please enter the depreciation rate" />
            </div>
            <div className="col-sm-5">
              <InputSelect data={depreciationMethod} label="Depreciation Method" name='depreciation_method_id' id='depreciation_method_id' required={Number(asset.depre_appre_id.data) === 1} handleChange={handleSelectChange} value={asset.depreciation_method_id.data} error={asset.depreciation_method_id.error} errorMessage="Please select the depreciation method" />
            </div>
            <div className="col-sm-4">
              <InputSelect data={formattedAssetAccounts} label="Depreciation Account" name='depreciation_account_id' id='depreciation_account_id' required={Number(asset.depre_appre_id.data) === 1} handleChange={handleSelectChange} value={asset.depreciation_account_id.data} error={asset.depreciation_account_id.error} errorMessage="Please select the depreciation account" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-4">
              <InputSelect data={formattedExpenseAccounts} label="Expense Account" name='expense_account_id' id='expense_account_id' required={Number(asset.depre_appre_id.data) === 1} handleChange={handleSelectChange} value={asset.expense_account_id.data} error={asset.expense_account_id.error} errorMessage="Please select the expense account" />
            </div>
            <div className="col-sm-4">
              <InputSelect data={formattedExpenseAccounts} label="Depreciation Loss Account" name='depreciation_loss_account_id' id='depreciation_loss_account_id' required={Number(asset.depre_appre_id.data) === 1} handleChange={handleSelectChange} value={asset.depreciation_loss_account_id.data} error={asset.depreciation_loss_account_id.error} errorMessage="Please select the depreciation loss account" />
            </div>
            <div className="col-sm-4">
              <InputSelect data={formattedIncomeAccounts} label="Depreciation Gain Account" name='depreciation_gain_account_id' id='depreciation_gain_account_id' required={Number(asset.depre_appre_id.data) === 1} handleChange={handleSelectChange} value={asset.depreciation_gain_account_id.data} error={asset.depreciation_gain_account_id.error} errorMessage="Please select the depreciation gain account" />
            </div>
          </div>

        </>
      )}

      {Number(asset.depre_appre_id.data) === 2 && (
        <>
          <hr />
          <h5 className="text-muted">Appreciation</h5>
          <div className="form-row">
            <div className="col-sm-3">
              <Input type='number' handleChange={handleChange} name="appreciation_rate" label='Appreciation Rate' required={Number(asset.depre_appre_id.data) === 2} value={asset.appreciation_rate.data} error={asset.appreciation_rate.error} errorMessage="Please enter the appreciation rate" />
            </div>
            <div className="col-sm-5">
              <InputSelect data={formattedAssetAccounts} label="Appreciation Account" name='appreciation_account_id' id='appreciation_account_id' required={Number(asset.depre_appre_id.data) === 2} handleChange={handleSelectChange} value={asset.appreciation_account_id.data} error={asset.appreciation_account_id.error} errorMessage="Please select the appreciation account" />
            </div>
            <div className="col-sm-4">
              <InputSelect data={formattedIncomeAccounts} label="Income Account" name='income_account_id' id='income_account_id' required={Number(asset.depre_appre_id.data) === 2} handleChange={handleSelectChange} value={asset.income_account_id.data} error={asset.income_account_id.error} errorMessage="Please select the income account" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-6">
              <InputSelect data={formattedExpenseAccounts} label="Appreciation Loss Account" name='appreciation_loss_account_id' id='appreciation_loss_account_id' required={Number(asset.depre_appre_id.data) === 2} handleChange={handleSelectChange} value={asset.appreciation_loss_account_id.data} error={asset.appreciation_loss_account_id.error} errorMessage="Please select the appreciation loss account" />
            </div>
            <div className="col-sm-6">
              <InputSelect data={formattedIncomeAccounts} label="Appreciation Gain Account" name='appreciation_gain_account_id' id='appreciation_gain_account_id' required={Number(asset.depre_appre_id.data) === 2} handleChange={handleSelectChange} value={asset.appreciation_gain_account_id.data} error={asset.appreciation_gain_account_id.error} errorMessage="Please select the appreciation gain account" />
            </div>
          </div>
        </>
      )}

      <div className="form-row">
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} name="description" label='Description' value={asset.description.data} error={asset.description.error} errorMessage="Please enter the description" required />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>

    </form>
  )
}

export default CreateAsset