
import { createSlice } from '@reduxjs/toolkit';

export interface IMobileApp {
  user: any;
}

const initialState: IMobileApp = {
  user: {},
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggedUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});

const { reducer, actions } = userReducer;

export const {
  setLoggedUser
} = actions;

export default reducer;