import React from 'react'
import ListBrands from '../../components/stocks/brand/ListBrands'
import StocksWrapper from '../../components/StocksWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const Products = () => {
  return (
    <StocksWrapper>
      <>
        <Breadcrumbs title='Brands' parents={[{ link: ROUTES.STOCKS, title: 'Stock Management' }]} />
        <ListBrands />
      </>
    </StocksWrapper>
  )

}

export default Products