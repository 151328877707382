import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { editProgram } from '../../../api/projects';
import { ERRORMESSAGE, errorToastOptions, programDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import usePrograms from '../../../hooks/usePrograms';
import useProjects from '../../../hooks/useProjects';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';
import TextEditor from '../../global/TextEditor';
interface ICreateProgramProps {
  closeModal: () => void,
  id: number
}
const EditProgram = ({ closeModal, id }: ICreateProgramProps) => {
  const [formLoading, setLoading] = useState(false);
  const [program, setProgram] = useState(programDefaultData);
  const validator = new Validator();
  const { refresh, selectedProgram, getSelectedProgram, loading } = usePrograms();
  const { formattedProjectData } = useProjects();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedProgram(Number(id)), [id]);

  const formNames = ['description', 'category_name'];

  useEffect(() => {
    let programData = programDefaultData;
    if (!loading) {
      for (let x of formNames) {
        programData = { ...programData, ...{ [x]: { ...programData[x], ...{ error: false, data: selectedProgram[x] } } } };
      }
      setProgram(programData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setProgram({ ...program, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setProgram({ ...program, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleEditorChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setProgram({ ...program, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setProgram({ ...program, ...{ [name]: { error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(program, setProgram)) {
      setLoading(true);

      const data = {
        category_name: program.category_name.data,
        description: program.description.data,
        project_id: program.project_id.data,
      }

      // console.log(data); return;

      try {
        const response = await editProgram(data, id);
        if (response.data.success === true) {
          toast.success('Program updated successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }

    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='category_name' label='Program Name' handleChange={handleChange} value={program.category_name.data} error={program.category_name.error} errorMessage="Please provide the program name" />
        </div>
        <div className="col-sm-6">
          <InputSelect data={formattedProjectData} name='project_id' id='project_id' required handleChange={handleEditorChange} label='Project Name' value={{ value: program.project_id.data, label: "" }} error={program.project_id.error} errorMessage="Please provide the project name" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <TextEditor name='description' label='program Description' handleChange={handleEditorChange} required value={program.description.data} error={program.description.error} errorMessage="Please provide the program description" />
        </div>
      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </form>
  )
}

export default EditProgram