import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import { useLastUrlSegment } from '../../hooks/useQuery'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'
import ViewPurchaseOrder from '../../components/procurement/purchaseOrder/ViewPurchaseOrder'

const ViewPurchaseOrderDetails = () => {
  const id = useLastUrlSegment();
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Purchase Order Details' parents={[{ link: ROUTES.PURCHASEORDER, title: "Purchase Orders" }]} />
        {/* body */}
        <ViewPurchaseOrder id={id}/>
      </>
    </DashboardWrapper>
  )
}

export default ViewPurchaseOrderDetails