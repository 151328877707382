import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../routes/routes'
import EmployeesTable from './EmployeesTable'

const ListEmployees = () => {
  return (
    <div className="col-xl-12 mb-30">
      <div className="card card-statistics h-100">
        <div className="card-body">
          <div className="d-block d-md-flex justify-content-between">
            <div className="d-block">
              <div className='pl-3 row align-items-end'>
                <Link to={ROUTES.ADDEMPLOYEE} className='btn btn-primary ml-auto mr-3'>Add New Employee</Link>
              </div>
            </div>
            <div className="d-block d-md-flex clearfix sm-mt-20">
              <div className="widget-search ml-0 clearfix">
                <i className="fa fa-search"></i>
                <input type="search" className="form-control" placeholder="Search...." />
              </div>
            </div>
          </div>
          {/* table */}
          <EmployeesTable />
          {/* end table */}
        </div>
      </div>
    </div>
  )
}

export default ListEmployees