import React, { ReactElement } from 'react'

interface ICardProps {
  children: ReactElement
  className?: string
}

const Card = ({ className = '', children }: ICardProps) => {
  return (
    <div className="row">
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <div className={`card-body ${className}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card