
import { createSlice } from '@reduxjs/toolkit';

export interface IProjects {
  projects: any;
  categories: any;
  activities: any;
  programs: any;
  selectedProject: any;
  selectedActivity: any;
  categoryFormData: any;
  selectedCategory: any;
  otherCategories: any;
  otherPrograms: any;
  selectedProgram: any;
  activityTeamRoles: any;
  activityParameters: any;
  selectedActivityParameter: any;
  selectedActivityTeamRole: any;
  rolePermission: any;
  assignedPermissions: any;
  activityStats: any;

  funders: any;
  selectedFunder: any;
  activityReports: any;
  services: any;
}

const initialState: IProjects = {
  projects: {},
  categories: {},
  activities: {},
  programs: {},
  selectedProject: {},
  selectedActivity: {},
  categoryFormData: [],
  selectedCategory: {},
  otherCategories: {},
  otherPrograms: {},
  selectedProgram: {},
  activityTeamRoles: [],
  activityParameters: [],
  selectedActivityParameter: {},
  selectedActivityTeamRole: {},
  rolePermission: [],
  assignedPermissions: [],
  activityStats: {},

  funders: [],
  selectedFunder: {},
  activityReports: [],
  services: []
};

export const projectsReducer = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, { payload }) => {
      state.projects = payload;
    },
    setCategories: (state, { payload }) => {
      state.categories = payload;
    },
    setActivities: (state, { payload }) => {
      state.activities = payload;
    },
    setPrograms: (state, { payload }) => {
      state.programs = payload;
    },
    setSelectedProject: (state, { payload }) => {
      state.selectedProject = payload;
    },
    setSelectedActivity: (state, { payload }) => {
      state.selectedActivity = payload
    },
    setCategoryFormData: (state, { payload }) => {
      state.categoryFormData = payload
    },
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload
    },
    setOtherCategories: (state, { payload }) => {
      state.otherCategories = payload
    },
    setOtherPrograms: (state, { payload }) => {
      state.otherPrograms = payload
    },
    setSelectedProgram: (state, { payload }) => {
      state.selectedProgram = payload
    },
    setActivityTeamRoles: (state, { payload }) => {
      state.activityTeamRoles = payload
    },
    setActivityParameters: (state, { payload }) => {
      state.activityParameters = payload
    },
    setSelectedActivityParameter: (state, { payload }) => {
      state.selectedActivityParameter = payload
    },
    setSelectedActivityTeamRole: (state, { payload }) => {
      state.selectedActivityTeamRole = payload
    },
    setRolePermissions: (state, { payload }) => {
      state.rolePermission = payload
    },
    setAssignedPermissions: (state, { payload }) => {
      state.assignedPermissions = payload
    },
    setActivityStats: (state, { payload }) => {
      state.activityStats = payload
    },
    setFunders: (state, { payload }) => {
      state.funders = payload
    },
    setSelectedFunders: (state, { payload }) => {
      state.selectedFunder = payload
    },
    setActivityReports: (state, { payload }) => {
      state.activityReports = payload
    },
    setServices: (state, { payload }) => {
      state.services = payload
    },
  },
});

const { reducer, actions } = projectsReducer;

export const {
  setProjects, setCategories, setActivities, setPrograms, setSelectedCategory, setSelectedProject, setSelectedActivity, setCategoryFormData, setOtherCategories, setOtherPrograms, setSelectedProgram, setActivityTeamRoles, setActivityParameters, setSelectedActivityParameter, setSelectedActivityTeamRole, setRolePermissions, setAssignedPermissions, setActivityStats, setFunders, setSelectedFunders, setActivityReports, setServices
} = actions;

export default reducer;