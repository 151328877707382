import React from 'react'
import ListProjects from './ListProjects'
// import Stats from './Stats'

const ProjectsDashboard = () => {
  return (
    <>
      {/* <Stats /> */}
      <ListProjects />
    </>
  )
}

export default ProjectsDashboard