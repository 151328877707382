import React from 'react'
import parse from "html-react-parser";
import useCategories from '../../../hooks/useCategories';
import Card from '../../../parts/Card';
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder';
import { EllipsisLoader } from '../../global/MyLoaders';
import OtherCategories from './OtherCategories';
import { htmlParseOptions } from '../../../constants';

const ViewCategories = () => {
  const { loading, selectedCategory } = useCategories();
  return loading ? <EllipsisLoader /> : (
    <div className='row'>
      <div className='col-xl-8 mb-30'>
        <Card>
          <>
            <CardHeaderWithBottomBorder title={selectedCategory.name} />
            <CardContentWrapper>
              <div className='row'>
                <div className="col-sm-12">
                  <h6>Name</h6>
                  <p>{selectedCategory.name}</p>
                </div>

                <div className="col-sm-12">
                  <h6>Description</h6>
                  {parse(selectedCategory?.description ? selectedCategory?.description : '', htmlParseOptions)}

                </div>
              </div>
            </CardContentWrapper>
          </>
        </Card>
      </div>

      <div className='col-xl-4 mb-30'>
        <OtherCategories />
      </div>
    </div>
  )
}

export default ViewCategories