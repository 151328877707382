import React from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useTreatments from '../../../hooks/useTreatments';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';

const TreatmentTable = () => {
  const id = useLastUrlSegment();
  const { treatment, loading } = useTreatments(id);

  const treatmentColumnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "ARVS Name",
      selector: (row) => row.arvs?.name,
      reorder: true,
      sortable: true,
      wrap: true,
      grow: 2,
    }, {
      id: 2,
      name: "Dosage",
      selector: (row) => row.dosage,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 3,
      name: "Patient Names",
      selector: (row) => row.patient.first_name + " " + row.patient.last_name,
      reorder: true,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      id: 4,
      name: "Frequency",
      selector: (row) => row.frequency,
      sortable: true,
      wrap: true,
    }, {
      id: 5,
      name: "Instructions",
      selector: (row) => row.instructions,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 6,
      name: "Period",
      selector: (row) => row.period,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 7,
      name: "Treatment Form",
      selector: (row) => row.treatment_form,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 8,
      name: "Start Date",
      selector: (row) => row.start_date,
      reorder: true,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <Link to={`/doctor/treatments/view/${row.id}`} className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></Link>
          <button className='btn btn-sm btn-info'><i className='fa fa-edit'></i></button>

          <button className='btn btn-sm btn-danger mx-1'><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  return (
    <div className="table-responsive mt-15">

      {loading ? (<EllipsisLoader />) : (
        <MyDataTable columns={treatmentColumnData} data={treatment} title="" />)}
    </div>
  )
}

export default TreatmentTable