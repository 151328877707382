import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  payBillDefaultData,
  paymentMethodSelectData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccounts from "../../../hooks/useAccounts";
import {
  formatAcountSelectData,
  formatDepartmentSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import SentePayLogo from "../../../assets/images/sentepay.svg";
import usePaymentTypes from "../../../hooks/usePaymentTypes";
import useBudget from "../../../hooks/useBudget";
import useBills from "../../../hooks/useBills";
import PayBillForm from "../../global/PayBillForm";

interface IAddPaymentProps {
  closeModal: () => void;
  refresh: () => void;
  id: number;
  amount: any;
  paid_to: number;
}

const AddPayment = ({
  closeModal,
  refresh,
  id,
  amount,
  paid_to,
}: IAddPaymentProps) => {
  const [billPayment, setBillPayment] = useState(payBillDefaultData);
  const [formLoading, setLoading] = useState(false);
  const { accounts, loading, expenseAccounts, getExpenseAccount } =
    useAccounts();
  const { paymentTypes } = usePaymentTypes();
  const { budgets } = useBudget();
  const { getBill, selectedBill } = useBills();

  useEffect(() => getBill(id), [id]);

  const defaultData = [{ name: "", amount: "", budget_id: "", debit: "" }];
  const [values, setValues] = useState<Array<any>>(defaultData);

  useEffect(() => {
    setBillPayment({
      ...billPayment,
      ...{
        bill_amount: { error: false, data: amount as string },
        ...{ status_id: { error: false, data: "2" } },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getExpenseAccount(), [accounts]);

  const validator = new Validator();

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    if (value !== "") {
      // update the state
      setBillPayment({
        ...billPayment,
        ...{ [name]: { error: false, data: value } },
      });
    } else {
      // indicate an error
      setBillPayment({
        ...billPayment,
        ...{ [name]: { error: true, data: value } },
      });
    }
  };

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setBillPayment({
        ...billPayment,
        ...{ [name]: { error: false, data: value } },
      });
    } else {
      // indicate an error
      setBillPayment({
        ...billPayment,
        ...{ [name]: { error: true, data: value } },
      });
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();

    if (validator.checkForEmptyFields(billPayment, setBillPayment)) {
      setLoading(true);
      let credit = 1; // this functionality to the backend

      const data = {
        ...getFormData(billPayment),
        paid_to: paid_to,
        credit: credit,
        payment_mode: billPayment.payment_channel.data,
        items: values,
      };

      try {
        const response = await doCreate(`bills/${id}/payout`, data);
        if (response.data.success === true) {
          toast.success("Successfully paid out a bill", successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(
            formatErrorMessage(response.data.data, response.data.message),
            errorToastOptions
          );
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false);
      }
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      {billPayment.payment_channel.data == "2" && (
        <>
          <div className="form-row align-items-center">
            <div className="col-sm-3">
              <h5 className="text-primary">Powered by: </h5>
            </div>
            <div className="col-sm-9">
              <img
                src={SentePayLogo}
                style={{
                  width: "50%",
                  objectFit: "contain",
                  height: "100%",
                  display: "flex",
                  margin: "0 auto",
                }}
                alt=""
              />
            </div>
          </div>
          <hr />
        </>
      )}

      <div className="form-row">
        <div className="col-sm-6">
          <Input
            type="number"
            name="bill_amount"
            label="Bill Amount"
            handleChange={() => ""}
            error={billPayment?.bill_amount.error}
            errorMessage="Please provide the estimated amount"
            value={billPayment?.bill_amount.data}
            required
            readonly
          />
        </div>

        <div className="col-6">
          <InputSelect
            data={paymentTypes?.map((item: any) => ({
              label: item?.name,
              value: item?.id,
            }))}
            name="payment_channel"
            label="Payment Channel"
            id="payment_channel"
            required
            handleChange={handleSelectChange}
            error={billPayment?.payment_channel.error}
            errorMessage="Please provide the payment channel"
            value={billPayment?.payment_channel.data}
          />
        </div>
      </div>

      <PayBillForm
        defaultData={selectedBill?.requisition?.items}
        setValues={setValues}
        values={values}
      />
      <div className="form-row">
        {billPayment.payment_channel.data == "2" && (
          <>
            <div className="col-sm-6">
              <Input
                type="number"
                name="phone_number"
                label="Phone number"
                handleChange={handleChange}
                error={billPayment?.phone_number.error}
                errorMessage="Please provide the phone number"
                value={billPayment?.phone_number.data}
                required={billPayment.payment_channel.data == "2"}
              />
            </div>
          </>
        )}
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn btn-primary"
          onClick={handleSubmit}
        >
          {formLoading ? "Paying..." : "Pay"}
        </button>
      </div>
    </form>
  );
};

export default AddPayment;
