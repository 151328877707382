import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useLastUrlSegment } from '../hooks/useQuery';

const ClinicSideBar = () => {
  const location = useLocation();
  const id = useLastUrlSegment();
  return (
    <div className='card card-statistics h-100 col-xs-12 col-md-3 px-2 py-3 mb-10'>
      <div className='card-body'>
        <p className='text-muted'>Clinic Management</p>
        <ul className="nav navbar-nav settings_links">
          <li className={`${location.pathname === '/clinics/view/' + id && 'active'}`}>
            <NavLink to={'/clinics/view/' + id}><i className="fa fa-hospital-o"></i><span className="ml-2">Clinic details </span></NavLink>
          </li>
          {/* <li className={`${location.pathname === '/clinics/view/' + id + '/doctors' && 'active'}`}>
            <NavLink to={'/clinics/view/' + id + '/doctors'}><i className="fa fa-user-md"></i><span className="ml-2">Doctors </span></NavLink>
          </li> */}
          <li className={`${location.pathname === '/clinics/view/' + id + '/appointments' && 'active'}`}>
            <NavLink to={'/clinics/view/' + id + '/appointments'}><i className="fa fa-calendar-plus-o"></i><span className="ml-2">Appointments </span></NavLink>
          </li>
          <li className={`${location.pathname === '/clinics/view/' + id + '/treatments' && 'active'}`}>
            <NavLink to={'/clinics/view/' + id + '/treatments'}><i className="fa fa-stethoscope"></i><span className="ml-2">Treatments </span></NavLink>
          </li>
          <li className={`${location.pathname === '/clinics/view/' + id + '/patients' && 'active'}`}>
            <NavLink to={'/clinics/view/' + id + '/patients'}><i className="fa fa-wheelchair"></i><span className="ml-2">Patients </span></NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ClinicSideBar