import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import ReportsDashboard from "../../components/reports/ReportsDashboard";
import Breadcrumbs from "../../parts/Breadcrumbs";
import { ROUTES } from "../../routes/routes";
import GenerateReportsViewHeader from "../../components/reports/GenerateReportsViewHeader";
import ComparisonDetails from "../../components/accounting/budget_comparision/ComparisonDetails";

const BudgetComparisonDetails = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs
          title="Budget Comparison Details"
          parents={[{ link: ROUTES.REPORTS, title: "Reports" }]}
        />

        <ComparisonDetails />
      </>
    </DashboardWrapper>
  );
};

export default BudgetComparisonDetails;
