import React from 'react'
import ListAnnouncements from '../../components/anouncements/ListAnnouncements'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Announcemts = () => {
  return (
    <>
      <DashboardWrapper>
        <>
          <Breadcrumbs title='Announcements' />
          {/* <!-- main body --> */}
          <ListAnnouncements />
        </>

      </DashboardWrapper>
    </>
  )
}

export default Announcemts