
import useEmployees from '../../../../hooks/useEmployees';
import Card from '../../../../parts/Card';
import Address from './Address';
import Education from './Education';
import EmploymentDetails from './EmploymentDetails';
import Finish from './Finish';
import './form.css';
import OtherTraining from './OtherTraining';
import PersonalDetails from './PersonalDetails';
import Referee from './Referee';
import Steps from './Steps';
const Form = () => {

  const { addEmployeeFormId } = useEmployees();

  let ModelToDisplay;

  switch (addEmployeeFormId) {
    case 2:
      ModelToDisplay = Address;
      break;
    case 3:
      ModelToDisplay = Education;
      break;
    case 4:
      ModelToDisplay = EmploymentDetails;
      break;
    case 5:
      ModelToDisplay = Referee;
      break;
    case 6:
      ModelToDisplay = OtherTraining;
      break;
    case 7:
      ModelToDisplay = Finish;
      break;
    default:
      ModelToDisplay = PersonalDetails;
      break;
  }

  return (
    <div className="container-fluid" >
      <div className="row  mt-0">
        <div className="col-sm-12">
          <div className='col-12 col-sm-12 col-md-12 col-lg-12  p-0 mt-3 mb-2 text-center'>
            <Card>
              {/* <!-- progressbar --> */}
              <Steps id={addEmployeeFormId} />
            </Card>

          </div>

          <Card>
            <form id="msform">
              {/* <!-- fieldsets --> */}
              <ModelToDisplay />
              {/* <div className="form-row m-0 pb-4 pt-3 justify-content-end">
                {addEmployeeFormId > 1 && (
                  <button type='button' className='btn btn-outline-danger mr-3' onClick={prev}>Prev</button>
                )}
                <button type='button' className='btn  btn-info' onClick={next}>
                  Next
                </button>
              </div> */}
            </form>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Form