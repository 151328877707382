import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPatients } from "../api/doctor";
import { RootState } from "../redux/app/store";
import { setPatients } from "../redux/reducer/doctor/patientsReducer";

const usePatients = (clinicId?: number) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { patients } = useSelector((state: RootState) => state.patients);

  useEffect(() => {
    getAllPatients(clinicId)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setPatients(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    getAllPatients(clinicId)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setPatients(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { patients, loading, refresh };
};

export default usePatients;
