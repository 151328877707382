import React from 'react'
import toast from 'react-hot-toast';
import { errorToastOptions } from '../../constants';
import { formatAcountSelectData } from '../../utils/helpers';
import Input from './Input';
import InputSelect from './InputSelect';
import { EllipsisLoader } from './MyLoaders';

interface IDynamicFormBudgetProps {
  defaultData: Array<any>;
  setValues: (item: Array<any>) => void;
  values: Array<any>
  accounts: Array<any>
  loading: boolean
  availableAccount: Array<any>

}

const DynamicFormBudget = ({ defaultData, values, setValues, accounts, loading, availableAccount }: IDynamicFormBudgetProps) => {

  const handleAddClick = () => {
    setValues([...values, ...defaultData]);
  }

  const handleMinusClick = (index: number) => {
    const newState = values;
    if (newState.length === 1) {
      toast.error("You can't remove this column", errorToastOptions);
      return;
    };
    newState.splice(index, 1)
    setValues([...newState]);
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, id } = e.target as HTMLInputElement;
    const newState = values;
    newState[Number(id)][name] = value;
    setValues(newState);
  }

  const handleSelectChange = (e: any, id: number) => {
    const { name, value } = e;
    const newState = values;
    newState[Number(id)][name] = value;
    setValues(newState);
  }


  return loading ? <EllipsisLoader /> : (
    <div className='col-sm-12'>

      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Ledger</th>
            <th>Amount</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>

          {values && values.length > 0 && (
            values.map((item, index) => (
              <tr key={index}>

                <td>
                  <InputSelect label='' handleChange={(e) => handleSelectChange(e, index)} id={index} name={`account_chart_id`} data={formatAcountSelectData(accounts.filter((account: any) => !availableAccount.includes(account.id)))} withNoLabel value={values[index].account_chart_id} />
                </td>
                <td width={'30%'}>
                  <Input label='' handleChange={handleChange} id={index} name={`amount`} noLabel type='number' />
                </td>
                <td>
                  <div className="form-row flex-nowrap">
                    <div className="col">
                      <button
                        type="button"
                        className='btn btn-sm btn-danger'
                        onClick={() => handleMinusClick(index)} // remove a friend from the list
                      >
                        -
                      </button>

                    </div>
                    <div className="col">

                      <button
                        type="button"
                        className='btn btn-sm btn-info'
                        onClick={() => handleAddClick()} // insert an empty string at a position
                      >
                        +
                      </button>
                    </div>
                  </div>

                </td>
              </tr>

            ))
          )}
        </tbody>
      </table>

    </div>
  );
}

export default DynamicFormBudget