/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../parts/Footer';
import Nav from '../parts/Nav';
import SideBar from '../parts/SideBar';
import StockSideBar from '../parts/StockSideBar';
import { RootState } from '../redux/app/store';

interface ISettingsWrapperProps {
  children: ReactElement
}

const StocksWrapper = ({ children }: ISettingsWrapperProps) => {
  const { toggleSideNav } = useSelector(
    (state: RootState) => state.general,
  );
  return (
    <div className={`${toggleSideNav ? 'wrapper slide-menu' : 'wrapper'} `}>
      <Nav />
      <div className="container-fluid">
        <div className="row m-0">
          <SideBar />

          <div className="content-wrapper">
            <div className="row m-0">
              <StockSideBar />
              <div className="col-xs-12 col-md-9">
                {children}

              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StocksWrapper