import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import { setPaymentTypes, setSelectedPaymentType } from "../redux/reducer/accounting";

const usePaymentTypes = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    paymentTypes, selectedPaymentType
  } = useSelector(
    (state: RootState) => state.accounting,
  );
  useEffect(() => {

    if (paymentTypes.length > 0) {
      setLoading(false);
      return;
    }
    setLoading(true);
    doGet('payment_types').then((response) => {
      if (response.data.success) {
        dispatch(setPaymentTypes(response.data.data));
      }
    }).catch(() => {
      //error
    }).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    doGet('payment_types').then((response) => {
      if (response.data.success) {
        dispatch(setPaymentTypes(response.data.data));
      }
    }).catch(() => {
      //error
    }).finally(() => {
      setLoading(false);
    });
  }

  const getSelectedPaymentType = (id: number) => {
    // 
    setLoading(true);
    doGet('payment-types/' + id).then((response) => {
      if (response.data.success) {
        dispatch(setSelectedPaymentType(response.data.data));
      }
    }).catch(() => {
      //error
    }).finally(() => {
      setLoading(false);
    });
  }

  return { paymentTypes, loading, refresh, getSelectedPaymentType, selectedPaymentType }
}

export default usePaymentTypes