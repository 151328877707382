import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import ListSchedules from "../../components/accounting/schedules/listSchedules";

const Schedules = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Schedules" />
        {/* body */}
        <ListSchedules />
      </>
    </DashboardWrapper>
  );
};

export default Schedules;
