import React, { useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useStories from '../../../hooks/useStories'
import ConfirmDelete from '../../global/ConfirmDelete';
import ModalSwitcher from '../../global/ModalSwitcher';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';
import AddStory from './AddStory';
import EditStory from './EditStory';

const StoriesTable = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(1);
  const [selectedStoryId, setSelectedStoryId] = useState(0);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }
  const { loading, stories, refresh } = useStories();

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedStoryId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedStoryId(id);
    setIsOpen(true);
  }

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Id",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
      width: '40px'
    }, {
      id: 2,
      name: "Story Teller",
      selector: (row) => row.name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true
    }, {
      id: 3,
      name: "Address",
      selector: (row) => row.address,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true
    }, {
      id: 4,
      name: "Title",
      selector: (row) => row.title,
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true
    },

    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <Link to={`/app/stories/view/${row.id}`} className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></Link>
          <button className='btn btn-sm btn-primary' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}><i className='fa fa-edit'></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];

  let ModalToOpen;
  let modalTitle;
  let modalId;
  switch (Number(openModal)) {
    case 2:
      ModalToOpen = EditStory;
      modalTitle = "Edit Story";
      modalId = "edit-story";
      break;
    case 3:
      ModalToOpen = ConfirmDelete;
      modalTitle = "";
      modalId = "delete-story";
      break;
    default:
      ModalToOpen = AddStory;
      modalTitle = "Add new Story";
      modalId = "add-new-story";

  }

  return loading ? (<EllipsisLoader />) : (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToOpen closeModal={handleModalClick} id={selectedStoryId} path={`stories/${selectedStoryId}/delete`} refresh={refresh} />} />
      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3' onClick={(e) => {
                    e.stopPropagation();
                    setOpenModal(1)
                    setIsOpen(true);
                  }}> <i className='fa fa-plus'></i> Add Story</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            <MyDataTable columns={columns} data={stories?.data} title="" />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default StoriesTable