/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AuthenticatedAPIInstance } from '../utils/axios.js';

export const accessTokenKey = '@miles_of_smiles_access_token';
export const dummyJwtToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';

export const getUserById = async (userId: number) => {
  await AuthenticatedAPIInstance.get(`get_user/${userId}`)
    .then((response: { status?: any; data?: any; }) => {
      if (response.status === 200) {
        const { data } = response;
        return data;
      }
    }).catch((err: any) => err);
};
