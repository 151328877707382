import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { doCreate } from "../../../api";
import {
  ERRORMESSAGE,
  errorToastOptions,
  requisitionDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useDepartments from "../../../hooks/useDepartments";
import useRequisition from "../../../hooks/useRequisition";
import Validator from "../../../utils/Validator";
import DynamicForm from "../../global/DynamicForm";
import Input from "../../global/Input";
import InputDate from "../../global/InputDate";
import InputSelect from "../../global/InputSelect";
import useStaff from "../../../hooks/useStaff";
import useUsers from "../../../hooks/useUsers";
import { formatUserSelectData } from "../../../utils/helpers";
interface IAddRequisitionProps {
  onCancel: () => void;
}
const AddRequisition = ({ onCancel }: IAddRequisitionProps) => {
  const [loading, setLoading] = useState(false);
  const [requisition, setRequisition] = useState(requisitionDefaultData);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [selectedPurchaseOrderItems, setSelectedPurchaseOrderItems] = useState(
    []
  );
  const defaultData = [{ item_id: "", rate: "", quantity: "", total: "" }];
  const [values, setValues] = useState<Array<any>>(defaultData);

  const validator = new Validator();

  const { formattedDepartments } = useDepartments();
  const { formattedUsers } = useUsers();
  const { refresh, getPurchaseOrder, purchaseOrder } = useRequisition();
  useEffect(() => getPurchaseOrder(), []);
  console.log(formattedUsers);
  console.log(purchaseOrder);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(requisition, setRequisition)) {
      setLoading(true);
      const data = {
        department_id: requisition.department_id.data,
        description: requisition.description.data,
        requisition_date: requisition.requisition_date.data,
        delivery_date: requisition.delivery_date.data,
        items: values,
        requested_by: requisition.staff_id.data,
        reference_no: requisition.reference_no.data,
      };

      doCreate("purchase_requisitions", data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success(
              "Your requisition has been successfully submitted",
              successToastOptions
            );
            refresh();

            setTimeout(() => {
              onCancel();
            }, 1000);
          } else {
            toast.error(
              formatErrorMessage(response.data.data, response.data.message),
              errorToastOptions
            );
          }
        })
        .catch(() => {
          toast.error(ERRORMESSAGE, errorToastOptions);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  // const handleItemsChange = (obj: unknown) => {
  //   const { name, selectedOptions } = obj as HTMLSelectElement;
  //   if (name === 'items') {
  //     const selected = Array.from(selectedOptions).map((item) => item.value);
  //     const data = { [name]: { error: false, data: selected.join() } };
  //     setRequisition({ ...requisition, ...data });
  //   }
  // };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setRequisition({
        ...requisition,
        ...{
          [name]: { ...requisition[name], ...{ error: true, data: value } },
        },
      });
    }
  };
  const handlePurchaseOrderSelect = (e: any) => {
    const selectedOrderId = e.target.value;
    // Find the selected purchase order from the purchaseOrder array
    const selectedOrder = purchaseOrder.find(
      (order: any) => order.id === parseInt(selectedOrderId)
    );
    if (selectedOrder) {
      setSelectedPurchaseOrder(selectedOrder);
      setSelectedPurchaseOrderItems(selectedOrder.bid.items);
    }
  };

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-4">
          <InputDate
            name="requisition_date"
            label="Request Date"
            handleChange={handleSelectChange}
            required
            value={requisition.requisition_date.data}
            error={requisition.requisition_date.error}
            errorMessage="Please select the request date"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formattedDepartments}
            id="department_id"
            name="department_id"
            label="Department"
            handleChange={handleSelectChange}
            required
            value={requisition.requisition_date.data}
            error={requisition.requisition_date.error}
            errorMessage="Please select the department to handle this requisition"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formattedUsers}
            id="staff_id"
            name="staff_id"
            label="Staff Name(s)"
            handleChange={handleSelectChange}
            value={requisition.staff_id.data}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-4">
          <Input
            name="reference_no"
            label="Ref No"
            handleChange={handleChange}
            required
            value={requisition.reference_no.data}
            error={requisition.reference_no.error}
            errorMessage="Please enter the requisition reference number"
          />
        </div>
        <div className="col-sm-4">
          <Input
            name="description"
            label="Reason"
            handleChange={handleChange}
            required
            value={requisition.description.data}
            error={requisition.description.error}
            errorMessage="Please enter the requisition description"
          />
        </div>

        <div className="col-sm-4">
          <InputDate
            name="delivery_date"
            label="Delivery Date"
            handleChange={handleSelectChange}
            required
            noLimit
            value={requisition.delivery_date.data}
            error={requisition.delivery_date.error}
            errorMessage="Please select the estimated delivery date"
          />
        </div>
      </div>
      

      <div className="form-row">
        <DynamicForm
          defaultData={defaultData}
          setValues={setValues}
          values={values}
        />
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddRequisition;
