import React from 'react'
import ListOutGoingStock from '../../components/stocks/outGoing/ListOutGoingStock'
import StocksWrapper from '../../components/StocksWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const OutGoingStock = () => {
  return (
    <StocksWrapper>
      <>
        <Breadcrumbs title='Out Going Stock' parents={[{ link: ROUTES.STOCKS, title: 'Stock Management' }]} />
        <ListOutGoingStock />
      </>
    </StocksWrapper>
  )

}

export default OutGoingStock