import React from 'react'
import Profile from '../../components/settings/profile/Profile'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const MyProfile = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='My profile' />
        {/* body */}
        <Profile />
      </>
    </SettingsWrapper>

  )
}

export default MyProfile