import React, { useEffect } from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import { EllipsisLoader } from '../../../components/global/MyLoaders'
import FullClinicDetails from '../../../components/reports/view/FullClinicDetails'
import { useLastUrlSegment } from '../../../hooks/useQuery'
import useReports from '../../../hooks/useReports'
import Breadcrumbs from '../../../parts/Breadcrumbs'
import { ROUTES } from '../../../routes/routes'

const FullClinicReport = () => {
  const { getClinicReportDetails, clinicReportDetails, loading } = useReports();
  const id = useLastUrlSegment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getClinicReportDetails(id), [id]);
  return loading ? <EllipsisLoader /> : (
    <DashboardWrapper>
      <>
        <Breadcrumbs title={clinicReportDetails?.name} parents={[{ link: ROUTES.REPORTS, title: 'Reports' }, { link: ROUTES.REPORTS, title: 'Clinic report' }]} />

        <FullClinicDetails />

      </>
    </DashboardWrapper>
  )
}

export default FullClinicReport;