import React, { useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useReports from '../../../hooks/useReports';
import { EllipsisLoader } from '../../global/MyLoaders';

const FullProjectDetails = () => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { projectReportDetails, getProjectReportDetails, loading } = useReports();
  const id = useLastUrlSegment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getProjectReportDetails(id), [id]);
  return loading ? <EllipsisLoader /> : (
    <div className="row" ref={componentRef}>
      <div className="col-lg-12 mb-30">
        <div className="card">
          <div className="card-body py-4">
            <div className="user-bg" >
              <div className="user-info">
                <div className="row">
                  <div className="col-lg-6 align-self-center row mx-0 align-items-center">
                    <div className="user-dp mr-3">
                      <i className='fa fa-3x fa-briefcase'></i>
                    </div>
                    <div className="user-detail">
                      <h2 className="name">{projectReportDetails?.project_name}</h2>

                    </div>
                  </div>
                  <div className="col-lg-6 text-right align-self-center">
                    <button onClick={handlePrint} type="button" className="btn btn-sm btn-info d-print-none "><i className="ti-printer pr-1"></i>Print</button>
                  </div>
                </div>
                {/* 
                1 -Cost
                2 - Implementing partner
                3 - Location
                4 - Section
                5 - Level
                6 - Activity Evaluation
                7 - Challenges
                8 - Recommendation
                9 - Category
                */}

                <div className="row mt-3 mx-0">
                  <div className="col-md-8">
                    <h5>Details</h5>
                    <hr />
                    <div className="row mx-0">
                      <div className="col-md-6">
                        <h6>Cost</h6>
                        <p>{projectReportDetails?.cost}</p>
                      </div>
                      <div className="col-md-6">
                        <h6>Implementing partner</h6>
                        <p>{projectReportDetails?.implementing_partner}</p>
                      </div>
                    </div>

                    <div className="row mx-0 mt-3">
                      <div className="col-md-6">
                        <h6>Location</h6>
                        <p>{projectReportDetails?.location}</p>
                      </div>
                      <div className="col-md-6">
                        <h6>Category</h6>
                        <p>{projectReportDetails?.project_category?.name}</p>
                      </div>
                    </div>

                    <div className="row mx-0 mt-3">
                      <div className="col-md-12">
                        <h6>Activity Evaluation</h6>
                        {projectReportDetails?.activity_evaluation}
                      </div>
                      <div className="col-md-12 mt-2">
                        <h6>Challenges</h6>
                        {projectReportDetails?.challenges}
                      </div>
                      <div className="col-md-12 mt-2">
                        <h6>Recommendation</h6>
                        {projectReportDetails?.recommendations}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <h5>Project Activities</h5>
                    <hr />
                    <ul>
                      {projectReportDetails?.activities?.map((data: any) => (
                        <li key={data.id}>
                          {data?.activity_name}
                          <div className='border-1 p-2'>
                            <div className="row mx-0 justify-content-between align-items-center">
                              <p>Cost</p>
                              <span>{data?.cost}</span>
                            </div>
                            <div className="row mx-0 mt-2 justify-content-between align-items-center">
                              <p>Status</p>
                              <span className={data?.status === 'Completed' ? 'text-success' : 'text-warning'} >{data?.status}</span>
                            </div>

                            <div className="mt-2">
                              {data?.activity_teams.length > 0 && <h6>Team</h6>}
                              <ul>
                                {data?.activity_teams.map((activity: any) => (
                                  <React.Fragment key={activity?.id}>
                                    <li>{`${activity?.staff?.user?.first_name} ${activity?.staff?.user?.last_name}`} <span className='ml-3'>{activity?.role}</span></li>
                                    <hr />
                                  </React.Fragment>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullProjectDetails