import React, { useEffect } from 'react'
import ViewAsset from '../../components/assetManagement/view/ViewAsset'
import DashboardWrapper from '../../components/DashboardWrapper'
import { EllipsisLoader } from '../../components/global/MyLoaders'
import useAssetManagement from '../../hooks/useAssetManagement'
import { useLastUrlSegment } from '../../hooks/useQuery'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const View = () => {
  const id = useLastUrlSegment();
  const { getSelectedAsset, loading } = useAssetManagement();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedAsset(id), [id]);
  return (
    <DashboardWrapper>
      <>
        {loading ? <EllipsisLoader /> : (<>

          <Breadcrumbs title='Asset name' parents={[{ link: ROUTES.ASSETS, 'title': 'Asset Management' }]} />
          {/* body */}
          <ViewAsset />
        </>)}
      </>
    </DashboardWrapper>
  )
}

export default View