import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { stockGet } from '../api/stocks';
import { RootState } from '../redux/app/store';
import { setFormattedSuppliers, setSelectedSupplier, setSuppliers } from '../redux/reducer/stocks';
import { formatDepartmentSelectData } from '../utils/helpers';

const useSuppliers = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    suppliers, formattedSupplier, selectedSupplier
  } = useSelector(
    (state: RootState) => state.stocks,
  );

  useEffect(() => {

    if (suppliers.length > 0) {
      setLoading(false);
      return;
    }

    stockGet('suppliers').then((response) => {
      if (response.status === 200) {
        dispatch(setSuppliers(response.data.data));
        dispatch(setFormattedSuppliers(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    stockGet('suppliers').then((response) => {
      if (response.status === 200) {
        dispatch(setSuppliers(response.data.data));
        dispatch(setFormattedSuppliers(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSupplierName = (id: number) => {
    if (suppliers) {
      const supplier = suppliers.filter((item: any) => item?.id === id);

      if (supplier) {
        return supplier[0]?.name;
      }
    }
    return '';
  }

  const getSupplier = (id: number) => {
    setLoading(true);
    stockGet('suppliers/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedSupplier(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { suppliers, loading, refresh, formattedSupplier, getSupplier, selectedSupplier, getSupplierName }
}

export default useSuppliers