import React from "react";
import AccountDetail from "../../components/accounting/accountCharts/AccountDetail";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import { ROUTES } from "../../routes/routes";

const AccountDetails = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs
          title="View Account Details"
          parents={[{ link: ROUTES.COA, title: "Charts of account" }]}
        />
        {/* body */}
        <AccountDetail />
      </>
    </DashboardWrapper>
  );
};

export default AccountDetails;
