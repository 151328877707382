import React from 'react'
import GeneratePayroll from '../../../components/hr/payrollManagement/GeneratePayroll'
import PayrollWrapper from '../../../components/PayrollWrapper'
import Breadcrumbs from '../../../parts/Breadcrumbs'

const Genarate = () => {
  return (
    <PayrollWrapper>
      <>
        <Breadcrumbs title='Payroll Management' />
        <GeneratePayroll />
      </>
    </PayrollWrapper>
  )
}

export default Genarate;