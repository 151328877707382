import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate, upload } from '../../../../api';
import { ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../constants';
import { formatErrorMessage } from '../../../../helpers';
import Input from '../../../global/Input';
import TextEditor from '../../../global/TextEditor';

interface ISendCompletionProps {
  selectedParameter: any;
  refresh: () => void;
  closeModal: () => void;
}

const SendCompletion = ({ closeModal, selectedParameter, refresh }: ISendCompletionProps) => {
  const [formLoading, setLoading] = useState(false);
  const [sendData, setSendData] = useState({ data: '', error: false });
  const [sendDataFile, setSendDataFile] = useState({ data: '', error: false });

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (sendData.data === '' && selectedParameter.general.parameter_type === 1) {
      setSendData({ data: '', error: true });
      return;
    }

    try {
      setLoading(true);
      let newProject;
      if (selectedParameter.general.parameter_type === 1) {
        const data = {
          parameter: sendData.data
        };
        newProject = await doCreate(`projects/activity-parameters/${selectedParameter.id}/attach_parameter_details`, data);
      } else {
        const formdata = new FormData();
        formdata.append('file', sendDataFile.data);
        formdata.append('activity_id', selectedParameter.activity_id);
        formdata.append('general_activity_parameter_id', selectedParameter.general_activity_parameter_id);
        newProject = await upload(`activity-files`, formdata);
      }
      if (newProject.data.success === true) {
        toast.success(selectedParameter.general.name + " has marked as complete", successToastOptions);

        refresh(); // refresh the hook store
        setTimeout(() => {
          closeModal();
        }, 1000);
      } else {
        toast.error(formatErrorMessage(newProject.data.data, newProject.data.message), errorToastOptions);
      }
    } catch (err) {
      toast.error(ERRORMESSAGE, errorToastOptions);
    } finally {
      setLoading(false)
    }
  }

  const handleEditorChange = (e: any) => {
    const { value } = e;
    if (value !== '') {
      setSendData({ data: value, error: false });
    } else {
      setSendData({ data: value, error: true });
    }
  }

  const handleFileChange = (e: any) => {
    if (e.target.files) {
      setSendDataFile({ data: e.target.files[0], error: false });
    } else {
      setSendDataFile({ data: '', error: true });
    }
  }


  return (
    <form>
      <div className="form-row">
        {selectedParameter.general.parameter_type === 1 ? (

          <div className="col-sm-12">
            <TextEditor name='parameter' label='Completion parameters' handleChange={handleEditorChange} required value={sendData.data} error={sendData.error} errorMessage="Please provide full details" />
          </div>
        ) : (
          <div className="col-sm-12">
            <Input type='file' name='files' label='Completion parameters' handleChange={handleFileChange} required error={sendDataFile.error} errorMessage="Please attach a completion file" />
          </div>
        )}

      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-start">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Submitting..." : "Submit"}</button>
      </div>
    </form>
  )
}

export default SendCompletion