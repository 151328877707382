import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import useAccounts from "../../../hooks/useAccounts";
import { baseURLImage } from "../../../utils/axios";
import { downloadCSV } from "../../../utils/helpers";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import EditSubCategory from "./EditSubCategory";
import AddSubCategories from "./AddSubCategories";
import useSubCategories from "../../../hooks/useSubCategories";

const ListSubCategories = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});

  const { subCategories, getSubCategories, loading } = useSubCategories();

  useEffect(() => getSubCategories(), []);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Name",
      selector: (row) => row.sub_cat_name,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 2,
      name: "Code",
      selector: (row) => row.sub_cat_code,

      reorder: true,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      id: 3,
      name: "Description",
      selector: (row) => row.description,
      reorder: true,
      sortable: true,
      wrap: true,
    },

    {
      id: 4,
      name: "Category ",
      selector: (row) => row?.account_category?.cat_name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className="row">
          {/* <button className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></button> */}
          <button className="btn btn-sm btn-primary">
            <i
              className="fa fa-edit"
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(row);
              }}
            ></i>
          </button>

          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleEditClick = (row: any) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(row?.id);
    setSelectedRow(row);

    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = "edit-account-chart";
      modalTitle = "Edit New Sub Category";
      ModalToRender = EditSubCategory;

      break;
    case 3:
      modalId = "delete-chart";
      modalTitle = "";
      ModalToRender = ConfirmDelete;
      break;
    default:
      modalId = "add-account-chart";
      modalTitle = "Add New Sub Category";
      ModalToRender = AddSubCategories;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            row={selectedRow}
            closeModal={handleModalClick}
            path={`account-charts/${selectedId}/delete`}
            refresh={getSubCategories}
          />
        }
      />
      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(0);
                      setIsOpen(true);
                    }}
                  >
                    <i className="fa fa-plus"></i>Add new account
                  </button>
                </div>
                <a
                  href={`${baseURLImage}/account-charts/print`}
                  rel="noreferrer"
                  target={"_blank"}
                  className="btn btn-secondary ml-3"
                >
                  <i className="fa fa-download"></i>Download
                </a>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? (
              <EllipsisLoader />
            ) : (
              <MyDataTable columns={columns} data={subCategories} title="" />
            )}

            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListSubCategories;
