import React, { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import useCommittees from "../../../../hooks/useCommittees";
import { formatStatusID } from "../../../../utils/helpers";
import ConfirmDelete from "../../../global/ConfirmDelete";
import ModalSwitcher from "../../../global/ModalSwitcher";
import MyDataTable from "../../../global/MyDataTable";
import { EllipsisLoader } from "../../../global/MyLoaders";
import AddMember from "./AddMember";

const ListReviewingTeam = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  const {
    reviewingCommittee,
    loading,
    getReviewingCommitteeMembers,
    refreshReview,
  } = useCommittees();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getReviewingCommitteeMembers(), []);

  const departTableColumns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Staff",
      cell: (row) => (
        <span>{`${row.user?.first_name} ${row.user?.last_name}`}</span>
      ),
      reorder: true,
      sortable: true,
      wrap: true,
      grow: 3,
    },
    {
      id: 2,
      name: "Rank",
      selector: (row) => row.rank,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 3,
      name: "Status",
      selector: (row) => (
        <span className={`badge ${formatStatusID(row.status_id).className}`}>
          {formatStatusID(row.status_id).title}
        </span>
      ),
      reorder: true,
      sortable: true,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className="row">
          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(1); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 1:
      modalId = "delete-member";
      modalTitle = "Remove member";
      ModalToRender = ConfirmDelete;
      break;

    default:
      modalId = "add-new-reviewing-team-member";
      modalTitle = "Add New Reviewing team meber";
      ModalToRender = AddMember;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            closeModal={handleModalClick}
            refresh={refreshReview}
            path={`reviewing-commitee/${selectedId}/remove`}
          />
        }
      />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(0);
                      setIsOpen(true);
                    }}
                  >
                    <i className="fa fa-plus"></i>Add new member
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? (
              <EllipsisLoader />
            ) : (
              <MyDataTable
                data={reviewingCommittee}
                columns={departTableColumns}
                title=""
              />
            )}

            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListReviewingTeam;
