import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import ListTrailBalance from "../../components/accounting/trail_balance/ListTrailBalance";
import PayrollReport from "../../components/reports/PayrollReport";

const PayrollReports = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Payroll Report" />
        {/* body */}
        <PayrollReport />
      </>
    </DashboardWrapper>
  );
};

export default PayrollReports;
