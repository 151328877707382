import React from 'react'
import Card from '../../../parts/Card'
import CreatePayroll from './CreatePayroll'

const ManagePayrollAddPage = () => {
  return (
    <div>
      <Card>
        <CreatePayroll />

      </Card>
    </div>
  )
}

export default ManagePayrollAddPage