import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories, getAllOtherCategory, getCategory } from '../api/projects';
import { formatDataToSelectFormData } from '../helpers';
import { RootState } from '../redux/app/store';
import { setCategories, setCategoryFormData, setOtherCategories, setSelectedCategory } from '../redux/reducer/projects';

const useCategories = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    categories, categoryFormData, selectedCategory, otherCategories
  } = useSelector(
    (state: RootState) => state.projects,
  );

  useEffect(() => {

    if (Object.keys(categories).length > 0) {
      setLoading(false);
      return;
    }

    getAllCategories().then((response) => {
      if (response.status === 200) {
        dispatch(setCategories(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const refresh = () => {
    getAllCategories().then((response) => {
      if (response.status === 200) {
        dispatch(setCategories(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedCategory = (id: number) => {
    setLoading(true);
    getCategory(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedCategory(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getOtherCategories = (id: number) => {
    setLoading(true);
    getAllOtherCategory(id).then((response) => {
      if (response.status === 200) {
        dispatch(setOtherCategories(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getCategoriesFormData = () => {

    if (categoryFormData.length > 0) {
      setLoading(false);
      return;
    }

    getAllCategories().then((response) => {
      if (response.status === 200) {
        const d = formatDataToSelectFormData(response.data.data);
        dispatch(setCategoryFormData(d));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { categories, loading, refresh, getCategoriesFormData, categoryFormData, getSelectedCategory, selectedCategory, getOtherCategories, otherCategories }
}

export default useCategories