import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ViewFact from '../../components/mobile_app/funFacts/ViewFact'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ViewFunFact = () => {
  return (
    <>

      <DashboardWrapper>
        <>
          <Breadcrumbs title='View fun fact' parents={[{ link: ROUTES.FACTS, title: 'Fun Facts' }]} />
          {/* <!-- main body --> */}

          <ViewFact />
        </>

      </DashboardWrapper>
    </>
  )
}

export default ViewFunFact