import { AuthenticatedAPIInstance } from "../../utils/axios";

export const createAsset = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("assets/create", data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const edditAsset = async (id: number, data: any) => {
  const response = await AuthenticatedAPIInstance.post("assets/" + id + '/update', data).then((response) => response)
    .catch((error) => error);
  return response;
}


export const getAllAssets = async () => {
  const allStories = await AuthenticatedAPIInstance.get("assets").then((response) => response)
    .catch((error) => error);
  return allStories;
}

export const getAsset = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get("assets/" + id).then((response) => response)
    .catch((error) => error);
  return response;
}

export const getAllAssetTypes = async () => {
  const allStories = await AuthenticatedAPIInstance.get("asset_types").then((response) => response)
    .catch((error) => error);
  return allStories;
}

export const createAssetType = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("asset_types/create", data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const getAllAssetsTypeCategories = async () => {
  const allStories = await AuthenticatedAPIInstance.get("asset_categories").then((response) => response)
    .catch((error) => error);
  return allStories;
}
