import React, { useState } from 'react';
import MyModal from '../../global/MyModal';
import AddTreatment from './AddTreatment';
import TreatmentTable from './TreatmentTable';

const ListTreatments = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }


  return (
    <>
      <MyModal modalOpen={isOpen} setModalOpen={setIsOpen} id="add-new-patient" title="Add new treatment reminder">
        <AddTreatment closeModal={handleModalClick} />
      </MyModal>

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3' onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                  }}>Add Treatment</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            <TreatmentTable />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )


}

export default ListTreatments