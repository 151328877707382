

const useSessionStorage = (): any => {

  const isAdmin = (): any => {
    const role = JSON.parse(sessionStorage.user)?.user.role_id;
    return role === 1;
  }

  const isValidRole = (id: number | Array<number>): any => {
    const role = JSON.parse(sessionStorage.user)?.user.role_id;
    if (typeof id === 'number') {
      return role === id;
    }
    return id.includes(role);
  }

  const userId = (): any => {
    return JSON.parse(sessionStorage.user)?.user.id;
  }

  const userRoleId = (): any => {
    return Number(JSON.parse(sessionStorage.user)?.user.role_id);
  }

  return {
    isAdmin, userId, userRoleId, isValidRole
  };
}

export default useSessionStorage;

