import React, { useState } from 'react'
import { useLastUrlSegment } from '../../../hooks/useQuery'
import useStaff from '../../../hooks/useStaff'
import Card from '../../../parts/Card'
import CardContentWrapper from '../../global/CardContentWrapper'
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder'
import CardListItem from '../../global/CardListItem'
import { EllipsisLoader } from '../../global/MyLoaders'
import MyModal from '../../global/MyModal'
import EditAddress from './edit/EditAddress'

const EmployeeAddress = () => {
  const { selectedEmployee, loading } = useStaff();
  const [isOpen, setIsOpen] = useState(false);
  const id = useLastUrlSegment();
  return loading ? <EllipsisLoader /> : (
    <>
      <MyModal title={`Edit ${selectedEmployee?.user?.first_name} ${selectedEmployee?.user?.last_name} Address`} id='edit-employee-address' modalOpen={isOpen} setModalOpen={setIsOpen}>
        <EditAddress id={id} onCancel={() => setIsOpen(false)} />
      </MyModal>
      <Card>
        <>
          <CardHeaderWithBottomBorder title="Address Info" isButton >
            <button type='button' className='btn btn-sm btn-info' onClick={(e) => {
              e.stopPropagation();
              setIsOpen(true);
            }} >Edit</button>
          </CardHeaderWithBottomBorder>
          <CardContentWrapper>
            <div className='row'>
              <CardListItem value={`${selectedEmployee?.address?.district}`} title='District' />
              <CardListItem value={`${selectedEmployee?.address?.county}`} title='County' />
              <CardListItem value={`${selectedEmployee?.address?.sub_county}`} title='Sub County' />
              <CardListItem value={`${selectedEmployee?.address?.resident_town}`} title='Resident Town' />
              <CardListItem value={`${selectedEmployee?.address?.resident_village}`} title='Resident Village' />
              <CardListItem value={`${selectedEmployee?.address?.resident_phone}`} title='Resident Phone' />
            </div>
          </CardContentWrapper>
        </>
      </Card>
    </>
  )
}

export default EmployeeAddress