import React from 'react'
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routes/routes';
import DashboardWrapper from '../DashboardWrapper';


const NotFound = () => {
  return (
    <DashboardWrapper>
      <div role="alert" className='row align-items-center justify-content-center h-100 relative text-center'>
        <div className="col">
          <h3>Page not found</h3>
          <Link className='btn btn-outline-info mt-3' to={ROUTES.DASHBOARD} >Back Home</Link>

        </div>
      </div>
    </DashboardWrapper>
  )
}

export default NotFound;