import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { budgetDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import { formatDepartmentSelectData, getFormData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import RadioButton from '../../global/RadioButton';

interface IAddBudgetProps {
  closeModal: () => void;
  refresh: () => void;
  budgets: Array<any>
}

const AddBudget = ({ closeModal, refresh, budgets }: IAddBudgetProps) => {
  const [budget, setBudget] = useState(budgetDefaultData);
  const [loading, setLoading] = useState(false);
  const [isParent, setIsParent] = useState("0");
  const validator = new Validator();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setBudget({ ...budget, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setBudget({ ...budget, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleDateChange = (e: any) => {
    const { name, value } = e;
    if (value !== '') {
      // update the state
      setBudget({ ...budget, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setBudget({ ...budget, ...{ [name]: { error: true, data: value } } });
    }
  }

  const handleRadioButtonChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    setIsParent(value);
    if (value !== '') {
      // update the state
      setBudget({ ...budget, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setBudget({ ...budget, ...{ [name]: { error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(budget, setBudget)) {
      setLoading(true);

      const data = getFormData(budget);

      try {
        const response = await doCreate('budget/create', data);
        if (response.data.success === true) {
          toast.success('New budget created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <form>

      <div className="form-row">

        <div className="col-sm-6">
          <Input name='name' label='Name' handleChange={handleChange} error={budget?.name.error} errorMessage="Please provide the name" value={budget?.name.data} required />
        </div>

        <div className="col-sm-6">
          <Input type='number' name='exp_amount' label='Estimated Amount' handleChange={handleChange} error={budget?.exp_amount.error} errorMessage="Please provide the estimated amount" value={budget?.exp_amount.data} required />
        </div>
      </div>
      <div className="form-row">

        <div className="col-sm-6">
          <InputDate name='start_date' label='Start Date' handleChange={handleDateChange} error={budget?.start_date.error} errorMessage="Please select a start date" value={budget?.start_date.data} required />
        </div>
        <div className="col-sm-6">
          <InputDate name='end_date' label='End Date' handleChange={handleDateChange} error={budget?.end_date.error} errorMessage="Please select an end date" value={budget?.end_date.data} required noLimit />
        </div>
      </div>

      <div className="form-row">

        <div className="col-sm-6">
          <div className="col-sm-6">
            <label>Belongs to another budget?</label>
            <div>
              <RadioButton handleChange={handleRadioButtonChange} name='is_parent' label='No' checked={isParent === '0'} value={'0'} id="is_parent_yes" />
              <RadioButton handleChange={handleRadioButtonChange} name='is_parent' label='Yes' checked={isParent === '1'} value={'1'} id="is_parent_no" />
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          {isParent === '1' && (
            <InputSelect data={formatDepartmentSelectData(budgets)} name='budget_id' label='Parent Budget' handleChange={handleDateChange} error={budget?.budget_id.error} errorMessage="Please select the parent budget" value={budget?.budget_id.data} required={isParent === '1'} id="budget_id" />)
          }
        </div>
      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>{loading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddBudget