import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { doCreate } from '../../../../api'
import { employeeAddressDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../constants'
import { formatErrorMessage } from '../../../../helpers'
import useStaff from '../../../../hooks/useStaff'
import Validator from '../../../../utils/Validator'
import Input from '../../../global/Input'
import { EllipsisLoader } from '../../../global/MyLoaders'

interface IEditAddressProps {
  onCancel: () => void
  id: number
}

const EditAddress = ({ id, onCancel }: IEditAddressProps) => {
  const [address, setAddress] = useState(employeeAddressDefaultData);
  const { getStaff, selectedEmployee, loading } = useStaff();
  const [formLoading, setLoading] = useState(false);
  const validator = new Validator();

  // edit
  const formNames = ['district', 'county', 'sub_county', 'village', 'resident_town', 'resident_village', 'resident_phone'];

  useEffect(() => {
    let addressData = employeeAddressDefaultData;
    if (!loading && id) {
      for (let x of formNames) {
        addressData = { ...addressData, ...{ [x]: { ...addressData[x], ...{ error: false, data: selectedEmployee.address[x] } } } };
      }
      setAddress(addressData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);


  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box

    if (value !== '') {
      // update the state
      setAddress({ ...address, ...{ [name]: { ...address[name], error: false, data: value } } });
    } else {
      // indicate an error
      setAddress({ ...address, ...{ [name]: { ...address[name], error: true, data: value } } });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (validator.checkForEmptyFields(address, setAddress)) {
      // continue
      const data = {
        district: address.district.data,
        county: address.county.data,
        sub_county: address.sub_county.data,
        village: address.village.data,
        resident_town: address.resident_town.data,
        resident_village: address.resident_village.data,
        resident_phone: address.resident_phone.data,
      }
      setLoading(true);
      doCreate('/staff/' + selectedEmployee?.address?.id + '/edit_address', data).then((response) => {
        if (response.data.error) {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        } else {
          toast.success("Information updated succcessfully", successToastOptions);
          getStaff(Number(id));
          setTimeout(() => {
            onCancel();
          }, 1000);
        }
      }).catch(() => {
        toast.error(ERRORMESSAGE, errorToastOptions);
      }).finally(() => {
        setLoading(false);
      })
    }

  }
  return loading ? <EllipsisLoader /> : (
    <fieldset>
      <div className="form-row">
        <div className="col-sm-6">
          <Input label='District' name='district' handleChange={handleChange} value={address.district.data} error={address.district.error} errorMessage="Please provide the district" required />
        </div>
        <div className="col-sm-6">
          <Input label='County' name='county' handleChange={handleChange} value={address.county.data} error={address.county.error} errorMessage="Please provide the county" required />
        </div>
        <div className="col-sm-6">
          <Input label='Sub County' name='sub_county' handleChange={handleChange} value={address.sub_county.data} error={address.sub_county.error} errorMessage="Please provide the sub county" required />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input label='Village' name='village' handleChange={handleChange} value={address.village.data} error={address.village.error} errorMessage="Please provide the village" required />
        </div>
        <div className="col-sm-6">
          <Input label='Resident Town' name='resident_town' handleChange={handleChange} value={address.resident_town.data} error={address.resident_town.error} errorMessage="Please provide the resident town" required />
        </div>
        <div className="col-sm-6">
          <Input label='Resident Village' name='resident_village' handleChange={handleChange} value={address.resident_village.data} error={address.resident_village.error} errorMessage="Please provide the resident village" required />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input label='Resident phone number' name='resident_phone' handleChange={handleChange} value={address.resident_phone.data} error={address.resident_phone.error} errorMessage="Please provide the resident phone number" required />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='button' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='button' className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </fieldset>
  )
}

export default EditAddress