
import { createSlice } from '@reduxjs/toolkit';

export interface IAppointment {
  patients: any;
}

const initialState: IAppointment = {
  patients: {},
};

export const patientsReducer = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setPatients: (state, { payload }) => {
      state.patients = payload;
    },
  },
});

const { reducer, actions } = patientsReducer;

export const {
  setPatients
} = actions;

export default reducer;