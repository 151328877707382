import React from 'react';
import "./myLoaders.css";

const HeartLoader = () => {
  return (
    <div className="lds-heart"><div></div></div>
  )
}

const EllipsisLoader = () => {
  return (
    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  )
}

const PageLoader = () => {
  return (
    <div className='page-loader-container'>
      <div className="lds-facebook"><div></div><div></div><div></div></div>
    </div>
  )
}

export { HeartLoader, EllipsisLoader, PageLoader } 