/* eslint-disable react/destructuring-assignment */
import React from "react";
import "./myTab.css";

export interface ITabsData {
  tabId: number;
  title: string;
  component: React.ReactNode;
  large?: boolean;
}

export interface IMultipleTabsData {
  name: string;
  tabId: number;
  data: ITabsData[];
}

export const MyTabs = ({
  TabsData,
}: {
  TabsData: ITabsData[];
}): React.ReactElement => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <div className="w-full mb-40 mx-0">
        <ul
          className="row align-item-center list-none pt-3 mb-3"
          role="tablist"
        >
          {TabsData.length > 1 &&
            TabsData.map(
              (tab: ITabsData) =>
                tab && (
                  <li
                    key={tab.tabId}
                    className="-mb-px last:mr-0 flex-auto text-center"
                  >
                    <a
                      className={`text-xs font-bold ${
                        tab.large ? "px-3" : "px-5"
                      } py-3 border-b-4 block  ${
                        openTab === tab.tabId
                          ? "tab-active"
                          : "tab-inactive-gray-text"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(tab.tabId);
                      }}
                      data-toggle="tab"
                      href={`#link${tab.tabId}`}
                      role="tablist"
                    >
                      {tab.title}
                    </a>
                  </li>
                )
            )}
        </ul>
        <div className="relative bg-white w-full mb-6">
          <div className="px-3 py-5 flex-auto">
            <div className="tab-content tab-space">
              {TabsData.length > 1 &&
                TabsData.map((tab: ITabsData) => (
                  <div
                    key={tab.tabId}
                    className={openTab === tab.tabId ? "block" : "hidden"}
                    id="link1"
                  >
                    {openTab === tab.tabId && tab.component}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const MyMultipleTabs = ({
  multipleData,
}: {
  multipleData: IMultipleTabsData[];
}): React.ReactElement => {
  const [openTab, setOpenTab] = React.useState(1);
  const [openParentTab, setOPenParentTab] = React.useState(1);
  return (
    <>
      <div className="w-full mb-40 mx-0">
        <ul className="row align-item-start list-none pt-3 mb-3" role="tablist">
          {multipleData.length > 0 &&
            multipleData.map(
              ({ data, name, tabId }) =>
                name && (
                  <li key={tabId} className="-mb-px last:mr-0 flex-auto">
                    <a
                      className={`text-xs font-bold ${"px-5"} py-3 border-b-4 block  ${
                        openParentTab === tabId
                          ? "tab-parent-active"
                          : "tab-inactive-gray-text"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOPenParentTab(tabId);
                        setOpenTab(1);
                      }}
                      data-toggle="tab"
                      href={`#link${tabId}`}
                      role="tablist"
                    >
                      {name}
                    </a>
                  </li>
                )
            )}
        </ul>
        <ul
          className="row align-item-center list-none pt-3 mb-3 mt-3"
          role="tablist"
        >
          {multipleData.length > 0 &&
            multipleData
              .filter(({ tabId }) => openParentTab === tabId)
              .map(({ data }) =>
                data.map(
                  (tab) =>
                    tab && (
                      <li
                        key={tab.tabId}
                        className="-mb-px last:mr-0 flex-auto text-center"
                      >
                        <a
                          className={`text-xs font-bold ${
                            tab.large ? "px-3" : "px-5"
                          } py-3 border-b-4 block  ${
                            openTab === tab.tabId
                              ? "tab-active"
                              : "tab-inactive-gray-text"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(tab.tabId);
                          }}
                          data-toggle="tab"
                          href={`#link${tab.tabId}`}
                          role="tablist"
                        >
                          {tab.title}
                        </a>
                      </li>
                    )
                )
              )}
        </ul>
        <div className="relative bg-white w-full mb-6">
          <div className="px-3 py-5 flex-auto">
            <div className="tab-content tab-space">
              {multipleData.length > 0 &&
                multipleData
                  .filter(({ tabId }) => openParentTab === tabId)
                  .map(({ data }) =>
                    data.map((tab) => (
                      <div
                        key={tab.tabId}
                        className={openTab === tab.tabId ? "block" : "hidden"}
                        id="link1"
                      >
                        {openTab === tab.tabId && tab.component}
                      </div>
                    ))
                  )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
