import React, { useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import useAccounts from "../../../hooks/useAccounts";
import { useLastUrlSegment } from "../../../hooks/useQuery";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import { InputDateRange, SingleInputDateRange } from "../../global/InputDate";
import { formatCurrency } from "../../../utils/helpers";

const AccountDetail = () => {
  const id = useLastUrlSegment();

  const {
    getAccountTransactionDetails,
    getAccountTransactionDetailsOptions,
    selectedAccountDetails,
    loading,
  } = useAccounts();

  useEffect(() => getAccountTransactionDetails(id), [id]);

  const handlePageChange = (page: number) => {
    getAccountTransactionDetailsOptions(id, page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    getAccountTransactionDetailsOptions(id, page, newPerPage);
  };

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "#",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
      maxWidth: "50px",
    },
    {
      id: 2,
      name: "Date",
      selector: (row) => row.transaction_date,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 3,
      name: "Narrative",
      selector: (row) => row.narrative,
      wrap: true,
      grow: 2,
    },
    {
      id: 4,
      name: "Debit",
      selector: (row) =>
        row.debit_amount ? formatCurrency(row.debit_amount) : row.debit_amount,
      reorder: true,
      sortable: true,
      wrap: true,
    },
    {
      id: 5,
      name: "Credit",
      selector: (row) =>
        row.credit_amount
          ? formatCurrency(row.credit_amount)
          : row.credit_amount,
      reorder: true,
      sortable: true,
      wrap: true,
    },
  ];

  return loading ? (
    <EllipsisLoader />
  ) : (
    <div>
      <div className="card p-3 mb-10">
        <table className="table table-bordered table-striped">
          <tbody>
            <tr>
              <th>Account Name</th>
              <td>{selectedAccountDetails?.account_name}</td>
              <th>Account Code</th>
              <td>{selectedAccountDetails?.account_no}</td>
            </tr>

            <tr>
              <th>Category</th>
              <td>
                {
                  selectedAccountDetails?.account_sub_category?.account_category
                    ?.cat_name
                }
              </td>
              <th>Sub Category</th>
              <td>
                {" "}
                {selectedAccountDetails?.account_sub_category?.sub_cat_code +
                  " " +
                  selectedAccountDetails?.account_sub_category?.sub_cat_name}
              </td>
            </tr>
            <tr>
              <th>Description</th>
              <td colSpan={3}>{selectedAccountDetails?.description}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="card p-3 mb-10">
        <div className="row mx-0 w-full justify-content-between">
          <h5>Transactions List</h5>

          <div className="row mx-0  justify-content-between">
            <p className="mr-3">
              <span className="font-bold">Total Credit:</span>{" "}
              {formatCurrency(
                selectedAccountDetails?.transactions?.data?.reduce(
                  (acc: any, c: any) =>
                    c?.credit_amount ? Number(c?.credit_amount) + acc : acc,
                  0
                )
              )}
            </p>
            <p>
              <span className="font-bold">Total Debit:</span>{" "}
              {formatCurrency(
                selectedAccountDetails?.transactions?.data?.reduce(
                  (acc: any, c: any) =>
                    c?.debit_amount ? Number(c?.debit_amount) + acc : acc,
                  0
                )
              )}
            </p>
          </div>

          <div>
            <SingleInputDateRange />
          </div>
        </div>
        <DataTable
          pagination
          highlightOnHover
          columns={columns}
          data={selectedAccountDetails?.transactions?.data}
          title=""
          progressPending={loading}
          paginationServer
          paginationTotalRows={selectedAccountDetails?.transactions?.total}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>
    </div>
  );
};

export default AccountDetail;
