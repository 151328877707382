import React, { useEffect } from "react";
import useCashFlow from "../../../hooks/useCashFlow";
import { EllipsisLoader } from "../../global/MyLoaders";
import { formatCurrency } from "../../../utils/helpers";
import moment from "moment";

const ListCashFlow = () => {
  const { loading, cashFlow, getCashFlow } = useCashFlow();

  useEffect(() => getCashFlow(), []);

  return loading ? (
    <EllipsisLoader />
  ) : (
    <div>
      <table className="table table-sm table-bordered" width="100%">
        <tbody>
          <tr style={{ backgroundColor: "#1c84c6", color: "#fff" }}>
            <td colSpan={2}>
              <h5 className="text-center font-bold">
                Cash Flow Statement &nbsp; &nbsp; &nbsp;- &nbsp;
                <span>{moment().format("ll")}</span>
              </h5>
            </td>
          </tr>
          <tr className="table-info" style={{ color: "#000" }}>
            <td colSpan={2}>
              <h5>Cash from Operational Activities </h5>
            </td>
          </tr>

          <tr style={{ backgroundColor: "#fff" }}>
            <td style={{ paddingLeft: "30px" }}>Net Income </td>
            <td>
              <span>
                {" "}
                <span>{formatCurrency(cashFlow?.net_income)}</span>{" "}
              </span>
            </td>
          </tr>

          <tr style={{ backgroundColor: "#fff" }}>
            <td style={{ paddingLeft: "30px" }}>Depreciation</td>
            <td>
              <span>{formatCurrency(cashFlow?.depreciation)}</span>
            </td>
          </tr>
          <tr style={{ backgroundColor: "#fff" }}>
            <td style={{ paddingLeft: "30px" }}>Appreciation</td>
            <td>
              <span>{formatCurrency(cashFlow?.appreciation)}</span>
            </td>
          </tr>
          <tr style={{ backgroundColor: "#fff" }}>
            <td style={{ paddingLeft: "30px" }}>
              (Decrease)/Increase in Payables
            </td>
            <td>
              <span>{formatCurrency(cashFlow?.increase_in_payable)}</span>
            </td>
          </tr>

          <tr>
            <td colSpan={1}>
              <h5> &nbsp;</h5>
            </td>
          </tr>
          <tr className="table-info" style={{ color: "#000" }}>
            <td colSpan={2}>
              <h5>Cash From Investments Activities</h5>
            </td>
          </tr>
          {cashFlow?.investment?.map((item: any) => (
            <tr key={item.account_chart_id}>
              <td style={{ paddingLeft: "30px" }}>
                <a>{item.account_name}</a>
              </td>
              <td>
                <span className="text-normal">{formatCurrency(item.sum)}</span>
              </td>
            </tr>
          ))}

          <tr>
            <td colSpan={2}>
              <h5> &nbsp;</h5>
            </td>
          </tr>
          <tr className="table-info" style={{ color: "#000" }}>
            <td colSpan={2}>
              <h5>Cash from Financing Activities</h5>
            </td>
          </tr>

          {cashFlow?.finance?.map((item: any) => (
            <tr key={item.account_chart_id}>
              <td style={{ paddingLeft: "30px" }}>
                <a>{item.account_name}</a>
              </td>
              <td>
                <span className="text-normal">{formatCurrency(item.sum)}</span>
              </td>
            </tr>
          ))}

          <tr>
            <td colSpan={2}>
              <h5> &nbsp;</h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ListCashFlow;
