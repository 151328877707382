import React from "react";
import Card from "../../parts/Card";
import { formatCurrency } from "../../utils/helpers";

interface IPayrollInfoCardProps {
  title: string;
  amount: number;
  icon?: string;
  bg?: string;
}

const PayrollInfoCard = ({
  title,
  amount,
  icon = "fa-plus",
  bg = "info",
}: IPayrollInfoCardProps) => {
  return (
    <div className="col col-xs-6">
      <Card>
        <div className={`row border border-${bg} m-0 flex-nowrap`}>
          <div className={`row align-items-center m-0 bg-${bg} p-3 mr-3`}>
            <i className={`fa ${icon} fa-2x text-white`}></i>
          </div>
          <div className="row flex-column m-0 flex-nowrap">
            <p className="font-medium">{title}</p>
            <p className="font-light">Amount (UGSHS)</p>
            <p className="font-bold">{formatCurrency(amount)}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PayrollInfoCard;
