import React from 'react'
import ListBillings from '../../components/accounting/billing/ListBillings'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Billing = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Billings' />
        {/* body */}
        <ListBillings />
      </>
    </DashboardWrapper>

  )
}

export default Billing