import { createSlice } from '@reduxjs/toolkit';

export interface IStocks {
  stocks: any;
  stockProducts: any;
  suppliers: any;
  brands: any;
  formattedBrand: any;
  productCategories: any;
  formattedProductCategories: any;
  formattedStockProducts: any;
  outGoingStocks: any;
  formattedSupplier: any;
  selectedStock: any;
  selectedOutGoingStocks: any;
  selectedProduct: any;
  selectedBrand: any;
  selectedSupplier: any;
  selectedCategory: any;
  stockReport: any;
}

const initialState: IStocks = {
  stocks: [],
  stockProducts: [],
  suppliers: [],
  brands: [],
  formattedBrand: [],
  productCategories: [],
  formattedProductCategories: [],
  formattedStockProducts: [],
  outGoingStocks: [],
  formattedSupplier: [],
  selectedStock: {},
  selectedOutGoingStocks: {},
  selectedProduct: {},
  selectedBrand: {},
  selectedSupplier: {},
  selectedCategory: {},
  stockReport: []
}

export const stockReducer = createSlice({
  name: 'stocks',
  initialState,
  reducers: {
    setStocks: (state, { payload }) => {
      state.stocks = payload;
    },
    setStockProducts: (state, { payload }) => {
      state.stockProducts = payload;
    },
    setSuppliers: (state, { payload }) => {
      state.suppliers = payload;
    },
    setBrands: (state, { payload }) => {
      state.brands = payload;
    },
    setFormattedBrand: (state, { payload }) => {
      state.formattedBrand = payload;
    },
    setProductCategories: (state, { payload }) => {
      state.productCategories = payload;
    },
    setFormattedProductCategories: (state, { payload }) => {
      state.formattedProductCategories = payload;
    },
    setFormattedStockProducts: (state, { payload }) => {
      state.formattedStockProducts = payload;
    },
    setOutGoingStocks: (state, { payload }) => {
      state.outGoingStocks = payload;
    },
    setFormattedSuppliers: (state, { payload }) => {
      state.formattedSupplier = payload;
    },
    setSelectedStock: (state, { payload }) => {
      state.selectedStock = payload;
    },
    setSelectedOutGoingStocks: (state, { payload }) => {
      state.selectedOutGoingStocks = payload;
    },
    setSelectedProduct: (state, { payload }) => {
      state.selectedProduct = payload;
    },
    setSelectedBrand: (state, { payload }) => {
      state.selectedBrand = payload;
    },
    setSelectedSupplier: (state, { payload }) => {
      state.selectedSupplier = payload;
    },
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload;
    },
    setStockReport: (state, { payload }) => {
      state.stockReport = payload;
    }

  }
});

const { reducer, actions } = stockReducer;

export const { setStocks, setStockProducts, setSuppliers, setBrands, setFormattedBrand, setProductCategories, setFormattedProductCategories, setFormattedStockProducts, setOutGoingStocks, setFormattedSuppliers, setSelectedStock, setSelectedOutGoingStocks, setSelectedProduct, setSelectedBrand, setSelectedSupplier, setSelectedCategory, setStockReport } = actions;

export default reducer;