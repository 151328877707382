/* eslint-disable indent */
import { accessTokenKey } from '../api/helper';

export const isAuthenticated = (): boolean => {
    const accessToken = sessionStorage.getItem(accessTokenKey);

    if (accessToken) {
        return true;
    }

    return false;
};
