import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './redux/app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallBackUI from './components/global/ErrorFallBackUI';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallBackUI} onReset={() => {
        window.location.reload(); // simple work around
      }}>
        <App />

      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
