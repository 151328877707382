import React, { useEffect } from 'react'
import useClinics from '../../hooks/useClinics'
import useClinicStats from '../../hooks/useClinicStats'
import { useLastUrlSegment } from '../../hooks/useQuery'
import Card from '../../parts/Card'
import CardContentWrapper from '../global/CardContentWrapper'
import CardHeaderWithBottomBorder from '../global/CardHeaderWithBottomBorder'
import DashboardCard from '../global/DashboardCard'
import { EllipsisLoader } from '../global/MyLoaders'

const ClinicDetail = () => {
  const id = useLastUrlSegment();
  const { selectedClinic, loading, getSelectedClinic } = useClinics();
  const { clinicStats } = useClinicStats(id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedClinic(Number(id)), [id]);

  return loading ? <EllipsisLoader /> : (
    <div>
      <div className='row'>
        <div className="col-xl-12 mb-10 row">
          {/* here goes dashboard card */}
          <div className="col-sm-4">
            <DashboardCard title='Doctors' stats={clinicStats?.staff} />
          </div>
          <div className="col-sm-4">
            <DashboardCard title='Patients' stats={clinicStats?.patients} />
          </div>
          <div className="col-sm-4">
            <DashboardCard title='Appointments' stats={clinicStats?.appointments} />
          </div>
        </div>
      </div>

      <Card>
        <>
          <CardHeaderWithBottomBorder title={`Details for ${selectedClinic.name}`} />

          <CardContentWrapper>
            <>
              <p className='py-1'><strong>Email:</strong> <span>{selectedClinic.email}</span></p>
              <p className='py-1'><strong>Name:</strong> <span>{selectedClinic.name}</span></p>
              <p className='py-1'><strong>Telephone:</strong> <span>{selectedClinic.telephone}</span></p>
              <p className='py-1'><strong>Licence No.:</strong> <span>{selectedClinic.licence_no}</span></p>
              <p className='py-1'><strong>Location:</strong> <span>{selectedClinic.location}</span></p>
            </>
          </CardContentWrapper>
        </>
      </Card>
    </div>
  )
}

export default ClinicDetail