import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useProjects from '../../../hooks/useProjects';
import { useLastUrlSegment } from '../../../hooks/useQuery';
import Card from '../../../parts/Card'
import CardContentWrapper from '../../global/CardContentWrapper';
import CardHeaderWithBottomBorder from '../../global/CardHeaderWithBottomBorder';
import ModalSwitcher from '../../global/ModalSwitcher';
import CreateActivity from '../activities/CreateActivity';
import AddProjectReport from '../report/AddProjectReport';
import ViewProjectReport from '../report/ViewProjectReport';

const Right = () => {
  const { fetchSingleProject, selectedProject } = useProjects();
  const id = useLastUrlSegment();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedActivityId, setSelectedActivityId] = useState(0);

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }
  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'add-project-report';
      modalTitle = 'Add Project Report For An Activity';
      ModalToRender = AddProjectReport
      break;
    case 3:
      modalId = 'view-activity-report';
      modalTitle = 'View Project Report(s) For An Activity';
      ModalToRender = ViewProjectReport
      break;
    default:
      modalId = 'add-new-activity';
      modalTitle = 'Add New Activity';
      ModalToRender = CreateActivity
      break;
  }
  return (
    <div className='col-sm-4'>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender onCancel={handleModalClick} projectId={id} inRefresh={() => fetchSingleProject(id)} activity_id={selectedActivityId} />} />

      {/* action */}

      {/* activities */}
      <Card>
        <>
          {selectedProject.project_budget ? (<CardContentWrapper>
            <div className='row'>
              <div className="col-sm-12">
                <CardHeaderWithBottomBorder title='Activities' isButton>
                  <button className='btn btn-sm btn-outline-primary' onClick={(e) => {
                    e.stopPropagation();
                    setOpenModal(1);
                    setIsOpen(true);
                  }}> <i className='fa fa-plus-circle'></i> Add new</button>

                </CardHeaderWithBottomBorder>
                {selectedProject.activities?.map((activity: any) => (
                  <div className='row py-1 justify-content-between m-0' key={activity?.id}>
                    <div className="col-7">
                      <Link className=' border-bottom-dashed-link' to={`/projects/activities/${activity?.id}`} >{activity?.activity_name}</Link>

                    </div>
                    <div className="col-2">
                      <button style={{ height: '23px', width: '23px' }} className={`p-2 rounded-circle ${activity?.priority === 1 ? 'bg-default' : activity?.priority === 2 ? 'bg-secondary' : 'bg-danger'} text-white row align-items-center justify-content-center`}><i className='fa fa-arrow-up'></i></button>

                    </div>
                    <div className='px-2 col-3'>
                      <span className={`badge ${activity?.status === 'On-going' ? 'badge-info' : activity?.status === 'Completed' ? 'badge-success' : 'badge-secondary'}`}><i className={`${activity?.status === 'Completed' ? 'fa fa-check-circle' : null}`}></i>  {activity?.status}</span>

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CardContentWrapper>) : null}
          <CardContentWrapper>
            <div className='row'>
              <div className="col-sm-12">
                <h6>Category</h6>
                <p>{selectedProject?.project_category?.name}</p>
              </div>
            </div>
          </CardContentWrapper>
        </>
      </Card>


      {selectedProject.activities?.length > 0 && (
        <Card>
          <CardContentWrapper>
            <>
              <CardHeaderWithBottomBorder title='Reports' />
              {selectedProject.activities?.map((activity: any) => (
                <div className='row py-1 justify-content-between m-0' key={activity?.id}>
                  <div className="col-7">
                    <Link className=' border-bottom-dashed-link' to={`/projects/activities/${activity?.id}`} >{activity?.activity_name}</Link>
                  </div>
                  <div className='px-2 col-2 mr-1'>
                    <span onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(3);
                      setSelectedActivityId(activity?.id);
                      setIsOpen(true);
                    }} className='badge badge-secondary pointer'> <i className='fa fa-eye'>{activity?.reports_count}</i> View </span>
                  </div>
                  <div className='px-2 col-2'>
                    <span onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(2);
                      setSelectedActivityId(activity?.id);
                      setIsOpen(true);
                    }} className='badge badge-info pointer'> <i className='fa fa-plus'></i> Add </span>
                  </div>
                </div>
              ))}
            </>
          </CardContentWrapper>
        </Card>
      )}
    </div>
  )
}

export default Right