import { useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import useCategories from '../../../hooks/useCategories';
import { padString } from '../../../utils/helpers';
import ConfirmDelete from '../../global/ConfirmDelete';
import ModalSwitcher from '../../global/ModalSwitcher';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';
import CreateCategory from './CreateCategory';
import EditCategory from './EditCategory';

const CategoriesTable = () => {
  const { categories, loading, refresh } = useCategories();
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(1);
  const [selectedFactId, setSelectedFactId] = useState(0);

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedFactId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedFactId(id);
    setIsOpen(true);
  }


  const categoriesColumnData: TableColumn<any>[] = [
    {
      id: 1,
      name: "Id",
      selector: (row) => row.id,
      reorder: true,
      sortable: true
    }, {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      format: (row) => padString(row.name),
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 3,
      name: "Description",
      selector: (row) => row.description,
      format: (row) => padString(row.description),
      grow: 3,
      wrap: true,
    },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <Link to={`/settings/projects/categories/view/${row.id}`} className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></Link>
          <button className='btn btn-sm btn-primary' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}><i className='fa fa-edit'></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];

  let ModalToOpen;
  let modalTitle;
  let modalId;
  switch (Number(openModal)) {
    case 2:
      ModalToOpen = EditCategory;
      modalTitle = "Edit category";
      modalId = "edit-category";
      break;
    case 3:
      ModalToOpen = ConfirmDelete;
      modalTitle = "";
      modalId = "delete-category";
      break;
    default:
      ModalToOpen = CreateCategory;
      modalTitle = "Add new category";
      modalId = "add-new-category";

  }

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  return (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToOpen closeModal={handleModalClick} id={selectedFactId} path={`categories/${selectedFactId}/delete`} refresh={refresh} />} />
      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3' onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                  }}>Add Project category</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            <div className="table-responsive mt-15">

              {loading ? (<EllipsisLoader />) : (
                <MyDataTable data={categories} columns={categoriesColumnData} title="" />

              )}
            </div>
            {/* end table */}
          </div>
        </div>
      </div>
    </>

  )
}

export default CategoriesTable