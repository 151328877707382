import React from 'react'
import { ITabsData, MyTabs } from '../../global/MyTab';
import Appreciation from './appreciation/Appreciation';
import Details from './Details';
import Expenses from './expenses/Expenses';
import Income from './income/Income';
import Payments from './payments/Payments';

const ViewAsset = () => {
  const ApplicationsTabsData: ITabsData[] = [
    {
      tabId: 1,
      title: 'Asset details',
      component: <Details />,
    },
    {
      tabId: 2,
      title: 'Appreciation',
      component: <Appreciation />,
    }, {
      tabId: 3,
      title: 'Payments',
      component: <Payments />,
    }, {
      tabId: 4,
      title: 'Income',
      component: <Income />,
    }, {
      tabId: 5,
      title: 'Expenses',
      component: <Expenses />
    },

  ];

  return (
    <div>
      <MyTabs
        TabsData={ApplicationsTabsData}
      />

    </div>
  )
}

export default ViewAsset