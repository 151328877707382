import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllCustomers, getCustomer } from '../api/customers';
import { RootState } from '../redux/app/store';
import { setCustomers, setSelectedCustomer } from '../redux/reducer/customer';
import { formatDepartmentSelectData } from '../utils/helpers';

const useCustomers = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    customers, selectedCustomer
  } = useSelector(
    (state: RootState) => state.customer,
  );

  useEffect(() => {
    if (Object.keys(customers).length > 0) {
      setLoading(false);
      return;
    }

    getAllCustomers().then((response) => {
      if (response.status === 200) {
        dispatch(setCustomers(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    });

    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    getAllCustomers().then((response) => {
      if (response.status === 200) {
        dispatch(setCustomers(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getSelectedCustomer = (id: number) => {
    setLoading(true);
    getCustomer(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedCustomer(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  
  

  return { customers, loading, refresh, getSelectedCustomer, selectedCustomer }
}

export default useCustomers