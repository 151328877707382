import React from "react";
import useAssetManagement from "../../../hooks/useAssetManagement";
import CardListItem from "../../global/CardListItem";
import { formatCurrency } from "../../../utils/helpers";

const Details = () => {
  const { selectedAsset } = useAssetManagement();
  return (
    <div>
      <div>
        <h5>Asset details</h5>
        <hr />
      </div>
      <div className="row mx-0">
        <div className="col-sm-6">
          <CardListItem title="Asset name" value={selectedAsset?.asset_name} />
          <CardListItem
            title="Asset account"
            value={selectedAsset?.account?.account_name}
          />
          <CardListItem
            title="Identification No (S/N)"
            value={selectedAsset?.identity_no}
          />
          <CardListItem
            title="Purchase cost"
            value={formatCurrency(selectedAsset?.purchase_cost)}
          />
          <CardListItem
            title="Expected Age"
            value={selectedAsset?.expected_age}
          />
          <CardListItem
            title="Salvage value"
            value={formatCurrency(selectedAsset?.salvage_value)}
          />
          <CardListItem
            title="Appreciation Value"
            value={selectedAsset?.expected_age}
          />
          <CardListItem
            title="Appreciation Rate"
            value={selectedAsset?.appreciation_rate}
          />
          <CardListItem
            title="Appreciation Income Account"
            value={selectedAsset?.appreciation_gain_account_id}
          />
        </div>
        <div className="col-sm-6">
          <CardListItem
            title="Description"
            value={selectedAsset?.description}
          />
          <CardListItem
            title="Purchase date"
            value={selectedAsset?.purchase_date}
          />
          <CardListItem
            title="Date When Put To Use"
            value={selectedAsset?.date_when}
          />
          <CardListItem
            title="Age elapsed since purchase"
            value={selectedAsset?.expected_age}
          />
          <CardListItem title="Type" value={selectedAsset?.depre_appre_id} />
          <CardListItem
            title=" Appreciation Account"
            value={selectedAsset?.appreciation_account_id}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
