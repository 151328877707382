import React from 'react'
import ClinicWrapper from '../../components/ClinicWrapper'
import AppointmentTable from '../../components/doctor/appointments/AppointmentTable'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ClinicAppointments = () => {
  return (
    <ClinicWrapper>
      <>
        <Breadcrumbs title='Clinic appointments' parents={[{ link: ROUTES.CLINICS, title: 'Clinics' }]} />

        <AppointmentTable />

      </>
    </ClinicWrapper>
  )
}

export default ClinicAppointments