import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactToPrint from "react-to-print";

interface GoodsReceived {
  id: any;
}

const ViewGoodsReceived = ({ id }: GoodsReceived) => {
  const handlePrint = () => {
    window.print();
  };
  const componentRef = React.useRef(null);
  const order = {
    id: "PO123456",
    date: "2024-02-16",
    items: [
      { name: "Item 1", quantity: 2, price: 10 },
      { name: "Item 2", quantity: 1, price: 20 },
      { name: "Item 3", quantity: 3, price: 15 },
    ],
    total: 2 * 10 + 1 * 20 + 3 * 15, // Calculated total
    company: "Your Company",
    companyAddress: "123 Main St, City, Country",
    recipient: {
      name: "Recipient Name",
      address: "456 Elm St, City, Country",
    },
  };
  return (
    <div className="col-xl-12 mb-30">
      <div className="card card-statistics h-100">
        <div className="card-body">
          <div className="d-block d-md-flex justify-content-end mb-10">
            <div className="d-block d-md-flex clearfix sm-mt-20">
              <div className="widget-search ml-0 clearfix">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary ml-auto mr-3">
                      {" "}
                      Print GRN
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              </div>
            </div>
          </div>
          <div className="container" ref={componentRef}>
            <div className="row">
              <div className="col">
                <h2>{order.company}</h2>
                <p>{order.company}</p>
                <p>{order.companyAddress}</p>
              </div>
              <div className="col text-right">
                <h5 className="text-primary">GOODS RECEIVED NOTE</h5>
              </div>
            </div>
            <hr />
            <br />
            <div className="row">
              <div className="col">
                <h6>DELIVERY INFORMATION:</h6>
                <p>
                  <strong>Name:</strong> {order.recipient.name}
                </p>
                <p>
                  <strong>Address:</strong> {order.recipient.address}
                </p>
              </div>
              <div className="col">
                <h6>SUPPLIER INFORMATION:</h6>
                <p>
                  <strong>Name:</strong> {order.recipient.name}
                </p>
                <p>
                  <strong>Address:</strong> {order.recipient.address}
                </p>
              </div>
            </div>

            <br />
            <div className="row">
              <div className="col">
                <h6>RECEIVED BY:</h6>
                <p>
                  <strong>Name:</strong> {order.recipient.name}
                </p>
                <p>
                  <strong>Address:</strong> {order.recipient.address}
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <table className="table table-striped">
                  <thead>
                    <tr className="bg-primary">
                      <th colSpan={5} className="text-center text-white">
                        RECEIVED ITEMS
                      </th>
                    </tr>
                    <tr className="text-primary">
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.quantity}</td>
                        <td>{item.price.toFixed(2)}</td>
                        <td>{(item.quantity * item.price).toFixed(2)}</td>
                      </tr>
                    ))}

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>Total:</strong>
                      </td>
                      <td>{order.total.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <p>Comment:</p>
              </div>
            </div>
            <div className="row mt-4 justify-content-between">
              <div className="col text-center">
                <p>---------------------------</p>
                <p>Signature</p>
              </div>
              <div className="col text-center">
                <p>---------------------------</p>
                <p>Date</p>
              </div>
              <div className="col text-center">
                <p>{order.date}</p>
                <p>
                  <strong>Print Date</strong>
                </p>
              </div>
            </div>
            <br />
            <br />
            <hr />
            <div className="row">
              <div className="col-lg-12 text-center">
                <h3>COMPANY NAME</h3>
                <h5>COMPANY NAME</h5>
                <h5>COMPANY NAME</h5>
                <p>COMPANY NAME</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewGoodsReceived;
