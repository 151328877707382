import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { clinicGet } from '../api/clinics';
import { stockGet } from '../api/stocks';
import { RootState } from '../redux/app/store';
import { setClinicDoctor, setClinics, setformattedClinics, setSelectedClinic } from '../redux/reducer/clinic';
import { formatDepartmentSelectData } from '../utils/helpers';

const useClinics = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    clinics, selectedClinic, doctors
  } = useSelector(
    (state: RootState) => state.clinics,
  );

  useEffect(() => {

    if (clinics.length > 0) {
      setLoading(false);
      return;
    }

    clinicGet('clinics').then((response) => {
      if (response.status === 200) {
        dispatch(setClinics(response.data.data));
        dispatch(setformattedClinics(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedClinic = (id: number) => {
    setLoading(true);
    stockGet('clinics/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedClinic(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const refresh = () => {
    setLoading(true);
    stockGet('clinics').then((response) => {
      if (response.status === 200) {
        dispatch(setClinics(response.data.data));
        dispatch(setformattedClinics(formatDepartmentSelectData(response.data.data)));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getDoctors = (id: number) => {
    setLoading(true);
    stockGet('doctors').then((response) => {
      if (response.status === 200) {
        dispatch(setClinicDoctor(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { clinics, loading, refresh, getSelectedClinic, selectedClinic, getDoctors, doctors }
}

export default useClinics