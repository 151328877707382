import React, { ReactElement } from 'react'
import MyModal from './MyModal'
interface IModalSwitcherProps {
  component: ReactElement
  isOpen: boolean
  title: string
  minWidth?: number
  setIsOpen: (id: boolean) => void;
  id: string

}

const ModalSwitcher = ({ isOpen, id, title, setIsOpen, component
}: IModalSwitcherProps) => {
  return isOpen ? (
    <MyModal modalOpen={isOpen} setModalOpen={setIsOpen} id={id} title={title}>
      {component}
    </MyModal>
  ) : <p></p>;
}

export default ModalSwitcher