import React from 'react';
import Switch from "react-switch";

interface IMySwitchProps {
  checked: boolean;
  label: string;
  handleChange: () => void;
}

const MySwitch = ({ handleChange, checked, label }: IMySwitchProps) => {

  return (

    <div className="form-group mt-3">
      <label className='row align-items-center pl-3'>
        <span className='mr-3'>{label}</span>
        <Switch onChange={handleChange} checked={checked} />
      </label>
    </div>
  )
}

export default MySwitch