import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import useActivities from '../../../../hooks/useActivities';
import ConfirmDelete from '../../../global/ConfirmDelete';
import ModalSwitcher from '../../../global/ModalSwitcher';
import MyDataTable from '../../../global/MyDataTable';
import { EllipsisLoader } from '../../../global/MyLoaders';
import AddActivityRole from './AddActivityRole';
import EditActivityRole from './EditActivityRole';
import Permissions from './Permissions';

const ListActivityTeamRoles = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { loading, getActivityTeamRoles, activityTeamRoles, refreshActivityTeamRoles } = useActivities();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getActivityTeamRoles(), []);

  const departTableColumns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Name",
      cell: (row) => <button className='btn' onClick={(e) => {
        e.stopPropagation();
        setOpenModal(4);
        setSelectedId(row.id)
        setIsOpen(true);
      }}
      >{row.name}</button>,
      reorder: true,
      sortable: true,
      grow: 2,
    }, {
      id: 2,
      name: "Description",
      selector: (row) => row.description,
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true,
    }, {
      id: 3,
      name: "Permission(s)",
      selector: (row) => row.permissions?.map((item: any) => <span className='badge badge-secondary mr-2' key={item.id}>{item.name}</span>),
      reorder: true,
      grow: 3,
      sortable: true,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className='row'>
          {/* <button className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></button> */}
          <button className='btn btn-sm btn-primary'><i className='fa fa-edit' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'edit-department';
      modalTitle = 'Edit Activity Role';
      ModalToRender = EditActivityRole

      break;
    case 3:
      modalId = 'delete-activity-role';
      modalTitle = '';
      ModalToRender = ConfirmDelete
      break;
    case 4:
      modalId = 'assign-permission';
      modalTitle = 'Assign permissions to role';
      ModalToRender = Permissions
      break;
    default:
      modalId = 'add-new-activity-role';
      modalTitle = 'Add New Activity Role';
      ModalToRender = AddActivityRole
      break;
  }

  return (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={selectedId} closeModal={handleModalClick} path={`activity-roles/${selectedId}/delete`} refresh={refreshActivityTeamRoles} />} />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className='pl-3 row align-items-end'>
                  <button className='btn btn-primary ml-auto mr-3'
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(0);
                      setIsOpen(true);
                    }}><i className='fa fa-plus'></i>Add new role</button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input type="search" className="form-control" placeholder="Search...." />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? <EllipsisLoader /> : <MyDataTable data={activityTeamRoles} columns={departTableColumns} title="" />}

            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListActivityTeamRoles