import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjects, getSingleProject } from '../api/projects';
import { formatProjectDataToSelectFormData } from '../helpers';
import { RootState } from '../redux/app/store';
import { setProjects, setSelectedProject } from '../redux/reducer/projects';

const useProjects = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    projects, selectedProject
  } = useSelector(
    (state: RootState) => state.projects,
  );

  useEffect(() => {

    if (Object.keys(projects).length > 0) {
      setLoading(false);
      return;
    }

    getAllProjects().then((response) => {
      if (response.status === 200) {
        dispatch(setProjects(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  const refresh = () => {
    getAllProjects().then((response) => {
      if (response.status === 200) {
        dispatch(setProjects(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const fetchSingleProject = (id: number) => {
    setLoading(true);
    getSingleProject(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedProject(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const formattedProjectData = formatProjectDataToSelectFormData(projects?.data);

  return { projects, loading, refresh, fetchSingleProject, selectedProject, formattedProjectData }
}

export default useProjects