import React from 'react'
import { TableColumn } from 'react-data-table-component';
import useDepartments from '../../../hooks/useDepartments'
import { padString } from '../../../utils/helpers';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IDepartmentTableProps {
  handleEditClick: (id: number) => void;
  handleDeleteClick: (id: number) => void;
}

const DepartmentTable = ({ handleDeleteClick, handleEditClick }: IDepartmentTableProps) => {
  const { loading, departments } = useDepartments();
  const departTableColumns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true
    }, {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 3,
      name: "Salary Range",
      selector: (row) => row.salary_range,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, {
      id: 4,
      name: "Description",
      selector: (row) => padString(row.description),
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className='row'>
          {/* <button className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></button> */}
          <button className='btn btn-sm btn-primary'><i className='fa fa-edit' onClick={(e) => {
            e.stopPropagation();
            handleEditClick(row.id);
          }}></i></button>

          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  return loading ? <EllipsisLoader /> : (
    <MyDataTable data={departments} columns={departTableColumns} title="" />
  )
}

export default DepartmentTable