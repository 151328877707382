import { Form, Formik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import { FormInput } from '../../global/Input';

interface IReviewProps {
  id: number;
  closeModal: () => void;
  refresh: () => void;
  ///leave/{id}/complete
}

const Review = ({ id, closeModal, refresh }: IReviewProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <Formik initialValues={{
      comment: '',
      staff_leave_id: id,
      state_id: 2
    }}
      validationSchema={Yup.object({
        comment: Yup.string().required('Please provide the comment is required'),
      })}
      onSubmit={async values => {
        setLoading(true);
        try {
          const response = await doCreate(`leave/${id}/forward_after_review`, values);
          if (response.data.success === true) {
            toast.success("Successfully reviewed and sent for approved", successToastOptions);

            refresh();
            setTimeout(() => closeModal(), 1000);
          } else {
            toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
          }
        } catch (err) {
          toast.error(ERRORMESSAGE, errorToastOptions);
        } finally {
          setLoading(false)
        }
      }}
    >
      <Form >

        <FormInput
          label="Comment"
          name="comment" />
        <div className="form-row m-0 pb-4 pt-3 justify-content-end">

          <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
          <button type='submit' disabled={loading} className='btn  btn-info' >
            {loading ? "Processing..." : "Review"}
          </button>
        </div>

      </Form>
    </Formik>
  )
}

export default Review