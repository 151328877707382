import React, { useState } from 'react'
import useEmployees from '../../../../hooks/useEmployees'
import MyModal from '../../../global/MyModal'
import EmploymentDetailsModal from './modals/EmploymentDetailsModal'

const EmploymentDetails = () => {
  const [show, setShow] = useState(false);
  const { addEmployeeEmploymentDetails, prev, next } = useEmployees();

  const handleModalOpen = () => {
    setShow((prev) => !prev);
  }

  return (
    <>
      <MyModal modalOpen={show} setModalOpen={setShow} title='Add Employment Details' id='add-employment-details' >
        <EmploymentDetailsModal onCancel={handleModalOpen} />
      </MyModal>

      <fieldset>
        <div className='row align-items-end justify-content-end mx-0'>
          <button type='button' onClick={handleModalOpen} className='btn btn-primary'> <i className='fa fa-plus'></i> Add</button>
        </div>
        <div className='h-100'>
          <div className='row mx-0 my-3 '>
            {addEmployeeEmploymentDetails.length > 0 ? addEmployeeEmploymentDetails.map(({ employer, start_date, end_date }, id) => (
              <div key={employer} className="border rounded row mx-0 col-6 px-4 py-3 align-items-center">
                <div className="col-2">{id + 1}</div>
                <div className="col-6">
                  <p>{employer}</p>
                  <small>{start_date} - {end_date}</small>
                </div>

              </div>
            )) : (
              <div className="py-5 col">
                <p className='text-muted text-center'>There is no recent employement details</p>
              </div>
            )}
          </div>
        </div>

        <div className="form-row m-0 pb-4 pt-3 justify-content-end">
          <button type='button' className='btn btn-outline-danger mr-3' onClick={prev}>Prev</button>
          <button type='button' className='btn  btn-info' onClick={next}>Next</button>
        </div>

      </fieldset>
    </>
  )
}

export default EmploymentDetails