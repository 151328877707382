import { IFunFactProps, IHealthTipProps, IStoryProps } from "../../constants/interfaces/interfaces";
import { AuthenticatedAPIInstance } from "../../utils/axios"

// get all stories
export const getAllStories = async () => {
  const allStories = await AuthenticatedAPIInstance.get("stories").then((response) => response)
    .catch((error) => error);
  return allStories;
}
export const getStory = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get(`stories/${id}`).then((response) => response)
    .catch((error) => error);
  return response;
}

// facts
export const getAllFunFacts = async () => {
  const allFunFacts = await AuthenticatedAPIInstance.get("facts").then((response) => response)
    .catch((error) => error);
  return allFunFacts;
}
export const getFunFact = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get(`facts/${id}`).then((response) => response)
    .catch((error) => error);
  return response;
}
// tips
export const getAllHealthTips = async () => {
  const allFunFacts = await AuthenticatedAPIInstance.get("tips").then((response) => response)
    .catch((error) => error);
  return allFunFacts;
}
export const getHealthTip = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get(`tips/${id}`).then((response) => response)
    .catch((error) => error);
  return response;
}

export const createStory = async (data: IStoryProps) => {
  const allStories = await AuthenticatedAPIInstance.post("stories/create", data).then((response) => response)
    .catch((error) => error);
  return allStories;
}


export const updateStory = async (data: IStoryProps, id: number) => {
  const allStories = await AuthenticatedAPIInstance.post(`stories/${id}/update`, data).then((response) => response)
    .catch((error) => error);
  return allStories;
}

export const createFunFact = async (data: IFunFactProps) => {
  const funFacts = await AuthenticatedAPIInstance.post("facts/create", data).then((response) => response)
    .catch((error) => error);
  return funFacts;
}

export const updateFunFact = async (data: IFunFactProps, id: number) => {
  const funFacts = await AuthenticatedAPIInstance.post(`facts/${id}/update`, data).then((response) => response)
    .catch((error) => error);
  return funFacts;
}

export const createHealthTip = async (data: IHealthTipProps) => {
  const healthTip = await AuthenticatedAPIInstance.post("tips/create", data).then((response) => response)
    .catch((error) => error);
  return healthTip;
}

export const updateHealthTip = async (data: IHealthTipProps, id: number) => {
  const healthTip = await AuthenticatedAPIInstance.post(`tips/${id}/update`, data).then((response) => response)
    .catch((error) => error);
  return healthTip;
}