import React from 'react'
import ListParameters from '../../components/settings/activities/parameters/ListParameters'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const ActivityParameter = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Activity parameters' />
        <ListParameters />

      </>
    </SettingsWrapper>
  )
}

export default ActivityParameter