import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../../api';
import { assetAppreciationDefaultData, errorToastOptions, successToastOptions } from '../../../../constants';
import { formatErrorMessage } from '../../../../helpers';
import { getFormData } from '../../../../utils/helpers';
import Validator from '../../../../utils/Validator';
import Input from '../../../global/Input'
import InputDate from '../../../global/InputDate'
import Textarea from '../../../global/Textarea'

interface IAddAppreciationProps {
  closeModal: () => void;
  assetId: number;
}

const AddAppreciation = ({ closeModal, assetId }: IAddAppreciationProps) => {
  const [formLoading, setFormLoading] = useState(false);

  const [appreciation, setAppreciation] = useState(assetAppreciationDefaultData);

  const validator = new Validator();

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAppreciation({ ...appreciation, ...{ [name]: { ...appreciation[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAppreciation({ ...appreciation, ...{ [name]: { ...appreciation[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAppreciation({ ...appreciation, ...{ [name]: { ...appreciation[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAppreciation({ ...appreciation, ...{ [name]: { ...appreciation[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(appreciation, setAppreciation)) {
      setFormLoading(true);

      const data = getFormData(appreciation);

      doCreate(`assets/${assetId}/appreciation/create`, data).then((response) => {
        if (response.data.success === true) {
          toast.success('New asset appreciation has been recorded', successToastOptions);
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setFormLoading(false);
      })
    } else {
      console.log(appreciation);
    }
  }

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputDate handleChange={handleSelectChange} label="Date" required error={appreciation.transaction_date.error} name="transaction_date" errorMessage="Please select the date of transaction" value={appreciation.transaction_date.data} />
        </div>
        <div className="col-sm-6">
          <Input handleChange={handleChange} label="Appreciation Amount (Credit)" name='amount' type='number' error={appreciation.amount.error} errorMessage="Please enter the appreciation amount" value={appreciation.amount.data} required />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} label="Narrative" name='narrative' error={appreciation.narrative.error} errorMessage="Please enter the narrative" value={appreciation.narrative.data} required />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddAppreciation