import React from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import FullProjectDetails from '../../../components/reports/view/FullProjectDetails'
import Breadcrumbs from '../../../parts/Breadcrumbs'
import { ROUTES } from '../../../routes/routes'

const FullProjectReport = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Workout' parents={[{ link: ROUTES.REPORTS, title: 'Reports' }, { link: ROUTES.REPORTS, title: 'Project report' }]} />

        <FullProjectDetails />

      </>
    </DashboardWrapper>
  )
}

export default FullProjectReport;