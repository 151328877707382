import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, successToastOptions, unitDefaultData } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useUnits from '../../../hooks/useUnits';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import { EllipsisLoader } from '../../global/MyLoaders';
import Textarea from '../../global/Textarea';

interface IEditUnitProps {
  closeModal: () => void;
  refresh: () => void;
  id: number;
}

const EditUnit = ({ closeModal, id, refresh }: IEditUnitProps) => {
  const [units, setUnits] = useState(unitDefaultData);
  const [formLoading, setLoading] = useState(false);
  const validator = new Validator();

  const { getSelectedUnit, selectedUnit, loading } = useUnits();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedUnit(id), [id]);

  const formNames = ['description', 'name'];

  useEffect(() => {
    let unitData = unitDefaultData;
    if (!loading) {
      for (let x of formNames) {
        unitData = { ...unitData, ...{ [x]: { ...unitData[x], ...{ error: false, data: selectedUnit[x] } } } };
      }
      setUnits(unitData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setUnits({ ...units, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setUnits({ ...units, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(units, setUnits)) {
      setLoading(true);

      const data = {
        name: units.name.data,
        description: units.description.data
      }

      try {
        const response = await doCreate('units/' + id + '/update', data);
        if (response.data.success === true) {
          toast.success('Successfully updated unit details', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>

      <div className="form-row">
        <div className="col-sm-12">
          <Input name='name' label='Department Name' handleChange={handleChange} error={units?.name.error} errorMessage="Please provide a unit name" value={units?.name.data} required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea handleChange={handleChange} name='description' label='Description' error={units?.description.error} errorMessage="Please provide a unit description" value={units?.description.data} required />
        </div>

      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Updating..." : "Update"}</button>
      </div>
    </form>
  )
}

export default EditUnit