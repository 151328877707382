import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, paymentTypeDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useAccounts from '../../../hooks/useAccounts';
import { formatAcountSelectData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';
import Textarea from '../../global/Textarea';

interface IAddPaymentTypeProps {
  closeModal: () => void;
  refresh: () => void;
}


const AddPaymentType = ({ closeModal, refresh }: IAddPaymentTypeProps) => {
  const [paymentType, setPaymentType] = useState(paymentTypeDefaultData);
  const [formLoading, setLoading] = useState(false);
  const validator = new Validator();

  const { loading, cashAccounts, getCashAccount, accounts } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCashAccount(), [accounts]);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setPaymentType({ ...paymentType, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setPaymentType({ ...paymentType, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setPaymentType({ ...paymentType, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setPaymentType({ ...paymentType, ...{ [name]: { error: true, data: value } } });
    }
  }


  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(paymentType, setPaymentType)) {
      setLoading(true);

      const data = {
        name: paymentType.name.data,
        description: paymentType.description.data,
        linked_account_id: paymentType.linked_account_id.data,
      }

      try {
        const response = await doCreate('payment-types/create', data);
        if (response.data.success === true) {
          toast.success('New payment type created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Name' handleChange={handleChange} error={paymentType?.name.error} errorMessage="Please provide the payment type name" value={paymentType?.name.data} required />
        </div>
        <div className="col-sm-6">
          <InputSelect id='linked_account_id' data={formatAcountSelectData(cashAccounts)} name='linked_account_id' label='Linked Account' handleChange={handleSelectChange} error={paymentType?.name.error} errorMessage="Please provide a unit name" value={paymentType?.name.data} required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea handleChange={handleChange} name='description' label='Description' error={paymentType?.description.error} errorMessage="Please provide a payment type description" value={paymentType?.description.data} required />
        </div>

      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddPaymentType