import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ROUTES } from '../routes/routes'

const StockSideBar = () => {
  const location = useLocation();
  return (
    <div className='card card-statistics h-100 col-xs-12 col-md-3 px-2 py-3 mb-10'>
      <div className='card-body'>
        <p className='text-muted'>Stock menu</p>
        <ul className="nav navbar-nav settings_links">
          <li className={`${location.pathname === ROUTES.STOCKS && 'active'}`}>
            <NavLink to={ROUTES.STOCKS}><i className="fa fa-balance-scale"></i><span className="ml-2">Stock </span></NavLink>
          </li>
          <li className={`${location.pathname === ROUTES.STOCKOUTGOING && 'active'}`}>
            <NavLink to={ROUTES.STOCKOUTGOING}><i className="fa fa-shopping-basket"></i><span className="ml-2">Out-going stock </span></NavLink>
          </li>
          <li className={`${location.pathname === ROUTES.STOCKPRODUCTS && 'active'}`}>
            <NavLink to={ROUTES.STOCKPRODUCTS}><i className="fa fa-diamond"></i><span className="ml-2">Products </span></NavLink>
          </li>
          <li className={`${location.pathname === ROUTES.STOCKCATEGORIES && 'active'}`}>
            <NavLink to={ROUTES.STOCKCATEGORIES}><i className="fa  fa-certificate"></i><span className="ml-2">Product categories </span></NavLink>
          </li>
          <li className={`${location.pathname === ROUTES.STOCKSUPPLIERS && 'active'}`}>
            <NavLink to={ROUTES.STOCKSUPPLIERS}><i className="fa fa-volume-control-phone"></i><span className="ml-2">Suppliers </span></NavLink>
          </li>
          <li className={`${location.pathname === ROUTES.STOCKBRANDS && 'active'}`}>
            <NavLink to={ROUTES.STOCKBRANDS}><i className="fa  fa-arrow-circle-o-right"></i><span className="ml-2">Brands </span></NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default StockSideBar