import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import useSessionStorage from "../hooks/useSessionStorage";
import { ROUTES } from "../routes/routes";

const SideBar = () => {
  const [showId, setShowId] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();

  const handleClick = (id:number, item:any) => {
    if (id === showId) {
      setShowId(0); // close the dropdown
    } else {
      setShowId(id);
    }
    setSelectedItem(item);
  };

  const { userRoleId } = useSessionStorage();
  console.log(userRoleId());

  return (
    <div className="side-menu-fixed">
      <div className="scrollbar side-menu-bg">
        <ul className="nav navbar-nav side-menu" id="sidebarnav">
          {/* Reports */}
          {[1, 3, 4, 6, 8, 9].includes(userRoleId()) && (
            <li className={`${location.pathname.startsWith(ROUTES.REPORTS) && "active"}`}>
              <Link
                to={"#"}
                data-toggle="collapse"
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(5, "reports");
                }}
              >
                <div className="pull-left">
                  <i className="fa fa-line-chart"></i>
                  <span className="right-nav-text">Reports</span>
                </div>
                <div className="pull-right">
                  <i className="ti-plus"></i>
                </div>
                <div className="clearfix"></div>
              </Link>
              <ul
                className={`collapse ${showId === 5 && "show"}`}
                data-parent="#sidebarnav"
              >
                <li className={`${location.pathname.endsWith("trail-balance") && "active"}`}>
                  <Link to={ROUTES.TRIALBALANCE}>Trail Balance</Link>
                </li>
                <li>
                  <Link to={ROUTES.INCOMESTATEMENT}>Income Statement</Link>
                </li>
                <li className={`${location.pathname.endsWith("balancesheet") && "active"}`}>
                  <Link to={ROUTES.BALANCESHEET}>Balance Sheet</Link>
                </li>
                <li>
                  <Link to={ROUTES.SCHEDULES}>Schedules</Link>
                </li>
                <li>
                  <Link to={ROUTES.EQUITY}>Owner's Equity</Link>
                </li>
                <li>
                  <Link to={ROUTES.CASHFLOW}>Cash Flow</Link>
                </li>
                <li>
                  <Link to={ROUTES.BUDGETREPORT}>Budget Comparision</Link>
                </li>
                <li>
                  <Link to={ROUTES.PAYROLLREPORT}>Payroll Report</Link>
                </li>
                <li>
                  <Link to={ROUTES.REPORTS}>Other Reports</Link>
                </li>
              </ul>
            </li>
          )}

          {/* Accounting */}
          {[1, 3].includes(userRoleId()) && (
            <li className={`${location.pathname.startsWith(ROUTES.ACCOUNTING) && "active"}`}>
              <Link
                to={"#"}
                data-toggle="collapse"
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(6, "accounting");
                }}
              >
                <div className="pull-left">
                  <i className="fa fa-bank "></i>
                  <span className="right-nav-text">Accounting</span>
                </div>
                <div className="pull-right">
                  <i className="ti-plus"></i>
                </div>
                <div className="clearfix"></div>
              </Link>
              <ul
                className={`collapse ${showId === 6 && "show"}`}
                data-parent="#sidebarnav"
              >
                <li>
                  <Link to={ROUTES.SUBCATEGORIES}>Sub Categories</Link>
                </li>
                <li>
                  <Link to={ROUTES.COA}>Charts of accounts</Link>
                </li>
                <li>
                  <Link to={ROUTES.TRANSACTIONS}>Transactions</Link>
                </li>
                <li>
                  <Link to={ROUTES.BUDGET}>Budgets</Link>
                </li>
                <>
                  <li>
                    <Link to={ROUTES.BILLING}>Billing</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.INVOICES}>Invoices</Link>
                  </li>
                </>
              </ul>
            </li>
          )}

          {/* human resource */}
          {[1, 4].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.HR) && "active"
              }`}
            >
              <Link
                to="#"
                data-toggle="collapse"
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(7, "HumanResource");
                }}
              >
                <div className="pull-left">
                  <i className="fa fa-user-o"></i>
                  <span className="right-nav-text">Human resource</span>
                </div>
                <div className="pull-right">
                  <i className="ti-plus"></i>
                </div>
                <div className="clearfix"></div>
              </Link>
              <ul
                className={`collapse ${showId === 7 && "show"}`}
                data-parent="#sidebarnav"
              >
                <li>
                  <Link to={ROUTES.EMPLOYEES}>Employee</Link>
                </li>
                <li>
                  <Link to={ROUTES.LEAVEMANAGEMENT}>Leave Management</Link>
                </li>
                <>
                  {/* disabled this for now */}
                  {false && (
                    <li>
                      <Link to={ROUTES.STAFFPERFORMANCE}>Performance</Link>
                    </li>
                  )}
                </>
                <li>
                  <Link to={ROUTES.PAYROLLMANAGEMENT}>PayRoll Management</Link>
                </li>
              </ul>
            </li>
          )}
          {/* Unions */}
          {[1, 3].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.UNIONS) && "active"
              }`}
            >
              <NavLink to={ROUTES.UNIONS}>
                <i className="fa fa-users"></i>
                <span className="right-nav-text">Customers </span>
              </NavLink>
            </li>
          )}

          {[1, 3].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.RECEIVING) && "active"
              }`}
            >
              <NavLink to={ROUTES.RECEIVING}>
                <i className="fa fa-users"></i>
                <span className="right-nav-text">Receiving Report </span>
              </NavLink>
            </li>
          )}

          {/* Asset management */}
          {[1, 3].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.ASSETS) && "active"
              }`}
            >
              <NavLink to={ROUTES.ASSETS}>
                <i className="fa fa-tasks"></i>
                <span className="right-nav-text">Asset Management </span>
              </NavLink>
            </li>
          )}

          {/* stocks */}
          {[1, 3].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.STOCKS) && "active"
              }`}
            >
              <NavLink to={ROUTES.STOCKS}>
                <i className="fa fa-exchange"></i>
                <span className="right-nav-text">Stocks </span>
              </NavLink>
            </li>
          )}

          {/* procurement management */}
          {[1, 3].includes(userRoleId()) && (
            <li
              className={`${
                location.pathname.startsWith(ROUTES.PROCUREMENT) && "active"
              }`}
            >
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleClick(9, "Procurement");
                }}
                to="#"
                data-toggle="collapse"
              >
                <div className="pull-left">
                  <i className="fa fa-product-hunt"></i>
                  <span className="right-nav-text">Procurement</span>
                </div>
                <div className="pull-right">
                  <i className="ti-plus"></i>
                </div>
                <div className="clearfix"></div>
              </Link>
              {[1, 3].includes(userRoleId()) && (
                <ul
                  className={`collapse ${showId === 9 && "show"}`}
                  data-parent="#sidebarnav"
                >
                  <li>
                    <Link to={ROUTES.QUOTATION}>Quotation</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.BIDANALYSIS}>Bid Analysis</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.REQUISITIONS}>Purchase Requisition</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.PURCHASEORDER}>Purchase Order</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.GOODSRECIEVED}>Goods Recieved</Link>
                  </li>
                </ul>
              )}
            </li>
          )}

          {/* Cash Request */}
          <li className={`${location.pathname === ROUTES.CASHREQUISITIONS && "active"}`}>
            <NavLink to={ROUTES.CASHREQUISITIONS}>
              <i className="fa fa-money"></i>
              <span className="right-nav-text">Cash Request </span>
            </NavLink>
          </li>

          {/* Settings */}
          <li className={`${location.pathname.startsWith(ROUTES.SETTINGS) && "active"}`}>
            <NavLink to={ROUTES.MYPROFILE}>
              <i className="fa fa-cog"></i>
              <span className="right-nav-text">Settings </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
