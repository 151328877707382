import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ListQuotations from '../../components/procurement/quotation/ListQuotations'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Quotation = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Quotation' />
        {/* body */}
        <ListQuotations />
      </>
    </DashboardWrapper>
  )
}

export default Quotation