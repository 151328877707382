import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { doCreate } from '../../api';
import { ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../constants';
import { formatErrorMessage } from '../../helpers';
import useAnnouncements from '../../hooks/useAnnouncements';
import { FormInput, FormTextarea } from '../global/Input';
import { EllipsisLoader } from '../global/MyLoaders';

interface IAddAnnouncementProps {
  closeModal: () => void;
  id: number;
  refresh: () => void;
}

const EditAnnouncement = ({ closeModal, id, refresh }: IAddAnnouncementProps) => {
  const [formLoading, setLoading] = useState(false);
  const { loading, getSelectedAnnouncement, selectedAnnouncement } = useAnnouncements();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedAnnouncement(id), [id]);
  return loading ? <EllipsisLoader /> : (
    <Formik
      initialValues={{
        title: selectedAnnouncement?.title,
        information: selectedAnnouncement?.information,
        start_date: selectedAnnouncement?.start_date,
        end_date: selectedAnnouncement?.end_date,
      }}
      validationSchema={Yup.object({
        title: Yup.string()
          .required('The title field is required'),
        information: Yup.string()
          .min(50, 'Must be 20 characters or more')
          .required('The content field is required'),
        start_date: Yup.date()
          .required('The start date field is required'),
        end_date: Yup.date().when("start_date",
          (started, yup) => started && yup.min(started, "End date must be greater than start date"))
      })}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          const response = await doCreate(`notice/${id}/update`, values);
          if (response.data.success === true) {
            toast.success('New Announcement created successfully', successToastOptions);
            refresh(); // refresh the hook store
            setTimeout(() => {
              closeModal();
            }, 1000);
          } else {
            toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
          }
        } catch (err) {
          toast.error(ERRORMESSAGE, errorToastOptions);
        } finally {
          setLoading(false)
        }
      }}
    >
      <Form>
        <FormInput
          label="Title"
          name="title"
          type="text"
        />

        <div className="form-row">
          <div className="col-sm-6">
            <FormInput
              label="Start Date"
              name="start_date"
              type="date"
            />
          </div>
          <div className="col-sm-6">
            <FormInput
              label="End Date"
              name="end_date"
              type="date"
            />
          </div>
        </div>

        <FormTextarea
          label="Content"
          name="information"
          cols={10}
          rows={10}
        />

        <div className="form-row m-0 pb-4 pt-3 justify-content-end">
          <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
          <button type='submit' disabled={formLoading} className='btn  btn-info' >{formLoading ? "Creating..." : "Create"}</button>
        </div>
      </Form>
    </Formik>
  )
}

export default EditAnnouncement