import React, { useEffect } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactToPrint from "react-to-print";
import useRequisition from "../../../hooks/useRequisition";
import { EllipsisLoader } from "../../global/MyLoaders";

interface Requisition {
  id: any;
}

const ViewRequisition = ({ id }: Requisition) => {
  const handlePrint = () => {
    window.print();
  };
  const componentRef = React.useRef(null);
  const order = {
    id: "PO123456",
    date: "2024-02-16",
    items: [
      { name: "Item 1", quantity: 2, price: 10 },
      { name: "Item 2", quantity: 1, price: 20 },
      { name: "Item 3", quantity: 3, price: 15 },
    ],
    total: 2 * 10 + 1 * 20 + 3 * 15, // Calculated total
    company: "Your Company",
    companyAddress: "123 Main St, City, Country",
    recipient: {
      name: "Recipient Name",
      address: "456 Elm St, City, Country",
    },
  };

  const { getRequisition, requisition, loading } =
    useRequisition();
  useEffect(() => getRequisition(id), []);
  return loading ? <EllipsisLoader /> : (
    <div className="col-xl-12 mb-30">
      <div className="card card-statistics h-100">
        <div className="card-body">
          <div className="d-block d-md-flex justify-content-end mb-10">
            
            <div className="d-block d-md-flex clearfix sm-mt-20">
              <div className="widget-search ml-0 clearfix">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary ml-auto mr-3"><i className="fa fa-print"></i>{" "}Print Order</button>
                  )}
                  content={() => componentRef.current}
                />
              </div>
            </div>
          </div>
          <div className="container" ref={componentRef}>
            <div className="row">
              <div className="col">
                <h2>{order.company}</h2>
                <p>{order.company}</p>
                <p>{order.companyAddress}</p>
              </div>
              <div className="col text-right">
                <h2>REQUISITION</h2>
                <p>
                  <strong>Ref No:</strong> {requisition?.reference_no}
                </p>
                <p>
                  <strong>Date:</strong> {requisition?.requisition_date}
                </p>
                <p>Status: {requisition.status}</p>
                <p>Type: {requisition?.type}</p>
              </div>
            </div>
            <hr />
            <br />
            <div className="row">
              <div className="col">
                <h4>Requested By:</h4>
                <p>
                  <strong>Name:</strong> {order.recipient.name}
                </p>
                <p>
                  <strong>Address:</strong> {order.recipient.address}
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requisition?.items?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item?.item.name}</td>
                        <td></td>
                        <td>{item?.quantity}</td>
                        <td>{item?.rate}</td>
                        <td>{Number(item?.quantity * item?.rate).toLocaleString()}</td>
                      </tr>
                    ))}
                    
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>{12}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRequisition;
