import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { RootState } from '../redux/app/store';
import { setFunders, setSelectedFunders } from '../redux/reducer/projects';

const useFunders = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    funders, selectedFunder
  } = useSelector(
    (state: RootState) => state.projects,
  );

  useEffect(() => {
    if (funders.length > 0) return;

    setLoading(true);
    doGet('funders').then((response) => {
      dispatch(setFunders(response.data.data));

    }).catch((e) => {

    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    doGet('funders').then((response) => {
      dispatch(setFunders(response.data.data));

    }).catch((e) => {

    }).finally(() => {
      setLoading(false);
    })
  }

  const getFunder = (id: number) => {
    setLoading(true);
    doGet('funders/' + id).then((response) => {
      dispatch(setSelectedFunders(response.data.data));

    }).catch((e) => {

    }).finally(() => {
      setLoading(false);
    })
  }

  return { funders, loading, refresh, getFunder, selectedFunder }

}

export default useFunders;
