import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from '../api';
import { getAllLeaves } from '../api/hr';
import { RootState } from '../redux/app/store';
import { setEmployeeLeave, setLeaveTypes, setSelectedLeave } from '../redux/reducer/hr';

const useEmployeeLeaves = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    leaves, selectedLeave, leaveTypes
  } = useSelector(
    (state: RootState) => state.hr,
  );

  useEffect(() => {

    if (Object.keys(leaves).length > 0) {
      setLoading(false);
      return;
    }

    getAllLeaves().then((response) => {
      if (response.status === 200) {
        dispatch(setEmployeeLeave(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedLeave = (id: number) => {
    setLoading(true);
    getAllLeaves(Number(id)).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedLeave(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const refresh = () => {
    getAllLeaves().then((response) => {
      if (response.status === 200) {
        dispatch(setEmployeeLeave(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getLeaveTypes = () => {
    if (leaveTypes.length > 0) {
      return;
    }
    setLoading(true);
    doGet('leave-types').then((response) => {
      if (response.status === 200) {
        dispatch(setLeaveTypes(response.data.data));
      }
    }).catch(() => {

    }).finally(() => {
      setLoading(false);
    })
  }


  return { loading, refresh, leaves, getSelectedLeave, selectedLeave, leaveTypes, getLeaveTypes }
}

export default useEmployeeLeaves