import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ListCashRequisitions from '../../components/procurement/cashRequisition/ListCashRequisitions'
import Breadcrumbs from '../../parts/Breadcrumbs'

const CashRequisition = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Cash Requisitions' />
        {/* body */}
        <ListCashRequisitions />
      </>
    </DashboardWrapper>
  )
}

export default CashRequisition