import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, successToastOptions, treatmentDefaultData, treatmentForm, treatmentFrequency, treatmentPeriod } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useAppointments from '../../../hooks/useAppointments';
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useTreatments from '../../../hooks/useTreatments';
import { formatDepartmentSelectData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';
import MySwitch from '../../global/MySwitch';
import Textarea from '../../global/Textarea';
interface IAddTreatmentProps {
  closeModal: () => void;
}

const AddTreatment = ({ closeModal }: IAddTreatmentProps) => {
  const id = useLastUrlSegment();
  const [isChecked, setIsChecked] = useState(true);
  const [treatment, setTreatment] = useState(treatmentDefaultData);
  const [formLoading, setLoading] = useState(false);

  const validator = new Validator();

  const { getPatientsSelectData, patients, getArvs, arvs, loading
  } = useAppointments(id);
  const { refresh } = useTreatments();
  useEffect(() => {
    return getPatientsSelectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return getArvs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setTreatment({ ...treatment, ...{ [name]: { ...treatment[name], error: false, data: value } } });
    } else {
      // indicate an error
      setTreatment({ ...treatment, ...{ [name]: { ...treatment[name], error: true, data: value } } });
    }
  };


  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setTreatment({ ...treatment, ...{ [name]: { ...treatment[name], error: false, data: value } } });
    } else {
      // indicate an error
      setTreatment({ ...treatment, ...{ [name]: { ...treatment[name], error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(treatment, setTreatment)) {
      setLoading(true);

      const data = {
        arv_type_id: treatment.arv_type_id.data,
        dosage: treatment.dosage.data,
        frequency: treatment.frequency.data,
        instructions: treatment.instructions.data,
        medication_time: [{ reminder: treatment.medication_time.data }],
        patient_id: treatment.patient_id.data,
        period: treatment.period.data,
        start_date: treatment.treatment_start_date.data,
        treatment_form: treatment.form.data,
      }
      try {
        let newTreatment;
        if (id) {
          newTreatment = await doCreate('treatment-reminders/create?clinic_id=' + id, data);
        } else {
          newTreatment = await doCreate('treatment-reminders/create', data);
        }
        if (newTreatment.data.success === true) {
          toast.success('New treatment appointment created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newTreatment.data.data), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }

    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className='form-row'>
        <div className="col-sm-6">
          <InputSelect id='patient_id' label='Patient name' name='patient_id' data={patients} required handleChange={handleSelectChange} value={treatment.patient_id.data} error={treatment.patient_id.error} errorMessage="Please select a patient" />
        </div>
        <div className="col-sm-6">
          <InputSelect id='form' label='Form' name='form' data={treatmentForm} required handleChange={handleSelectChange} value={treatment.form.data} error={treatment.form.error} errorMessage="Please select the treatment reminder form" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect id='arv_type_id' label='ARV name' name='arv_type_id' data={formatDepartmentSelectData(arvs)} required handleChange={handleSelectChange} value={treatment.arv_type_id.data} error={treatment.arv_type_id.error} errorMessage="Please select the arvs name" />
        </div>
        <div className="col-sm-6">
          <Input name='dosage' label='Dosage' handleChange={handleChange} required value={treatment.dosage.data} error={treatment.dosage.error} errorMessage="Please enter the dosage" />
        </div>
      </div>

      <div className="form-row">
        <Textarea name='instructions' label='Instructions' handleChange={handleChange} required value={treatment.instructions.data} error={treatment.instructions.error} errorMessage="Please enter the instructions" />
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect id='period' label='Period' name='period' required data={treatmentPeriod} handleChange={handleSelectChange} value={treatment.period.data} error={treatment.period.error} errorMessage="Please select reminder period" />
        </div>
        <div className="col-sm-6">
          <InputSelect id='frequency' label='Frequency' required name='frequency' data={treatmentFrequency} handleChange={handleSelectChange} value={treatment.frequency.data} error={treatment.frequency.error} errorMessage="Please select the frequency" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputDate label='Treatment start date' handleChange={handleSelectChange} required name='treatment_start_date' value={treatment.treatment_start_date.data} error={treatment.treatment_start_date.error} errorMessage="Please select the treatment start date" noLimit />
        </div>
        <div className="col-sm-6">
          <InputDate label='Refill date' handleChange={handleSelectChange} required name='refill_date' value={treatment.refill_date.data} error={treatment.refill_date.error} errorMessage="Please select a refill date" noLimit />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <MySwitch checked={isChecked} handleChange={() => setIsChecked((prev) => !prev)} label="Medicine has no end date" />
        </div>
        <div className="col-sm-6">
          {!isChecked && (<InputDate label='Treatment end date' handleChange={handleSelectChange} required={!isChecked} name='treatment_end_date' value={treatment.treatment_end_date.data} error={treatment.treatment_end_date.error} errorMessage="Please select treatment end date" noLimit />)}
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <InputDate label='Medication Time' handleChange={handleSelectChange} required noLimit withTime name='medication_time' value={treatment.medication_time.data} error={treatment.medication_time.error} errorMessage="Please select the medication time" />
        </div>
        <div className="col-sm-6">
          <InputDate label='Next appointment date' handleChange={handleSelectChange} required noLimit name='next_appointment_date' value={treatment.next_appointment_date.data} error={treatment.next_appointment_date.error} errorMessage="Please select the next appointment date" />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' className='btn  btn-info' disabled={formLoading} onClick={handleSubmit}>{formLoading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddTreatment