import React from 'react'

interface IRadioButtonProps {
  name: string;
  label: string;
  value?: string;
  checked?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
}

const RadioButton = ({ name, label, checked = false, handleChange, value, id }: IRadioButtonProps) => {
  return (
    <div className="custom-control custom-radio custom-control-inline">
      <input onChange={handleChange} type="radio" id={id} name={name} className="custom-control-input" checked={checked} value={value} />
      <label className="custom-control-label" htmlFor={id}>{label}</label>
    </div>
  )
}

export default RadioButton