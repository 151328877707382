import React from 'react'
import ListInvoices from '../../components/accounting/invoices/ListInvoices'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Invoices = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Invoices and payment vouchers' />
        {/* body */}
        <ListInvoices />
      </>
    </DashboardWrapper>

  )
}

export default Invoices