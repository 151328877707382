import React from "react";
import Dashboard1 from "../components/dashboard/Dashboard1";
// import Dashboard2 from '../components/dashboard/Dashboard2'
import Dashboard3 from "../components/dashboard/Dashboard3";
import { Dashboard4 } from "../components/dashboard/Dashboard4";
import DashboardAnnouncement from "../components/dashboard/DashboardAnnouncement";
import DashboardStats from "../components/dashboard/DashboardStats";
import RecentActivities from "../components/dashboard/RecentActivities";
// import RecentAssets from '../components/dashboard/RecentAssets'
import RecentProjects from "../components/dashboard/RecentProjects";
// import RecentStock from '../components/dashboard/RecentStock'
import DashboardWrapper from "../components/DashboardWrapper";
import useSessionStorage from "../hooks/useSessionStorage";
import Breadcrumbs from "../parts/Breadcrumbs";

const Dashboard = () => {
  const { isValidRole } = useSessionStorage();
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="" />
        {/* <DashboardDatePicker /> */}
        {/* <!-- main body --> */}
        {/* <DashboardStats /> */}
        {/* <DashboardAnnouncement /> */}
        <div className="row flex-wrap justify-content-between">
          {/* <Dashboard1 />
          <Dashboard4 />
          <Dashboard3 /> */}
          {/* <DailyActivities /> */}
          {/* <RecentAssets /> */}
          {/* <RecentStock /> */}
          {/* {
            isValidRole([1, 3, 4, 5, 9]) && (
              <>
                <RecentProjects />
                <RecentActivities />
              </>
            )} */}
        </div>
      </>
    </DashboardWrapper>
  );
};

export default Dashboard;
