import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ListFunFacts from '../../components/mobile_app/funFacts/ListFunFacts'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Facts = () => {

  return (
    <>

      <DashboardWrapper>
        <>
          <Breadcrumbs title='Fun Facts' />
          {/* <!-- main body --> */}
          <ListFunFacts />
        </>

      </DashboardWrapper>
    </>
  )
}

export default Facts