import React from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import ListEmployees from '../../../components/hr/employees/ListEmployees'
import Breadcrumbs from '../../../parts/Breadcrumbs'

const Employees = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Employees' />
        <ListEmployees />
      </>
    </DashboardWrapper>
  )
}

export default Employees