import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ListPurchaseOrder from '../../components/procurement/purchaseOrder/ListPurchaseOrder'
import Breadcrumbs from '../../parts/Breadcrumbs'

const PurchaseOrder = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Purchase Order' />
        {/* body */}
        <ListPurchaseOrder />
      </>
    </DashboardWrapper>
  )
}

export default PurchaseOrder