import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { createNewProject } from '../../../api/projects';
import { ERRORMESSAGE, errorToastOptions, projectDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useCategories from '../../../hooks/useCategories';
import useProjects from '../../../hooks/useProjects';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputSelect from '../../global/InputSelect';
import Textarea from '../../global/Textarea';

interface ICreateProjectProps {
  closeModal: () => void;
}

const CreateProject = ({ closeModal: onCancel }: ICreateProjectProps) => {
  const [project, setProject] = useState(projectDefaultData);
  const [loading, setLoading] = useState(false);

  const validator = new Validator();

  const { refresh } = useProjects();
  const { categoryFormData, getCategoriesFormData } = useCategories();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCategoriesFormData(), []);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setProject({ ...project, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setProject({ ...project, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setProject({ ...project, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setProject({ ...project, ...{ [name]: { error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(project, setProject)) {
      setLoading(true);

      const data = {
        project_name: project.project_name.data,
        implementing_partner: project.implementing_partner.data,
        location: project.location.data,
        cost: project.cost.data,
        activity_evaluation: project.activity_evaluation.data,
        challenges: project.challenges.data,
        level: project.level.data,
        recommendations: project.recommendations.data,
        sector: project.sector.data,
        project_category_id: project.project_category_id.data,
        reporting_period: project.reporting_period.data,
      }

      // console.log(data); return;

      try {
        const newProject = await createNewProject(data);
        if (newProject.data.success === true) {
          toast.success('New Project created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }

    }
  }

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='project_name' label='Project Name' handleChange={handleChange} value={project.project_name.data} error={project.project_name.error} errorMessage="Please provide the project name" required />
        </div>
        <div className="col-sm-6">
          <Input name='implementing_partner' label='Implementing Partner' handleChange={handleChange} value={project.implementing_partner.data} error={project.implementing_partner.error} errorMessage="Please provide the project implementing partner" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='location' label='Project Location' handleChange={handleChange} value={project.location.data} error={project.location.error} errorMessage="Please provide the project location" required />
        </div>
        <div className="col-sm-6">
          <Input name='cost' label='Estimented cost' handleChange={handleChange} value={project.cost.data} error={project.cost.error} errorMessage="Please provide the project cost" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='reporting_period' label='Reporting period' handleChange={handleChange} value={project.reporting_period.data} error={project.reporting_period.error} errorMessage="Please provide the project reporting period" required />
        </div>
        <div className="col-sm-6">
          <InputSelect data={categoryFormData} name='project_category_id' id='project_category_id' required handleChange={handleSelectChange} label='Project category' value={project.project_category_id.data} error={project.project_category_id.error} errorMessage="Please provide the project category" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='level' label='Project Level' handleChange={handleChange} value={project.level.data} error={project.level.error} errorMessage="Please provide the project level" required />
        </div>
        <div className="col-sm-6">
          <Input name='sector' label='Sector' handleChange={handleChange} value={project.sector.data} error={project.sector.error} errorMessage="Please provide the project sector" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Textarea name='activity_evaluation' label='Activity Evaluation' handleChange={handleChange} required value={project.activity_evaluation.data} error={project.activity_evaluation.error} errorMessage="Please provide the project activity evaluation" />
        </div>
        <div className="col-sm-6">
          <Textarea name='recommendations' label='Recommendations' handleChange={handleChange} required value={project.recommendations.data} error={project.recommendations.error} errorMessage="Please provide the project recommendations" />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Textarea name='challenges' label='Expected Challenges' handleChange={handleChange} required value={project.challenges.data} error={project.challenges.error} errorMessage="Please provide the project challenges" />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  )
}

export default CreateProject