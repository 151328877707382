import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { ERRORMESSAGE, errorToastOptions, patientDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useGeoData from '../../../hooks/useGeoData';
import usePatients from '../../../hooks/usePatients';
import { formatDepartmentSelectData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input'
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';

interface ICreatePatientProps {
  onClick: () => void;
  clinicId?: number;
}

const CreatePatient = ({ onClick, clinicId }: ICreatePatientProps) => {
  const [formLoading, setLoading] = useState(false);
  const [patient, setPatient] = useState(patientDefaultData);

  const { refresh } = usePatients(clinicId);

  const { districts, loading, _districts } = useGeoData();

  console.log(districts);

  // console.clear();

  const validator = new Validator();

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setPatient({ ...patient, ...{ [name]: { ...patient[name], error: false, data: value } } });
    } else {
      // indicate an error
      setPatient({ ...patient, ...{ [name]: { ...patient[name], error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setPatient({ ...patient, ...{ [name]: { ...patient[name], error: false, data: value } } });
    } else {
      // indicate an error
      setPatient({ ...patient, ...{ [name]: { ...patient[name], error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(patient, setPatient)) {
      setLoading(true);
      const clinic_id = clinicId ? clinicId : JSON.parse(sessionStorage.user).user.clinic_id;


      const data = {
        first_name: patient.first_name.data,
        last_name: patient.last_name.data,
        telephone: patient.telephone.data,
        pin: patient.pin.data,
        address: patient.address.data,
        district_id: patient.district_id.data,
        date_of_birth: patient.date_of_birth.data,
        gender: patient.gender.data,
        clinic_id,
        next_of_keen: [{
          first_name: patient.nox_first_name.data,
          last_name: patient.nox_last_name.data,
          telephone: patient.nox_telephone.data,
          address: patient.nox_address.data,
          district: patient.nox_district.data,
          gender: patient.nox_gender.data,
        }]
      }
      try {
        const newTreatment = await doCreate('patients/create', data);
        if (newTreatment.data.success === true) {
          toast.success('New patient created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            onClick();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newTreatment.data.data), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }

    }
  }


  return loading ? <EllipsisLoader /> : (
    <div>
      <form>
        <div className="form-row">
          <div className="col-md-6">
            <Input label='First Name' name='first_name' handleChange={handleChange} required value={patient.first_name.data} error={patient.first_name.error} errorMessage="Please enter the patient first name" />
          </div>
          <div className="col-md-6">
            <Input label='First name' name='last_name' handleChange={handleChange} required value={patient.last_name.data} error={patient.last_name.error} errorMessage="Please enter the patient last name" />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Input label='Telephone' name='telephone' handleChange={handleChange} required value={patient.telephone.data} error={patient.telephone.error} errorMessage="Please enter the patient telephone number" />
          </div>
          <div className="col-md-6">
            <Input label='Pin' name='pin' type='number' handleChange={handleChange} required value={patient.pin.data} error={patient.pin.error} errorMessage="The pin should be 4 digits long" min={4} max={4} />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Input label='Address' name='address' handleChange={handleChange} required value={patient.address.data} error={patient.address.error} errorMessage="Please enter the patient address" />
          </div>
          <div className="col-md-6">
            <InputSelect data={formatDepartmentSelectData(_districts)} id='district_id' label='District' name='district_id' handleChange={handleSelectChange} required value={patient.district_id.data} error={patient.district_id.error} errorMessage="Please select the patient district" />
          </div>
          <div className="col-md-6">
            <InputDate label='Date of birth' name='date_of_birth' handleChange={handleSelectChange} required value={patient.date_of_birth.data} error={patient.date_of_birth.error} errorMessage="Please select the patient date of birth" />
          </div>
          <div className="col-md-6">
            <InputSelect data={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }]} id='gender' label='Gender' name='gender' handleChange={handleSelectChange} required value={patient.gender.data} error={patient.gender.error} errorMessage="Please select the patient gender" />
          </div>
        </div>

        <h6>Next of keen details</h6>
        <hr />
        <div className="form-row">
          <div className="col-md-6">
            <Input label='First Name' name='nox_first_name' handleChange={handleChange} required value={patient.nox_first_name.data} error={patient.nox_first_name.error} errorMessage="Please enter the next of keen first name" />
          </div>
          <div className="col-md-6">
            <Input label='First name' name='nox_last_name' handleChange={handleChange} required value={patient.nox_last_name.data} error={patient.nox_last_name.error} errorMessage="Please enter the next of keen last name" />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Input label='Telephone' name='nox_telephone' handleChange={handleChange} required value={patient.nox_telephone.data} error={patient.nox_telephone.error} errorMessage="Please enter the next of keen telephone number" />
          </div>
          <div className="col-md-6">
            <InputSelect data={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }]} id='nox_gender' label='Gender' name='nox_gender' handleChange={handleSelectChange} required value={patient.nox_gender.data} error={patient.nox_gender.error} errorMessage="Please select the next of keen gender" />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6">
            <Input label='Address' name='nox_address' handleChange={handleChange} required value={patient.nox_address.data} error={patient.nox_address.error} errorMessage="Please enter the next of keen address" />
          </div>
          <div className="col-md-6">
            <InputSelect data={districts} id='nox_district' label='District' name='nox_district' handleChange={handleSelectChange} required value={patient.nox_district.data} error={patient.nox_district.error} errorMessage="Please enter the next of keen district" />
          </div>


        </div>


        <div className="form-row m-0 pb-4 pt-3 justify-content-end">
          <button type='reset' className='btn btn-outline-danger mr-3' onClick={onClick}>Cancel</button>
          <button onClick={handleSubmit} type='submit' className='btn  btn-info' disabled={formLoading}>{formLoading ? 'Creating...' : 'Create'}</button>
        </div>

      </form>
    </div>
  )
}

export default CreatePatient