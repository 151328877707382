import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { createNewActivityTeam } from '../../../api/projects';
import { addMemberDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatEmployeeToSelectFormData, formatErrorMessage } from '../../../helpers';
import useActivities from '../../../hooks/useActivities';
import useStaff from '../../../hooks/useStaff';
import { formatDepartmentSelectData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import InputSelect from '../../global/InputSelect';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IAddTeamMemberProps {
  onCancel: () => void;
  id: number
}

const AddTeamMember = ({ onCancel, id }: IAddTeamMemberProps) => {
  const [formLoading, setLoading] = useState(false);
  const [member, setMember] = useState(addMemberDefaultData);
  const { employees } = useStaff();
  const validator = new Validator();
  const allMembers = formatEmployeeToSelectFormData(employees);
  const { getActivity, getActivityTeamRoles, activityTeamRoles, loading } = useActivities();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getActivityTeamRoles(), []);

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setMember({ ...member, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setMember({ ...member, ...{ [name]: { error: true, data: value } } });
    }
  }


  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(member, setMember)) {
      setLoading(true);
      const data = {
        activity_team_role_id: member.activity_team_role_id.data,
        staff_id: member.member_id.data,
        activity_id: id
      }

      // console.log(data); return;

      try {
        const newProject = await createNewActivityTeam(data);
        console.log(newProject);
        if (newProject.data.success === true) {
          toast.success('New member added successfully', successToastOptions);
          getActivity(id); // reload
          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data, newProject.data.message), errorToastOptions);
        }
      } catch (err) {
        console.log(err);
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }

    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <InputSelect data={formatDepartmentSelectData(activityTeamRoles)} name='activity_team_role_id' id='activity_team_role_id' required handleChange={handleSelectChange} label='Member Role' errorMessage="Please select the member role" error={member.member_id.error} />
        </div>
        <div className="col-sm-6">
          <InputSelect data={allMembers} name='member_id' id='member_id' required handleChange={handleSelectChange} label='Users' errorMessage="Please provide the member" error={member.member_id.error} />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Adding..." : "Add"}
        </button>
      </div>
    </form>
  )
}

export default AddTeamMember