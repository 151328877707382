import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { doCreate } from '../../../../api';
import { ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../constants';
import { formatErrorMessage } from '../../../../helpers';
import useActivities from '../../../../hooks/useActivities';
import { EllipsisLoader } from '../../../global/MyLoaders';

interface IPermissionProps {
  closeModal: () => void;
  id: number;
  refresh: () => void;
}

const Permissions = ({ closeModal, id, refresh }: IPermissionProps) => {
  const [formLoading, setLoading] = useState(false);
  const [formRemoveLoading, setFormRemoveLoading] = useState(false);
  const [assign, setAssign] = useState<Array<any>>([]);
  const [assigned, setAssigned] = useState<Array<any>>([]);
  const [removed, setRemoved] = useState<Array<any>>([]);

  const { getAvailablePermissions, rolePermission, assignedPermissions, loading } = useActivities();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAvailablePermissions(id), [id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setAssigned(assignedPermissions?.map((item: any) => item.id)), [assignedPermissions]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, checked } = e.target as HTMLInputElement;
    if (checked) {
      let newArray = [...assign, value];
      setAssign(newArray);
    } else {
      let newArray = assign.filter((item: any) => Number(item) !== Number(value));
      setAssign(newArray);
    }
  }

  const handleAssignedChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, checked } = e.target as HTMLInputElement;
    if (checked) {
      let newArray = [...assigned, value];
      setAssigned(newArray);
      let newRemoved = removed.filter((item: any) => Number(item) !== Number(value));
      setAssigned(newArray);
      setRemoved(newRemoved);
    } else {
      let newArray = assigned.filter((item: any) => Number(item) !== Number(value));
      setAssigned(newArray);
      let newRemoved = [...removed, value];
      setRemoved(newRemoved);
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (assign.length === 0) {
      return;

    }
    const data = {
      permissions: assign
    }
    try {
      setLoading(true);
      const response = await doCreate('activity-roles/' + id + '/attach_permission', data);
      if (response.data.success === true) {
        toast.success('New permission(s) attached successfully', successToastOptions);
        refresh(); // refresh the hook store
        setTimeout(() => {
          closeModal();
        }, 1000);
      } else {
        toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
      }
    } catch (err) {
      toast.error(ERRORMESSAGE, errorToastOptions);
    } finally {
      setLoading(false)
    }
  }

  const removeSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (removed.length === 0) {
      return;
    }
    const data = {
      permissions: removed
    }
    try {
      setFormRemoveLoading(true);
      const response = await doCreate('activity-roles/' + id + '/detach_permission', data);
      if (response.data.success === true) {
        toast.success('Permission(s) detached successfully', successToastOptions);
        refresh(); // refresh the hook store
        setTimeout(() => {
          closeModal();
        }, 1000);
      } else {
        toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
      }
    } catch (err) {
      toast.error(ERRORMESSAGE, errorToastOptions);
    } finally {
      setFormRemoveLoading(false)
    }
  }

  return loading ? <EllipsisLoader /> : (
    <div className="form-row">
      <div className="col-sm-6 border-r-1">
        {rolePermission.length > 0 ? (
          <form>
            {rolePermission.map((item: any) => <div key={item.id} className="remember-checkbox p-10">
              <input type="checkbox" className="form-control" name="assign" id={item.name} value={item.id} onChange={handleChange} />
              <label htmlFor={item.name} className="m-0"> {item.name}</label>
            </div>)}
            <div className="form-row m-0 pb-4 pt-3 justify-content-start">
              <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
              <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Assigning..." : "Assign"}</button>
            </div>
          </form>
        ) : (
          <p className='text-muted text-center mt-3'>No new permission</p>
        )}

      </div>
      <div className="col-sm-6">
        {assignedPermissions.length > 0 && (
          <form>
            <h5>Assigned roles</h5>
            {assignedPermissions.map((item: any) => <div key={item.id} className="remember-checkbox p-10">
              <input type="checkbox" className="form-control" name="two" id={item.name} checked={assigned.includes(item.id)} onChange={handleAssignedChange} value={item.id} />
              <label htmlFor={item.name} className="m-0"> {item.name}</label>
            </div>)}
            <div className="form-row m-0 pb-4 pt-3 justify-content-start">
              <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
              <button type='submit' disabled={formLoading} className='btn  btn-primary' onClick={removeSubmit}>{formRemoveLoading ? "Updating..." : "Update"}</button>
            </div>
          </form>

        )}
      </div>
    </div>
  )
}

export default Permissions