import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ViewActivity from '../../components/project/activities/ViewActivity'
import useActivities from '../../hooks/useActivities'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ViewActivityDetails = () => {
  const { selectedActivity } = useActivities();
  return (
    <DashboardWrapper >
      <>
        <Breadcrumbs title='Activity details' parents={[{ link: ROUTES.PROJECTS, title: 'Projects' }, { link: `/projects/view/${selectedActivity?.project_id}`, title: selectedActivity?.project?.project_name }]} />
        <ViewActivity />

      </>
    </DashboardWrapper >
  )
}

export default ViewActivityDetails