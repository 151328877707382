import { AuthenticatedAPIInstance } from "../../utils/axiosV2";

export const createCustomer = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("customers", data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const edditCustomer = async (id: number, data: any) => {
  const response = await AuthenticatedAPIInstance.post("customers/" + id + '/update', data).then((response) => response)
    .catch((error) => error);
  return response;
}


export const getAllCustomers = async () => {
  const allStories = await AuthenticatedAPIInstance.get("customers").then((response) => response)
    .catch((error) => error);
  return allStories;
}

export const getCustomer = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get("customers/" + id).then((response) => response)
    .catch((error) => error);
  return response;
}




