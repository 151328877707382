import React from 'react'
import CategoriesTable from '../../components/project/categories/CategoriesTable'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const Categories = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Categories' parents={[{ link: ROUTES.PROJECTS, title: 'Projects' }]} />
        <CategoriesTable />

      </>
    </SettingsWrapper>
  )
}

export default Categories