import React from 'react'
import ListDepartments from '../../components/settings/departments/ListDepartments'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Departments = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='Departments' />
        {/* body */}
        <ListDepartments />
      </>
    </SettingsWrapper>

  )
}

export default Departments