import React from 'react'
import ListRequisitions from '../../components/procurement/requisition/ListRequisitions'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Requisition = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Requisitions' />
        {/* body */}
        <ListRequisitions />
      </>
    </DashboardWrapper>

  )
}

export default Requisition