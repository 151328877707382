import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import useCustomers from "../../hooks/useCustomers";
import ConfirmDelete from "../global/ConfirmDelete";
import ModalSwitcher from "../global/ModalSwitcher";
import MyDataTable from "../global/MyDataTable";
import { EllipsisLoader } from "../global/MyLoaders";
import { formatCurrency } from "../../utils/helpers";
import EditAsset from "../assetManagement/EditAsset";
import AddCustomer from "./forms/AddCutomer";

const ListCustomers = () => {
  const { customers, loading, refresh } = useCustomers();
  const [isOpen, setIsOpen] = useState(false);
  const [localCustomers, setLocalCustomers] = useState([]);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    setLocalCustomers(customers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, customers]);

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "Customer Name",
      selector: (row) => (
        <Link className="text-info" to={`/customers/view/${row.id}`}>
          {row.name}
        </Link>
      ),
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Email Address",
      selector: (row) => row.email,
      reorder: true,
      sortable: true,
    },
    {
      id: 3,
      name: "Phone Number",
      selector: (row) => row.phone_no,
      reorder: true,
      sortable: true,
    },
    {
      id: 4,
      name: "Debit",
      selector: (row) => formatCurrency(row.debt),
      reorder: true,
      sortable: true,
    },
    {
      id: 5,
      name: "Debit Limit",
      selector: (row) => formatCurrency(row.debt_limit),
      reorder: true,
      sortable: true,
    },
    {
      id: 6,
      name: "Status",
      selector: (row) => (row.status_id === 1 ? "Active" : "Dormant"),
      reorder: true,
      sortable: true,
    },
    {
      button: true,
      cell: (row) => (
        <div className="row">
          <button
            className="btn btn-sm btn-primary"
            // onClick={(e) => {
            //   e.stopPropagation();
            //   handleEditClick(row.id);
            // }}
          >
            <i className="fa fa-edit"></i>
          </button>

          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    const results = customers.filter(
      (res: any) =>
        String(res?.email)
          .toLocaleLowerCase()
          .includes(String(value).toLocaleLowerCase()) ||
        String(res?.name)
          .toLocaleLowerCase()
          .includes(String(value).toLocaleLowerCase())
    );
    setLocalCustomers(results);
  };

  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(3);
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = "edit-asset";
      modalTitle = "Edit asset";
      ModalToRender = EditAsset;

      break;
    case 3:
      modalId = "delete-customer";
      modalTitle = "";
      ModalToRender = ConfirmDelete;
      break;
    default:
      modalId = "add-new-customer";
      modalTitle = "Add New Customer";
      ModalToRender = AddCustomer;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            closeModal={handleModalClick}
            path={`customers/${selectedId}/delete`}
            refresh={refresh}
          />
        }
      />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsOpen(true);
                    }}
                  >
                    Add new customer
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? (
              <EllipsisLoader />
            ) : (
              <MyDataTable columns={columns} data={localCustomers} title="" />
            )}
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListCustomers;
