import React from 'react'

interface IBidAnalysisProps {
  id: number;
  closeModal: () => void;
  refresh: () => void;
}

const EditBidAnalysis = ({ closeModal, id, refresh }: IBidAnalysisProps) => {
  return (
    <div>EditBidAnalysis</div>
  )
}

export default EditBidAnalysis