/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import Footer from '../parts/Footer';
import Nav from '../parts/Nav';
import SideBar from '../parts/SideBar';
import { RootState } from '../redux/app/store';

interface IDashboardWrapperProps {
  children: ReactElement
}

const DashboardWrapper = ({ children }: IDashboardWrapperProps) => {
  const { toggleSideNav } = useSelector(
    (state: RootState) => state.general,
  );


  return (
    <div className={`${toggleSideNav ? 'wrapper slide-menu' : 'wrapper'} `}>
      <Nav />
      <div className="container-fluid">
        <div className="row">
          <SideBar />

          <div className="content-wrapper" id="app_modal">
            {children}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardWrapper