import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { stockCreate } from '../../../api/stocks';
import { ERRORMESSAGE, errorToastOptions, stockDefaultData, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useStocks from '../../../hooks/useStocks';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IEditStockProps {
  id: number
  closeModal: () => void
}

const EditStock = ({ id, closeModal }: IEditStockProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [stock, setStock] = useState(stockDefaultData);
  const { loading, getStock, selectedStock, refresh } = useStocks();

  const validator = new Validator();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getStock(id), [id]);

  const formNames = ['name', 'quantity', 'total_price', 'bought_on'];

  useEffect(() => {
    let stockData = selectedStock;
    if (!loading) {
      for (let x of formNames) {
        stockData = { ...stockData, ...{ [x]: { ...stockData[x], ...{ error: false, data: selectedStock[x] } } } };
      }
      setStock(stockData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setStock({ ...stock, ...{ [name]: { ...stock[name], error: false, data: value } } });
    } else {
      // indicate an error
      setStock({ ...stock, ...{ [name]: { ...stock[name], error: true, data: value } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setStock({ ...stock, ...{ [name]: { ...stock[name], error: false, data: value } } });
    } else {
      // indicate an error
      setStock({ ...stock, ...{ [name]: { ...stock[name], error: true, data: value } } });
    }
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(stock, setStock)) {
      setFormLoading(true);

      const data = {
        name: stock.name.data,
        quantity: stock.quantity.data,
        total_price: stock.total_price.data,
        bought_on: stock.bought_on.data,
      }

      // console.log(data); return;

      try {
        const newProject = await stockCreate(data, 'stock/' + id + '/update');
        if (newProject.data.success === true) {
          toast.success('New stock created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data, newProject.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setFormLoading(false)
      }

    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Name' handleChange={handleChange} value={stock.name.data} error={stock.name.error} errorMessage="Please enter the stock name" required />
        </div>
        <div className="col-sm-6">
          <Input name='quantity' label='Quantity' type='number' handleChange={handleChange} value={stock.quantity.data} error={stock.quantity.error} errorMessage="Please enter the stock quantity" required />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='total_price' label='Total Price' handleChange={handleChange} value={stock.total_price.data} error={stock.total_price.error} errorMessage="Please enter the stock total price" required />
        </div>
        <div className="col-sm-6">
          <InputDate name='bought_on' label='Date bought' handleChange={handleSelectChange} required value={stock.bought_on.data} error={stock.bought_on.error} errorMessage="Please select the date the stock was bought on" />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Updating..." : "Update"}</button>
      </div>
    </form>
  )
}

export default EditStock