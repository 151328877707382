import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { stockCreate } from "../../../api/stocks";
import {
  ERRORMESSAGE,
  errorToastOptions,
  stockProductDefaultData,
  successToastOptions,
} from "../../../constants";
import { formatErrorMessage } from "../../../helpers";
import useAccounts from "../../../hooks/useAccounts";
import useBrands from "../../../hooks/useBrands";
import useBudget from "../../../hooks/useBudget";
import useStockCategories from "../../../hooks/useStockCategories";
import useStockProduct from "../../../hooks/useStockProducts";
import useSuppliers from "../../../hooks/useSuppliers";
import {
  formatAcountSelectData,
  formatDepartmentSelectData,
  formatMultipleSelectData,
  formatSelectData,
  getFormData,
} from "../../../utils/helpers";
import Validator from "../../../utils/Validator";
import Input from "../../global/Input";
import InputSelect from "../../global/InputSelect";
import { EllipsisLoader } from "../../global/MyLoaders";
import useUnits from "../../../hooks/useUnits";

interface IAddProductProps {
  closeModal: () => void;
}

const AddProduct = ({ closeModal }: IAddProductProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [product, setProduct] = useState(stockProductDefaultData);

  const { formattedBrand } = useBrands();
  const { formattedProductCategories } = useStockCategories();
  const { refresh } = useStockProduct();
  const { formattedSupplier } = useSuppliers();
  const { budgets } = useBudget();
  const { units } = useUnits();

  const {
    cashAccounts,
    loading,
    formattedAssetAccounts,
    getCashAccount,
    getAssetAccount,
  } = useAccounts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getCashAccount(), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAssetAccount(), []);

  const validator = new Validator();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as
      | HTMLInputElement
      | HTMLSelectElement
      | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setProduct({
        ...product,
        ...{ [name]: { ...product[name], error: false, data: value } },
      });
    } else {
      // indicate an error
      setProduct({
        ...product,
        ...{ [name]: { ...product[name], error: true, data: value } },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setProduct({
        ...product,
        ...{ [name]: { ...product[name], error: false, data: value } },
      });
    } else {
      // indicate an error
      setProduct({
        ...product,
        ...{ [name]: { ...product[name], error: true, data: value } },
      });
    }
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(product, setProduct)) {
      setFormLoading(true);

      // const data = {
      //   name: product.name.data,
      //   reference: product.reference.data,
      //   price: product.price.data,
      //   vat: product.vat.data,
      //   project_category_id: product.project_category_id.data,
      //   brand_id: product.brand_id.data,
      //   supplier_id: product.supplier_id.data,
      //   account_chart_id: product.account_chart_id.data
      // }

      const data = getFormData(product);

      try {
        const newProject = await stockCreate(data, "items/create");
        if (newProject.data.success === true) {
          toast.success(
            "New product created successfully",
            successToastOptions
          );
          refresh(); // refresh the hook store
          setProduct(stockProductDefaultData);
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(
            formatErrorMessage(newProject.data.data),
            errorToastOptions
          );
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setFormLoading(false);
      }
    }
  };

  return loading ? (
    <EllipsisLoader />
  ) : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            name="name"
            label="Name"
            handleChange={handleChange}
            value={product.name.data}
            error={product.name.error}
            errorMessage="Please enter the product name"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            name="reference"
            label="Reference"
            handleChange={handleChange}
            value={product.reference.data}
            error={product.reference.error}
            errorMessage="Please enter the product reference"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            name="price"
            label="Price"
            type="number"
            handleChange={handleChange}
            value={product.price.data}
            error={product.price.error}
            errorMessage="Please enter the product price"
            required
          />
        </div>

        <div className="col-sm-6">
          <InputSelect
            data={formatSelectData(units)}
            id="unit_id"
            name="unit_id"
            label="Units"
            handleChange={handleSelectChange}
            required
            value={product.unit_id.data}
            error={product.unit_id.error}
            errorMessage="Please select the units"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-4">
          <InputSelect
            data={formattedSupplier}
            id="supplier_id"
            name="supplier_id"
            label="Supplier"
            handleChange={handleSelectChange}
            value={product.supplier_id.data}
            error={product.supplier_id.error}
            errorMessage="Please select the product supplier"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formattedProductCategories}
            id="item_category_id"
            label="Category"
            name="item_category_id"
            required
            handleChange={handleSelectChange}
            value={product.item_category_id.data}
            error={product.item_category_id.error}
            errorMessage="Please select the category"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formattedBrand}
            id="brand_id"
            label="Brand"
            name="brand_id"
            handleChange={handleSelectChange}
            value={product.brand_id.data}
            error={product.brand_id.error}
            errorMessage="Please select the brand"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-4">
          <InputSelect
            data={formatDepartmentSelectData(budgets)}
            label="Budget"
            name="budget_id"
            id="budget_id"
            handleChange={handleSelectChange}
            value={product.budget_id.data}
            error={product.budget_id.error}
            errorMessage="Please select the budget"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formatAcountSelectData(cashAccounts)}
            label="Fund Account"
            name="account_chart_id"
            id="account_chart_id"
            required
            handleChange={handleSelectChange}
            value={product.account_chart_id.data}
            error={product.account_chart_id.error}
            errorMessage="Please select the fund account"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formattedAssetAccounts}
            label="Stock Account"
            name="product_account"
            id="product_account"
            required
            handleChange={handleSelectChange}
            value={product.product_account.data}
            error={product.product_account.error}
            errorMessage="Please select the product account"
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddProduct;
