import React from 'react'
import ViewBudgetItems from '../../components/accounting/budget/ViewBudgetItems'
import DashboardWrapper from '../../components/DashboardWrapper'
import { useLastUrlSegment } from '../../hooks/useQuery'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ViewBudgetDetails = () => {
  const id = useLastUrlSegment();
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Budget Details' parents={[{ link: ROUTES.BUDGET, title: "Budgets" }]} />
        {/* body */}
        <ViewBudgetItems id={id} />
      </>
    </DashboardWrapper>
  )
}

export default ViewBudgetDetails