import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ListBidAnalysis from '../../components/procurement/bidAnalysis/ListBidAnalysis'
import Breadcrumbs from '../../parts/Breadcrumbs'

const BidAnalysis = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Bid Analysis' />
        {/* body */}
        <ListBidAnalysis />
      </>
    </DashboardWrapper>
  )
}

export default BidAnalysis