import React, { ReactElement, useEffect } from "react";
import toast from "react-hot-toast";
import { errorToastOptions } from "../../constants";
import Input from "./Input";
import { EllipsisLoader } from "./MyLoaders";
import InputSelect from "./InputSelect";
import {
  formatAcountSelectData,
  formatDepartmentSelectData,
} from "../../utils/helpers";
import useAccounts from "../../hooks/useAccounts";
import useBudget from "../../hooks/useBudget";

interface IDynamicFormProps {
  defaultData: Array<any>;
  setValues: (item: Array<any>) => void;
  values: Array<any>;
}

const PayBillForm = ({
  defaultData,
  values,
  setValues,
}: IDynamicFormProps): ReactElement => {
  const handleAddClick = () => {
    setValues([...values]);
  };
  const { accounts, loading, expenseAccounts, getExpenseAccount } =
    useAccounts();
  const { budgets } = useBudget();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getExpenseAccount(), [accounts]);

  const handleMinusClick = (index: number) => {
    const newState = values;
    if (newState.length === 1) {
      toast.error("You can't remove this column", errorToastOptions);
      return;
    }
    newState.splice(index, 1);
    setValues([...newState]);
  };

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, id } = e.target as HTMLInputElement;
    const newState = values;
    newState[Number(id)][name] = value;
    setValues(newState);
  };

  const handleSelectChange = (e: any, id: number) => {
    const { name, value } = e;
    const newState = values;
    newState[Number(id)][name] = value;
    setValues(newState);
  };

  return (
    <div>
      {defaultData?.map((item: any, index: any) => {
        const newState = values;
        if (newState) {
          newState[Number(index)] = { name: item?.name, amount: item?.amount };
          setValues(newState);
        }
        return (
          <div key={item?.id} className="form-row items-center">
            <div className="col-sm-3">
              <p>{item?.name}</p>
            </div>
            <div className="col-sm-3">
              <Input
                type="number"
                name="amount"
                label="Bill Amount"
                handleChange={handleChange}
                value={item?.amount}
                required
              />
            </div>
            <div className="col-3">
              <InputSelect
                data={formatAcountSelectData(expenseAccounts)}
                name="debit"
                label="Debit"
                id="debit"
                required
                handleChange={(e) => handleSelectChange(e, index)}
              />
            </div>
            <div className="col-3">
              <InputSelect
                data={formatDepartmentSelectData(budgets)}
                name="budget_id"
                label="Choose Budget"
                id="budget_id"
                required
                handleChange={(e) => handleSelectChange(e, index)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PayBillForm;
