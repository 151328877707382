import { AuthenticatedAPIInstance } from "../../utils/axios";

export const getAllEmployees = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`staff/${id}`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }
  const allEmployees = await AuthenticatedAPIInstance.get("staff").then((response) => response)
    .catch((error) => error);
  return allEmployees;
}

export const createEmployee = async (data: any) => {
  const allEmployees = await AuthenticatedAPIInstance.post("staff/create", data).then((response) => response)
    .catch((error) => error);
  return allEmployees;
}

export const getAllLeaves = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`leave/${id}`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }

  const allEmployees = await AuthenticatedAPIInstance.get("leave").then((response) => response)
    .catch((error) => error);
  return allEmployees;
}

export const createLeave = async (data: any) => {
  const allEmployees = await AuthenticatedAPIInstance.post(`leave/create`, data).then((response) => response)
    .catch((error) => error);
  return allEmployees;
}


export const getAllPayrolls = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`payrolls/${id}`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }
  const allEmployees = await AuthenticatedAPIInstance.get("payrolls/last").then((response) => response)
    .catch((error) => error);
  return allEmployees;
}

export const createPayroll = async (data: any) => {
  const allEmployees = await AuthenticatedAPIInstance.post(`payrolls/create`, data).then((response) => response)
    .catch((error) => error);
  return allEmployees;
}

export const getStaffPayroll = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`payrolls/staff/${id}`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }
}


export const getPayrollStats = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`payrolls/staff/${id}/stats`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }
}

export const getAllPerformances = async (id?: number) => {
  if (typeof id === 'number') {
    const allEmployees = await AuthenticatedAPIInstance.get(`performance-cycles/${id}`).then((response) => response)
      .catch((error) => error);
    return allEmployees;
  }
  const allEmployees = await AuthenticatedAPIInstance.get("performance-cycles").then((response) => response)
    .catch((error) => error);
  return allEmployees;
}