import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../api/hr";
import { RootState } from "../redux/app/store";
import {
  setEmployees,
  setFormSelectEmployeeData,
  setSelectedEmployee,
} from "../redux/reducer/hr";
import { formatStaffSelectData } from "../utils/helpers";

const useStaff = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const { employees, selectedEmployee, formSelectEmployeeData } = useSelector(
    (state: RootState) => state.hr
  );

  useEffect(() => {
    if (employees.length > 0) {
      setLoading(false);
      return;
    }

    getAllEmployees()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setEmployees(response.data.data));
          dispatch(
            setFormSelectEmployeeData(formatStaffSelectData(response.data.data))
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);

  const refresh = () => {
    getAllEmployees()
      .then((response) => {
        if (response.status === 200) {
          dispatch(setEmployees(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStaff = (id: number) => {
    setLoading(true);
    getAllEmployees(Number(id))
      .then((response) => {
        if (response.status === 200) {
          dispatch(setSelectedEmployee(response.data.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    employees,
    loading,
    refresh,
    getStaff,
    selectedEmployee,
    formSelectEmployeeData,
  };
};

export default useStaff;
