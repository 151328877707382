import { AuthenticatedAPIInstance } from "../../utils/axios";

export const getAllProjects = async () => {
  const allProjects = await AuthenticatedAPIInstance.get("projects").then((response) => response)
    .catch((error) => error);
  return allProjects;
}

export const createNewProject = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("projects/create", data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const getSingleProject = async (id: number) => {
  if (!id) return;
  const allProjects = await AuthenticatedAPIInstance.get(`projects/${id}`).then((response) => response)
    .catch((error) => error);
  return allProjects;
}
///categories/{id}/others

export const getAllCategories = async () => {
  const allCategories = await AuthenticatedAPIInstance.get("categories").then((response) => response)
    .catch((error) => error);
  return allCategories;
}

export const getCategory = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get(`categories/${id}`).then((response) => response)
    .catch((error) => error);
  return response;
}

export const getAllOtherCategory = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get(`categories/${id}/others`).then((response) => response)
    .catch((error) => error);
  return response;
}

export const createNewCategory = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("categories/create", data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const updateCategory = async (data: any, id: number) => {
  const response = await AuthenticatedAPIInstance.post(`categories/${id}/update`, data).then((response) => response)
    .catch((error) => error);
  return response;
}


export const getAllActivities = async () => {
  const allActivities = await AuthenticatedAPIInstance.get("activities").then((response) => response)
    .catch((error) => error);
  return allActivities;
}

export const createNewActivity = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("activities/create", data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const createNewActivityTeam = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("/projects/activity-team/create", data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const getActivityDetails = async (id: number) => {
  const allActivities = await AuthenticatedAPIInstance.get(`activities/${id}`).then((response) => response)
    .catch((error) => error);
  return allActivities;
}

export const getAllPrograms = async () => {
  const allPrograms = await AuthenticatedAPIInstance.get("programs").then((response) => response)
    .catch((error) => error);
  return allPrograms;
}

export const getProgram = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get(`programs/${id}`).then((response) => response)
    .catch((error) => error);
  return response;
}

export const createNewProgram = async (data: any) => {
  const response = await AuthenticatedAPIInstance.post("programs/create", data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const editProgram = async (data: any, id: number) => {
  const response = await AuthenticatedAPIInstance.post(`programs/${id}/update`, data).then((response) => response)
    .catch((error) => error);
  return response;
}

export const getAllOtherPrograms = async (id: number) => {
  const response = await AuthenticatedAPIInstance.get(`programs/${id}/others`).then((response) => response)
    .catch((error) => error);
  return response;
}