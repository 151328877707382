import React from 'react'
import ListCategories from '../../components/stocks/category/ListCategories'
import StocksWrapper from '../../components/StocksWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const Categories = () => {
  return (
    <StocksWrapper>
      <>
        <Breadcrumbs title='Categories' parents={[{ link: ROUTES.STOCKS, title: 'Stock Management' }]} />
        <ListCategories />
      </>
    </StocksWrapper>
  )

}

export default Categories