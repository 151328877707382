import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ListGoodsRecieved from '../../components/procurement/goodsRecieved/ListGoodsRecieved'
import Breadcrumbs from '../../parts/Breadcrumbs'

const GoodsRecieved = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Goods Recieved' />
        {/* body */}
        <ListGoodsRecieved />
      </>
    </DashboardWrapper>
  )
}

export default GoodsRecieved