import React from 'react'
import ProgramsTable from '../../components/project/programs/ProgramsTable'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const Programs = () => {
  return (
    <SettingsWrapper >
      <>
        <Breadcrumbs title='Programs' parents={[{ link: ROUTES.PROJECTS, title: 'Projects' }]} />
        <ProgramsTable />

      </>
    </SettingsWrapper >
  )
}

export default Programs