import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { stockCreate } from '../../../api/stocks';
import { ERRORMESSAGE, errorToastOptions, successToastOptions, supplierDefaultData } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import useSuppliers from '../../../hooks/useSuppliers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';

interface IAddSupplierProps {
  closeModal: () => void
}

const AddSupplier = ({ closeModal }: IAddSupplierProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [supplier, setSupplier] = useState(supplierDefaultData);

  const { refresh } = useSuppliers();

  const validator = new Validator();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setSupplier({ ...supplier, ...{ [name]: { ...supplier[name], error: false, data: value } } });
    } else {
      // indicate an error
      setSupplier({ ...supplier, ...{ [name]: { ...supplier[name], error: true, data: value } } });
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(supplier, setSupplier)) {
      setFormLoading(true);

      const data = {
        name: supplier.name.data,
        email: supplier.email.data,
        telephone: supplier.telephone.data,
        location: supplier.location.data,
      }

      // console.log(data); return;

      try {
        const newProject = await stockCreate(data, 'suppliers/create');
        if (newProject.data.success === true) {
          toast.success('New supplier created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(newProject.data.data, newProject.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setFormLoading(false)
      }

    }
  }

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Name' handleChange={handleChange} value={supplier.name.data} error={supplier.name.error} errorMessage="Please enter the supplier name" required />
        </div>
        <div className="col-sm-6">
          <Input name='email' label='Email' type='email' handleChange={handleChange} value={supplier.email.data} error={supplier.email.error} errorMessage="Please enter the supplier email address" required />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='telephone' label='Telephone Number' handleChange={handleChange} type='tel' value={supplier.telephone.data} error={supplier.telephone.error} errorMessage="Please enter the supplier telephone" required />
        </div>
        <div className="col-sm-6">
          <Input name='location' label='Supplier Location' handleChange={handleChange} value={supplier.location.data} error={supplier.location.error} errorMessage="Please enter the supplier location" required />
        </div>
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>{formLoading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddSupplier