
import { createSlice } from '@reduxjs/toolkit';

interface ICustomer {
  customers: any;
  selectedCustomer: any;
}

const initialState: ICustomer = {
  customers: [],
  selectedCustomer: {},
}

export const customerReducer = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomers: (state, { payload }) => {
      state.customers = payload
    },
    setSelectedCustomer: (state, { payload }) => {
      state.selectedCustomer = payload
    },
  }
});


const { reducer, actions } = customerReducer;
export const { setCustomers, setSelectedCustomer } = actions;
export default reducer;
