import React, { useState } from "react";
import { TableColumn } from "react-data-table-component";
import useFunders from "../../../hooks/useFunders";
import ConfirmDelete from "../../global/ConfirmDelete";
import ModalSwitcher from "../../global/ModalSwitcher";
import MyDataTable from "../../global/MyDataTable";
import { EllipsisLoader } from "../../global/MyLoaders";
import AddFunder from "./AddFunder";
import EditFunder from "./EditFunder";
import { formatCurrency } from "../../../utils/helpers";

const ListFunders = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { refresh, funders, loading } = useFunders();

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      reorder: true,
      sortable: true,
      grow: 4,
      wrap: true,
    },
    {
      id: 3,
      name: "Telephone(s)",
      selector: (row) =>
        row.telephone_2 ? row.telephone + "/" + row.telephone_2 : row.telephone,
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      id: 4,
      name: "Amount",
      selector: (row) => formatCurrency(row.amount),
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      id: 5,
      name: "Email",
      selector: (row) => row.email,
      reorder: true,
      sortable: true,
      grow: 3,
      wrap: true,
    },

    {
      button: true,
      cell: (row) => (
        <div className="row">
          {/* <button className='btn btn-sm btn-primary mx-1'><i className='fa fa-eye'></i></button> */}
          <button className="btn btn-sm btn-primary">
            <i
              className="fa fa-edit"
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(row.id);
              }}
            ></i>
          </button>

          <button
            className="btn btn-sm btn-danger mx-1"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(row.id);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];
  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  };

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = "edit-funder";
      modalTitle = "Edit Funder";
      ModalToRender = EditFunder;

      break;
    case 3:
      modalId = "delete-funder";
      modalTitle = "";
      ModalToRender = ConfirmDelete;
      break;
    default:
      modalId = "add-new-funder";
      modalTitle = "Add New Funder";
      ModalToRender = AddFunder;
      break;
  }

  return (
    <>
      <ModalSwitcher
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        id={modalId}
        title={modalTitle}
        component={
          <ModalToRender
            id={selectedId}
            closeModal={handleModalClick}
            path={`funders/${selectedId}/delete`}
            refresh={refresh}
          />
        }
      />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block">
                <div className="pl-3 row align-items-end">
                  <button
                    className="btn btn-primary ml-auto mr-3"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenModal(0);
                      setIsOpen(true);
                    }}
                  >
                    <i className="fa fa-plus"></i>Add new funder
                  </button>
                </div>
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  <i className="fa fa-search"></i>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search...."
                  />
                </div>
              </div>
            </div>
            {/* table */}
            {loading ? (
              <EllipsisLoader />
            ) : (
              <MyDataTable columns={columns} data={funders} title="" />
            )}
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListFunders;
