import React, { useEffect } from 'react'
import useActivities from '../../../hooks/useActivities';
import { apiConfig } from '../../../utils/axios';
import { EllipsisLoader } from '../../global/MyLoaders';

interface IViewProjectReportProps {
  activity_id: number;
  onCancel: () => void;
}

const ViewProjectReport = ({ onCancel, activity_id }: IViewProjectReportProps) => {
  const { getActivityReports, activityReports, loading } = useActivities();



  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getActivityReports(activity_id), [activity_id]);
  return loading ? <EllipsisLoader /> : (
    <div>
      <div className="row">
        {activityReports.length > 0 ? activityReports.map((report: any, id: number) => (
          <div className="col-12" key={report?.id}>
            {`${++id}. `} <a className='btn btn btn-outline-secondary' target={'_blank'} rel="noreferrer" href={`${apiConfig.baseURL}activities/${activity_id}/reports/${report?.id}`} > <i className='fa fa-download'></i> Download</a>
          </div>
        )) : (
          <div className="col-12">
            <p className='text-muted'>There is no report yet!</p>
          </div>

        )}
      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>

      </div>
    </div>
  )
}

export default ViewProjectReport