import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllStories, getStory } from '../api/mobile_app';
import { RootState } from '../redux/app/store';
import { setSelectedStory, setStories } from '../redux/reducer/mobile_app';

const useStories = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    stories, selectedStory
  } = useSelector(
    (state: RootState) => state.mobleApp,
  );

  useEffect(() => {

    if (Object.keys(stories).length > 0) {
      setLoading(false);
      return;
    }

    getAllStories().then((response) => {
      if (response.status === 200) {
        dispatch(setStories(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stories]);

  const refresh = () => {
    getAllStories().then((response) => {
      if (response.status === 200) {
        dispatch(setStories(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getselectedStory = (id: number) => {
    setLoading(true);
    getStory(id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedStory(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return {
    stories, loading, refresh, getselectedStory, selectedStory
  }
}

export default useStories