import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import { FormInput, FormTextarea } from '../../global/Input';
import { doCreate } from '../../../api';

interface ICreateProjectProps {
  onCancel: () => void;
  inRefresh: () => void;
  activity_id: number;
}

const AddProjectReport = ({ onCancel, inRefresh, activity_id }: ICreateProjectProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <Formik initialValues={{
      objectives: '',
      responsibility: '',
      procedure: '',
      description: '',
      data_sources: '',
    }}
      validationSchema={Yup.object({
        objectives: Yup.string().required('Required'),
        responsibility: Yup.string().min(20, 'Must be 20 characters or more').required('Required'),
        procedure: Yup.string().min(20, 'Must be 20 characters or more').required('Required'),
        description: Yup.string().min(20, 'Must be 20 characters or more').required('Required'),
        data_sources: Yup.string().min(20, 'Must be 20 characters or more').required('Required'),
      })}
      onSubmit={async values => {
        try {
          setLoading(true);
          const newProject = await doCreate(`activities/${activity_id}/reports/create`, values);
          if (newProject.data.success === true) {
            toast.success('New Project report created successfully', successToastOptions);
            inRefresh(); // refresh the hook store
            setTimeout(() => {
              onCancel();
            }, 1000);
          } else {
            toast.error(formatErrorMessage(newProject.data.data, newProject.data.message), errorToastOptions);
          }
        } catch (err) {
          toast.error(ERRORMESSAGE, errorToastOptions);
        } finally {
          setLoading(false)
        }
      }} >
      <Form >
        <div className="form-row">
          <div className="col-sm-6">
            <FormInput
              label="Objectives"
              name="objectives" />
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-6">
            <FormTextarea
              label="Responsibility"
              name="responsibility" rows={6} />
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-6">
            <FormTextarea
              label="Procedure"
              name="procedure" rows={10} />
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-6">
            <FormTextarea
              label="Description"
              name="description" rows={10} />
          </div>
        </div>

        <div className="form-row">
          <div className="col-sm-6">
            <FormTextarea
              label="Data sources"
              name="data_sources" rows={6} />
          </div>
        </div>

        <div className="form-row m-0 pb-4 pt-3 justify-content-end">
          <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
          <button type='submit' disabled={loading} className='btn  btn-info' >
            {loading ? "Creating..." : "Create"}
          </button>
        </div>
      </Form>
    </Formik>
  )
}


export default AddProjectReport