import React, { useEffect } from 'react'
import { useLastUrlSegment } from '../../../hooks/useQuery';
import useStaff from '../../../hooks/useStaff';
import { EllipsisLoader } from '../../global/MyLoaders';
import EmployeeAddress from './EmployeeAddress';
import EmployeeDetails from './EmployeeDetails'
import EmployeeEducation from './EmployeeEducation';
import EmployeeOtherTrainings from './EmployeeOtherTrainings';
import EmployeeRecentEmployments from './EmployeeRecentEmployments';
import EmployeeReferees from './EmployeeReferees';

const ViewDetails = () => {
  const id = useLastUrlSegment();
  const { loading, getStaff } = useStaff();
  useEffect(() => {
    return getStaff(Number(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return loading ? <EllipsisLoader /> : (
    <div className='row m-0'>
      <div className="col-xs-12 col-md-6">
        <EmployeeDetails />

      </div>
      <div className="col-xs-12 col-md-6">
        <EmployeeAddress />
      </div>
      <div className="col-xs-12 col-md-6">
        <EmployeeEducation />
      </div>
      <div className="col-xs-12 col-md-6">
        <EmployeeRecentEmployments />
      </div>
      <div className="col-xs-12 col-md-6">
        <EmployeeOtherTrainings />
      </div>
      <div className="col-xs-12 col-md-6">
        <EmployeeReferees />
      </div>
    </div>
  )
}

export default ViewDetails