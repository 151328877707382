import React, { useEffect } from 'react'
import useActivities from '../../../hooks/useActivities'
import { useLastUrlSegment } from '../../../hooks/useQuery'
import { EllipsisLoader } from '../../global/MyLoaders'
import Activity from './Activity'
import ActivityDashboard from './ActivityDashboard'

const ViewActivity = () => {
  const id = useLastUrlSegment();
  const { getActivity, loading } = useActivities();

  useEffect(() => {
    return getActivity(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return loading ? (<EllipsisLoader />) : (
    <>
      <ActivityDashboard id={id} />
      <Activity id={id} />
    </>
  )
}

export default ViewActivity