import React from "react";
import DashboardWrapper from "../../components/DashboardWrapper";
import Breadcrumbs from "../../parts/Breadcrumbs";
import ListTrailBalance from "../../components/accounting/trail_balance/ListTrailBalance";

const TrialBalance = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title="Trial Balance" />
        {/* body */}
        <ListTrailBalance />
      </>
    </DashboardWrapper>
  );
};

export default TrialBalance;
