import React from 'react'
import toast from 'react-hot-toast';
import { errorToastOptions } from '../../constants';
import Input from './Input';

interface IDynamicFormProps {
  defaultData: Array<any>;
  setValues: (item: Array<any>) => void;
  values: Array<any>

}

const DynamicInvoiceForm = ({ defaultData, values, setValues }: IDynamicFormProps) => {
  const handleAddClick = () => {
    setValues([...values, ...defaultData]);
  }

  const handleMinusClick = (index: number) => {
    const newState = values;
    if (newState.length === 1) {
      toast.error("You can't remove this column", errorToastOptions);
      return;
    };
    newState.splice(index, 1)
    setValues([...newState]);
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, id } = e.target as HTMLInputElement;
    const newState = values;
    newState[Number(id)][name] = value;
    newState[Number(id)].sub_total = newState[Number(id)].quantity * newState[Number(id)].amount;
    setValues([...newState]);
  }



  return (
    <div className='col-sm-12'>

      <table className='table table-bordered'>
        <thead>
          <tr>
            <th colSpan={3}>Product Name</th>
            <th>Amount</th>
            <th>Quantity</th>
            <th>Sub Total</th>
          </tr>
        </thead>
        <tbody>

          {values && values.length > 0 && (
            values.map((item, index) => (
              <tr key={index}>
                <td colSpan={3}>
                  <Input label='' handleChange={handleChange} id={index} name={`product_name`} noLabel />
                </td>
                <td>
                  <Input type='number' label='' handleChange={handleChange} id={index} name={`amount`} noLabel />
                </td>
                <td>
                  <Input type='number' label='' handleChange={handleChange} id={index} name={`quantity`} noLabel />
                </td>
                <td>
                  <div className="form-row flex-nowrap justify-content-center align-items-center">
                    <div className="col">
                      {item['quantity'] * item['amount']}
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className='btn btn-sm btn-danger'
                        onClick={() => handleMinusClick(index)} // remove a friend from the list
                      >
                        -
                      </button>

                    </div>

                  </div>

                </td>
              </tr>

            ))
          )}
        </tbody>
      </table>

      <div className="col mx-0 my-2">

        <button
          type="button"
          className='btn btn-sm btn-info'
          onClick={() => handleAddClick()} // insert an empty string at a position
        >
          Add +
        </button>
      </div>

      {/* <div className="row border my-1 p-1">
        <div className="col-sm-3">
          <h6 className='text-center'>Sub Total</h6>
          <p className='text-center'>0</p>
        </div>
        <div className="col-sm-3">
          <h6 className='text-center'>Total Tax</h6>
          <p className='text-center'>0</p>
        </div>
        <div className="col-sm-3">
          <h6 className='text-center'>Discount</h6>
          <p className='text-center'>0</p>
        </div>
        <div className="col-sm-3">
          <h6 className='text-center'>Grand Total</h6>
          <p className='text-center'>0</p>
        </div>
      </div> */}

    </div>
  );
}

export default DynamicInvoiceForm