import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../api';
import { changePasswordDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import { formatErrorMessage } from '../../../helpers';
import Validator from '../../../utils/Validator';
import Input from '../../global/Input';

interface IChangePasswordProps {
  onCancel: () => void;
}

const ChangePassword = ({ onCancel }: IChangePasswordProps) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(changePasswordDefaultData)

  const validator = new Validator();

  const handleChange = (e:
    | React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setPassword({ ...password, ...{ [name]: { ...password[name], error: false, data: value } } });
    } else {
      // indicate an error
      setPassword({ ...password, ...{ [name]: { ...password[name], error: true, data: value } } });
    }

  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(password, setPassword)) {
      setLoading(true);
      const data = {
        old_password: password.old_password.data,
        new_password: password.new_password.data,
        new_password_confirmation: password.new_password_confirmation.data,
      }

      try {
        const response = await doCreate('users/' + JSON.parse(sessionStorage.user)?.user?.id + '/update_password', data);
        if (response.data.success === true) {
          toast.success('Password updated successfully', successToastOptions);
          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }



  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} label="Old Password" name='old_password' placeholder='Please enter your old password' error={password.old_password.error} value={password.old_password.data} errorMessage="Please provide the old password" required />
        </div>
      </div>
      <hr />
      <div className='py-2'>
        <p className='text-secondary'><strong>Note:</strong> New password should be eight character long!</p>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} label="New Password" name='new_password' placeholder='Please enter your password' error={password.new_password.error} value={password.new_password.data} errorMessage="Please make sure that the new password is atleast 8 characters long" required />
        </div>

        <div className="col-sm-6">
          <Input handleChange={handleChange} label="Confirm Password" name='new_password_confirmation' placeholder='Please confirm password' error={password.new_password_confirmation.error} value={password.new_password_confirmation.data} errorMessage="Please make sure that the password match" required />
        </div>

      </div>
      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>
          {loading ? "Changing..." : "Change"}
        </button>
      </div>
    </form >
  )
}

export default ChangePassword