import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { doCreate } from '../../../../api';
import { addActivityParameterDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../../constants';
import { formatErrorMessage } from '../../../../helpers';
import { getFormData } from '../../../../utils/helpers';
import Validator from '../../../../utils/Validator';
import Input from '../../../global/Input';
import RadioButton from '../../../global/RadioButton';
import Textarea from '../../../global/Textarea';

interface IAddParameterProps {
  closeModal: () => void;
  refresh: () => void;
}

const AddParameter = ({ closeModal, refresh }: IAddParameterProps) => {
  const [activityParameter, setActivityParameter] = useState(addActivityParameterDefaultData);
  const [loading, setLoading] = useState(false);
  const validator = new Validator();

  const handleChange = (
    e:
      | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setActivityParameter({ ...activityParameter, ...{ [name]: { error: false, data: value } } });
    } else {
      // indicate an error
      setActivityParameter({ ...activityParameter, ...{ [name]: { error: true, data: value } } });
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    if (validator.checkForEmptyFields(activityParameter, setActivityParameter)) {
      setLoading(true);
      const data = getFormData(activityParameter);

      try {
        const response = await doCreate('activity-parameters/create', data);
        if (response.data.success === true) {
          toast.success('New activity parameter created successfully', successToastOptions);
          refresh(); // refresh the hook store
          setTimeout(() => {
            closeModal();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      } catch (err) {
        toast.error(ERRORMESSAGE, errorToastOptions);
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <form>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Parameter Name' handleChange={handleChange} error={activityParameter?.name.error} errorMessage="Please provide the role name" value={activityParameter?.name.data} required />
        </div>

        <div className="col-sm-6">
          <label>Requires approval</label>
          <div>
            <RadioButton handleChange={handleChange} name='requires_approval' label='Yes' value={'1'} checked={activityParameter?.requires_approval.data === '1'} id="requires_approval_yes" />
            <RadioButton handleChange={handleChange} name='requires_approval' label='No' value={'0'} checked={activityParameter?.requires_approval.data === '0'} id="requires_approval_no" />
          </div>
        </div>

      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <label>Parameter type</label>
          <div>
            <RadioButton handleChange={handleChange} name='parameter_type' label='Text' value={'1'} checked={activityParameter?.parameter_type.data === '1'} id="parameter_type_text" />
            <RadioButton handleChange={handleChange} name='parameter_type' label='File' value={'2'} checked={activityParameter?.parameter_type.data === '2'} id="parameter_type_file" />
          </div>
        </div>
        <div className="col-sm-6">
          <Textarea handleChange={handleChange} name='description' label='Description' error={activityParameter?.description.error} errorMessage="Please provide a role description" value={activityParameter?.description.data} required />
        </div>

      </div>


      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={loading} className='btn  btn-info' onClick={handleSubmit}>{loading ? "Creating..." : "Create"}</button>
      </div>
    </form>
  )
}

export default AddParameter