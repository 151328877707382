import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { clinicCreate } from '../../api/clinics';
import { clinicDefaultData, errorToastOptions, successToastOptions } from '../../constants';
import { formatErrorMessage } from '../../helpers';
import useClinics from '../../hooks/useClinics';
import Validator from '../../utils/Validator';
import Input from '../global/Input'
import { EllipsisLoader } from '../global/MyLoaders';

interface IEditClinicProps {
  closeModal: () => void;
  id: number;
}

const EditClinic = ({ closeModal, id }: IEditClinicProps) => {
  const [formLoading, setFormLoading] = useState(false);

  const [clinic, setClinic] = useState(clinicDefaultData);

  const validator = new Validator();
  const { refresh, getSelectedClinic, loading, selectedClinic } = useClinics();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedClinic(id), [id]);

  const formNames = ['telephone', 'name', 'email', 'licence_no', 'location'];
  useEffect(() => {
    let clinicData = clinicDefaultData;
    if (!loading) {
      for (let x of formNames) {
        clinicData = { ...clinicData, ...{ [x]: { ...clinicData[x], ...{ error: false, data: selectedClinic[x] } } } };
      }
      setClinic(clinicData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setClinic({ ...clinic, ...{ [name]: { ...clinic[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setClinic({ ...clinic, ...{ [name]: { ...clinic[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(clinic, setClinic)) {
      setFormLoading(true);
    }

    const data = {
      name: clinic.name.data,
      email: clinic.email.data,
      telephone: clinic.telephone.data,
      licence_no: clinic.licence_no.data,
      location: clinic.location.data,
    }
    clinicCreate(data, 'clinics/' + id + '/update').then((response) => {
      if (response.data.success === true) {
        toast.success('Clinic details updated successfullu', successToastOptions);
        refresh();

        setTimeout(() => {
          closeModal();
        }, 1000);
      } else {
        toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
      }
    }).finally(() => {
      setFormLoading(false);
    })
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input name='name' label='Clinic name' handleChange={handleChange} value={clinic.name.data} error={clinic.name.error} errorMessage="Please enter the clinic name" required />
        </div>
        <div className="col-sm-6">
          <Input name='email' type='email' label='Email address' handleChange={handleChange} value={clinic.email.data} error={clinic.email.error} errorMessage="Please enter the clinic email address" required />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input name='telephone' label='Phone number' handleChange={handleChange} value={clinic.telephone.data} error={clinic.telephone.error} errorMessage="Please enter the clinic telephone number" required />
        </div>
        <div className="col-sm-6">
          <Input name='licence_no' label='License number' handleChange={handleChange} value={clinic.licence_no.data} error={clinic.licence_no.error} errorMessage="Please enter the clinic license number" required />
        </div>
        <div className="col-sm-6">
          <Input name='location' label='Location' handleChange={handleChange} value={clinic.location.data} error={clinic.location.error} errorMessage="Please enter the clinic location" required />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={closeModal}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn btn-primary' onClick={handleSubmit}>
          {formLoading ? "Updating..." : "Update"}
        </button>
      </div>
    </form>
  )
}

export default EditClinic