import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { edditAsset } from '../../api/assetManagement';
import { assetDefaultData, errorToastOptions, successToastOptions } from '../../constants';
import { formatErrorMessage } from '../../helpers';
import useAssetManagement from '../../hooks/useAssetManagement';
import Validator from '../../utils/Validator';
import Input from '../global/Input'
import InputDate from '../global/InputDate'
import InputSelect from '../global/InputSelect'
import { EllipsisLoader } from '../global/MyLoaders';

interface CreateAssetProps {
  closeModal: () => void;
  id: number;
}

const EditAsset = ({ closeModal: onCancel, id }: CreateAssetProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [asset, setAsset] = useState(assetDefaultData);
  const { assetTypesFormData, refresh, refreshTypes, getSelectedAsset, selectedAsset, loading } = useAssetManagement();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedAsset(id), [id]);

  useEffect(() => {
    return refreshTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formNames = ['asset_types_id', 'valuation_date', 'value_amount', 'purchase_price', 'purchase_date', 'replacement_value', 'serial_no', 'bought_from', 'description', 'asset_name'];

  useEffect(() => {
    let assetData = assetDefaultData;
    if (!loading) {
      for (let x of formNames) {
        assetData = { ...assetData, ...{ [x]: { ...assetData[x], ...{ error: false, data: selectedAsset[x] } } } };
      }
      setAsset(assetData);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const validator = new Validator();

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAsset({ ...asset, ...{ [name]: { ...asset[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAsset({ ...asset, ...{ [name]: { ...asset[name], ...{ error: true, data: value } } } });
    }
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== '') {
      // update the state
      setAsset({ ...asset, ...{ [name]: { ...asset[name], ...{ error: false, data: value } } } });
    } else {
      // indicate an error
      setAsset({ ...asset, ...{ [name]: { ...asset[name], ...{ error: true, data: value } } } });
    }
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(asset, setAsset)) {
      setFormLoading(true);
      const data = {
        asset_types_id: asset.asset_type_id.data,
        valuation_date: asset.valuation_date.data,
        value_amount: asset.value_amount.data,
        purchase_price: asset.purchase_price.data,
        purchase_date: asset.purchase_date.data,
        replacement_value: asset.replacement_value.data,
        serial_no: asset.serial_no.data,
        bought_from: asset.bought_from.data,
        description: asset.description.data,
        asset_file: asset.asset_file.data,
        asset_name: asset.asset_name.data
      }
      edditAsset(id, data).then((response) => {
        if (response.data.success === true) {
          toast.success('Asset updated successfully', successToastOptions);
          refresh();

          setTimeout(() => {
            onCancel();
          }, 1000);
        } else {
          toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
        }
      }).finally(() => {
        setFormLoading(false);
      })
    }
  }

  return loading ? <EllipsisLoader /> : (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} name="asset_name" label='Asset name' value={asset.asset_name.data} error={asset.asset_name.error} errorMessage="Please enter the asset name" required />
        </div>
        <div className="col-sm-6">
          <InputSelect data={assetTypesFormData} label="Type" name='asset_type_id' id='asset_type_id' required handleChange={handleSelectChange} value={[{ label: selectedAsset.asset_type?.name, value: selectedAsset.asset_type?.id }]} error={asset.asset_type_id.error} errorMessage="Please select the asset type" />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <InputDate handleChange={handleSelectChange} label="Valuation Date" name='valuation_date' required value={asset.valuation_date.data} error={asset.valuation_date.error} errorMessage="Please select the valuation date" />
        </div>
        <div className="col-sm-6">
          <Input type='number' handleChange={handleChange} name="value_amount" label='Value Amount' value={asset.value_amount.data} error={asset.value_amount.error} errorMessage="Please enter the value amount" required />
        </div>
      </div>
      <hr />

      <div className="form-row">
        <div className="col-sm-6">
          <InputDate handleChange={handleSelectChange} label="Purchase Date" name='purchase_date' required value={asset.purchase_date.data} error={asset.purchase_date.error} errorMessage="Please select the purchase date" />
        </div>
        <div className="col-sm-6">
          <Input type='number' handleChange={handleChange} name="purchase_price" label='Purchase price' required value={asset.purchase_price.data} error={asset.purchase_price.error} errorMessage="Please enter the purchase price" />
        </div>
        <div className="col-sm-6">
          <Input type='number' handleChange={handleChange} name="replacement_value" label='Replacement value' />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} name="serial_no" label='Serial Number' />
        </div>
        <div className="col-sm-6">
          <Input handleChange={handleChange} name="bought_from" label='Bought From' />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input handleChange={handleChange} name="description" label='Description' />
        </div>
        <div className="col-sm-6">
          {/* <Input handleChange={handleChange} name="asset_file" label='Invoice/Reciept' /> */}
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button type='reset' className='btn btn-outline-danger mr-3' onClick={onCancel}>Cancel</button>
        <button type='submit' disabled={formLoading} className='btn  btn-info' onClick={handleSubmit}>
          {formLoading ? "Updating..." : "Update"}
        </button>
      </div>

    </form>
  )
}

export default EditAsset