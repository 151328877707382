import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import ViewTreatment from '../../components/doctor/treatments/ViewTreatment'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { ROUTES } from '../../routes/routes'

const ViewTreatmentDetails = () => {
  return (
    <DashboardWrapper >
      <>
        <Breadcrumbs title='Treatments details' parents={[{ link: ROUTES.TREATMENTS, title: 'Treatments' }]} />
        <ViewTreatment />

      </>
    </DashboardWrapper >
  )
}

export default ViewTreatmentDetails