import React, { useState } from "react";
import toast from "react-hot-toast";
import useCustomers from "../../../hooks/useCustomers";
import Validator from "../../../utils/Validator";
import { customerDefaultData, successToastOptions } from "../../../constants";
import {
  getFormData,
} from "../../../utils/helpers";
import { createCustomer } from "../../../api/customers";
import Input from "../../global/Input";
import Textarea from "../../global/Textarea";

interface AddCustomerProps {
  closeModal: () => void;
}

const AddCustomer = ({ closeModal: onCancel }: AddCustomerProps) => {
  const [formLoading, setFormLoading] = useState(false);
  const [customer, setCustomer] = useState(customerDefaultData);
  const { refresh } = useCustomers();



  const validator = new Validator();

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setCustomer({
        ...customer,
        ...{ [name]: { ...customer[name], ...{ error: false, data: value } } },
      });
    } else {
      // indicate an error
      setCustomer({
        ...customer,
        ...{ [name]: { ...customer[name], ...{ error: true, data: value } } },
      });
    }
  };

  

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(customer, setCustomer)) {
      setFormLoading(true);

      const data = getFormData(customer);

      createCustomer(data)
        .then((response) => {
          if (response.data.success === true) {
            toast.success("Customer added successfully", successToastOptions);
            refresh();

            setTimeout(() => {
              onCancel();
            }, 1000);
          } else {
            //   toast.error(formatErrorMessage(response.data.data, response.data.message), errorToastOptions);
          }
        })
        .finally(() => {
          setFormLoading(false);
        });
    }
  };

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="name"
            label="Customer name"
            value={customer.name.data}
            error={customer.name.error}
            errorMessage="Please enter the customer name"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="email"
            type="email"
            label="Email Address"
            value={customer.email.data}
            error={customer.email.error}
            errorMessage="Please enter the email"
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="phone_no"
            label="Phone No"
            value={customer.phone_no.data}
            error={customer.phone_no.error}
            errorMessage="Please enter the customer phone number"
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            handleChange={handleChange}
            name="alt_phone"
            label="Alternative Phone No"
            value={customer.alt_phone.data}
            error={customer.alt_phone.error}
            errorMessage="Please enter the alt phone number"
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-6">
          <Input
            type="number"
            handleChange={handleChange}
            name="debt_limit"
            label="Debit Limit"
            required
            value={customer.debt_limit.data}
            error={customer.debt_limit.error}
            errorMessage="Please enter the debit limit value"
          />
        </div>
        <div className="col-sm-6">
          <Textarea
            handleChange={handleChange}
            name="address"
            label="Address"
            value={customer.address.data}
            error={customer.address.error}
            errorMessage="Please enter the address"
            required
          />
        </div>
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={formLoading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {formLoading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
};

export default AddCustomer;
