
import { useEffect } from "react"
import DashboardWrapper from "../../../components/DashboardWrapper"
import { EllipsisLoader } from "../../../components/global/MyLoaders"
import PersonalPayrollDetails from "../../../components/hr/payrollManagement/PersonalPayrollDetails"
import usePayrolls from "../../../hooks/usePayrolls"
import { useLastUrlSegment } from "../../../hooks/useQuery"
import useStaff from "../../../hooks/useStaff"
import Breadcrumbs from "../../../parts/Breadcrumbs"
import { ROUTES } from "../../../routes/routes"

const ViewPersonalPayroll = () => {
  const id = useLastUrlSegment();
  const { getSelectedStaffPayroll, getSelectedPayrollStats, loading } = usePayrolls();
  const { getStaff, selectedEmployee, loading: staffLoading } = useStaff();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedStaffPayroll(id), [id]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getSelectedPayrollStats(id), [id]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getStaff(id), [id]);



  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title={`Payroll details - ${staffLoading ? 'Loading..' : selectedEmployee?.user?.first_name + ' ' + selectedEmployee?.user?.last_name}`} parents={[{ title: "Payroll Management", link: ROUTES.PAYROLLMANAGEMENT }]} />
        {loading ? <EllipsisLoader /> : <PersonalPayrollDetails />}

      </>
    </DashboardWrapper>
  )
}

export default ViewPersonalPayroll