import React from 'react'
import SettingsWrapper from '../../components/SettingsWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'

const Settings = () => {
  return (
    <SettingsWrapper>
      <>
        <Breadcrumbs title='System settings' />
        {/* body */}
      </>
    </SettingsWrapper>
  )
}

export default Settings