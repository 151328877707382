import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import ReactToPrint from "react-to-print";

interface PurchaseOrder {
  id: any;
}

const ViewPurchaseOrder = ({ id }: PurchaseOrder) => {
  const handlePrint = () => {
    window.print();
  };
  const componentRef = React.useRef(null);
  const order = {
    id: "PO123456",
    date: "2024-02-16",
    items: [
      { name: "Item 1", quantity: 2, price: 10 },
      { name: "Item 2", quantity: 1, price: 20 },
      { name: "Item 3", quantity: 3, price: 15 },
    ],
    total: 2 * 10 + 1 * 20 + 3 * 15, // Calculated total
    company: "Your Company",
    companyAddress: "123 Main St, City, Country",
    recipient: {
      name: "Recipient Name",
      address: "456 Elm St, City, Country",
    },
  };
  return (
    <div className="col-xl-12 mb-30">
      <div className="card card-statistics h-100">
        <div className="card-body">
          <div className="d-block d-md-flex justify-content-end mb-10">
            
            <div className="d-block d-md-flex clearfix sm-mt-20">
              <div className="widget-search ml-0 clearfix">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-primary ml-auto mr-3"><i className="fa fa-print"></i>{" "}Print Order</button>
                  )}
                  content={() => componentRef.current}
                />
              </div>
            </div>
          </div>
          <div className="container" ref={componentRef}>
            <div className="row">
              <div className="col">
                <h2>{order.company}</h2>
                <p>{order.company}</p>
                <p>{order.companyAddress}</p>
              </div>
              <div className="col text-right">
                <h2>Purchase Order</h2>
                <p>
                  <strong>Order ID:</strong> {order.id}
                </p>
                <p>
                  <strong>Date:</strong> {order.date}
                </p>
              </div>
            </div>
            <hr />
            <br />
            <div className="row">
              <div className="col">
                <h4>To:</h4>
                <p>
                  <strong>Name:</strong> {order.recipient.name}
                </p>
                <p>
                  <strong>Address:</strong> {order.recipient.address}
                </p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <table className="table">
                  <thead>
                    <tr>
                      <th>P.O DATE</th>
                      <th>REQUISITIONER</th>
                      <th>SHIPPED VIA</th>
                      <th>F.O.B POINT</th>
                      <th>TERMS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.quantity}</td>
                        <td>{item.quantity}</td>
                        <td>${item.price.toFixed(2)}</td>
                        <td>${(item.quantity * item.price).toFixed(2)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-left">
                        <strong>Sub Total:</strong>{" "}
                      </td>
                      <td>${order.total.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>Tax Rate:</strong>
                      </td>

                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>Sales Tax:</strong>
                      </td>

                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>Shipping and Handling:</strong>
                      </td>

                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>Others:</strong>
                      </td>

                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <strong>Total:</strong>
                      </td>
                      <td>${order.total.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPurchaseOrder;
